import React, { FC } from "react";
import styled from "styled-components";

import type { InfoCollectionProps } from "./types";
import { InfoSign } from "../../Atoms";

const InfoCollectionContainer = styled.div<InfoCollectionProps>`
  display: flex;
  position: absolute;
  left: ${(props) => (props.left ? props.left : "unset")};
  right: ${(props) => (props.right ? props.right : "unset")};
  bottom: ${(props) => (props.bottom ? props.bottom : "unset")};
  top: ${(props) => (props.top ? props.top : "unset")};
  flex-direction: column;
`;

const InfoCollection: FC<InfoCollectionProps> = ({
  children,
  top,
  right,
  bottom,
  left,
}) => {
  return (
    <InfoCollectionContainer
      top={top}
      left={left}
      right={right}
      bottom={bottom}
    >
      <InfoSign>{children}</InfoSign>
    </InfoCollectionContainer>
  );
};

export default InfoCollection;
