import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Typography } from "../../Atoms";

const timelineWidth = 2880;
const hourWidth = timelineWidth / 24;

const TimelineWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  width: 100%;
  position: relative;
  background-color: #ffffff;
  padding: 16px 16px;
  height: 130px;
  border-radius: 8px;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TimelineContainer = styled.div`
  position: relative;
  width: ${timelineWidth}px;
  height: 100%;
`;

const Hour = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${hourWidth}px;
  border-right: 1px solid #ddd;
  height: 100px;
  justify-content: space-between;
`;

const HourLabel = styled.span`
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
  width: 59px; // because of 1 px border
`;

const HourLine = styled.div`
  flex-grow: 1;
  width: 1px;
  background-color: #ddd;
`;

const CurrentTimeIndicator = styled.div`
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: red;
  z-index: 10;
`;

const BlockContainer = styled.div`
  position: absolute;
  height: 100%;
  z-index: 5;
  margin-top: 25px;
`;

const Block = styled.div`
  position: absolute;
  border-radius: 4px;
  padding: 4px 8px;
  color: white;
  z-index: 5;
  height: 32px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
`;

interface TimeBlock {
  label: string;
  start: string;
  end: string;
  color: string;
}

interface TimelineProps {
  blocks: TimeBlock[];
}

const getCurrentTimePosition = () => {
  const currentTimeHours = new Date().getHours();
  const currentTimeMin = new Date().getMinutes();

  return currentTimeHours * 60 + currentTimeMin;
};

const Timeline: React.FC<TimelineProps> = ({ blocks }) => {
  const timelineRef = useRef<HTMLDivElement>(null);

  const renderHours = () =>
    Array.from({ length: 24 }, (_, i) => (
      <Hour key={i}>
        <HourLabel>
          <Typography
            color="#333"
            marginBottom="None"
            marginTop="None"
            fontSize="Small"
            as="button"
            noWrap="noWrap"
            style={{
              backgroundColor: "transparent",
              border: "none",
              fontWeight: 500,
              margin: 0,
              padding: 0,
            }}
          >
            <label>{i}:00</label>
          </Typography>
        </HourLabel>
        <HourLine />
      </Hour>
    ));

  const renderBlocks = () =>
    blocks.map((block, index) => {
      const [startHour, startMinute] = block.start.split(":").map(Number);
      const [endHour, endMinute] = block.end.split(":").map(Number);

      const left = startHour * 60 + startMinute + startHour;
      const width = endHour * 60 + endMinute - startHour * 60 + startMinute;

      return (
        <Block
          key={index}
          style={{
            left: `${left}px`,
            width: `${width - 16}px`,
            maxWidth: `${width - 16}px`,
            backgroundColor: block.color,
          }}
        >
          <Typography
            color="#ffffff"
            marginBottom="None"
            marginTop="None"
            fontSize="H6"
            as="button"
            noWrap="noWrap"
            style={{
              backgroundColor: "transparent",
              border: "none",
              fontWeight: 500,
              margin: 0,
              padding: 0,
            }}
          >
            <label>{block.label}</label>
          </Typography>
        </Block>
      );
    });

  const currentTime = getCurrentTimePosition();

  useEffect(() => {
    if (timelineRef.current) {
      const wrapper = timelineRef.current;
      const containerWidth = wrapper.clientWidth;
      const scrollTo = currentTime - containerWidth / 2 + 32;
      wrapper.scrollLeft = scrollTo;
    }
  }, []);

  return (
    <TimelineWrapper ref={timelineRef} id="wrapper">
      <TimelineContainer id="container">
        <BlockContainer style={{ width: "100%" }} className="blocks">
          {renderBlocks()}
        </BlockContainer>
        <CurrentTimeIndicator style={{ left: currentTime }} />
      </TimelineContainer>
      {renderHours()}
    </TimelineWrapper>
  );
};

export default Timeline;
