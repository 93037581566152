import { FC, useEffect, useState, useRef, useMemo, useContext } from "react";
import { Card, Container, Typography } from "../../Atoms";
import styled from "styled-components";
import type { Period } from "./../../../../ContextApi/StateProvider/type";
import { DateTime, Duration } from "luxon";
import { StateContext } from "../../../../ContextApi/StateProvider/StateProvider";

const DateScrollRootContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const HScroll = styled.div`
  overflow-x: auto;
  scroll-behavior: smooth;
`;

const InnerScroll = styled.div`
  width: 330px;
  display: flex;
  flex-direction: row;
`;

export class DateSelection {
  period: Period;
  start: DateTime;
  stop: DateTime;
  index: number;

  constructor(period: Period, start: DateTime, stop: DateTime, index: number) {
    this.period = period;
    this.start = start;
    this.stop = stop;
    this.index = index;
  }

  isPeriod(period: Period): boolean {
    return this.period === period;
  }
}

const DateOptions = (
  timezone: string,
  period: Period,
  formatting: string,
  nowLabel: string,
  lastLabel: string
): Array<[string, DateSelection]> => {
  const start = DateTime.now().setZone(timezone).startOf("day").startOf(period);
  let options: Array<[string, DateSelection]> = new Array();

  const dur = (period: Period, no: number): Duration => {
    let obj: any = {};
    obj[period] = no;
    return Duration.fromObject(obj);
  };

  for (let i = 0; i < 7; i++) {
    const iDay = start.minus(dur(period, i));
    let label = iDay.toFormat(formatting);
    if (i === 0) label = nowLabel;
    if (i === 1) label = lastLabel;

    options.push([
      label,
      new DateSelection(period, iDay, iDay.plus(dur(period, 1)), i),
    ]);
  }
  return options;
};

const PeriodOptions: Array<[Period, string, string, string, string]> =
  new Array(
    ["day", "Day", "EEEE", "Today", "Yesterday"],
    ["week", "Week", "w.W", "This week", "Last Week"],
    ["month", "Month", "LLL", "This month", "Last Month"],
    ["year", "Year", "yyyy", "This year", "Last Year"]
  );

export type DateSelectorProps = {
  timezone: string;
  stateSetter: React.Dispatch<React.SetStateAction<DateSelection | undefined>>;
  sharedState?: boolean;
};

const DateSelector: FC<DateSelectorProps> = ({
  timezone,
  stateSetter,
  sharedState,
}) => {
  const context = useContext(StateContext);
  const [selected, setSelected] = useState<DateSelection | undefined>();
  const [selectedPeriod, setSelectedPeriod] = useState<Period>(
    sharedState === true ? context.selectedPeriod.value : "day"
  );
  const [selectedIndex, setSelectedIndex] = useState<number>(
    sharedState === true ? context.selectedPeriodIndex.value : 6
  );

  const ref = useRef(null);
  const handleScrollRight = () => {
    setTimeout(() => {
      if (ref?.current) {
        // @ts-ignore
        ref.current.scrollLeft = 999;
      }
    }, 200);
  };

  const dateOptions: Array<[string, DateSelection]> = useMemo((): Array<
    [string, DateSelection]
  > => {
    const periodOptions = PeriodOptions.find(
      ([period]) => period === selectedPeriod
    );
    if (periodOptions === undefined) {
      return new Array();
    }

    const [period, , formatting, nowLabel, lastLabel] = periodOptions;
    const options = DateOptions(
      timezone,
      period,
      formatting,
      nowLabel,
      lastLabel
    );

    // Let's reverse the array, so we get newest to
    // the far right side. I css solution would be preferable,
    // but scroll overflow don't play nice with row-reverse.
    options.reverse();

    // Always selected "now" when period is changed.
    const last = options.length - 1;

    setSelected(options[last][1]);

    handleScrollRight();

    return options;
  }, [selectedPeriod]);

  useEffect(() => {
    if (selected === undefined) {
      return;
    }

    if (sharedState === true) {
      context.selectedPeriod.setter(selected.period);
      context.selectedPeriodIndex.setter(selectedIndex);
    }

    stateSetter(selected);
  }, [selected, selectedIndex]);

  return (
    <DateScrollRootContainer id="ads">
      <Card backgroundColor="White" noPadding>
        <Container
          flexDirection="column"
          justifyContent="space-between"
          flex={1}
          gap={16}
          padding="16px"
        >
          <Container
            flexDirection="row"
            justifyContent="space-between"
            flex={1}
            gap={16}
            padding="4px"
          >
            {PeriodOptions.map(([period, label]) => {
              return (
                <Typography
                  key={period}
                  color={selectedPeriod == period ? "#5AA9F5" : "#000000"}
                  marginBottom="None"
                  marginTop="None"
                  fontSize="H6"
                  noWrap="noWrap"
                  onClick={() => {
                    setSelectedPeriod(period);
                    setSelectedIndex(6);
                  }}
                >
                  <label>{label}</label>
                </Typography>
              );
            })}
          </Container>
          <HScroll className="extraData" ref={ref}>
            <InnerScroll>
              {dateOptions.map(([label, selection], i) => {
                return (
                  <Typography
                    color={selectedIndex == i ? "#5AA9F5" : "#000000"}
                    key={"day" + i}
                    marginBottom="None"
                    marginTop="None"
                    fontSize="H6"
                    as="button"
                    noWrap="noWrap"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      fontWeight: 500,
                    }}
                    onClick={() => {
                      setSelectedIndex(i);
                      setSelected(selection);
                    }}
                  >
                    <label>{label}</label>
                  </Typography>
                );
              })}
            </InnerScroll>
          </HScroll>
        </Container>
      </Card>
    </DateScrollRootContainer>
  );
};

export default DateSelector;
