import React, { FC } from "react";
import styled from "styled-components";
import backImg from "./../../Assets/Icon/nav/back.svg";
import backLightImg from "./../../Assets/Icon/nav/backLight.svg";
import { Link } from "react-router-dom";

import { Typography } from "./../../Atoms";

const RootBackHeader = styled.div`
  display: flex;
  width: 100%;
`;

const TitleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

const BackHeader: FC<{
  title: string;
  goBack: string;
  lightColor?: boolean;
}> = ({ title, goBack, lightColor }) => {
  return (
    <RootBackHeader>
      <Link to={goBack}>
        {lightColor ? (
          <img src={backLightImg} alt="back arrow" />
        ) : (
          <img src={backImg} alt="back arrow" />
        )}
      </Link>
      <TitleContainer>
        <Typography
          color={lightColor ? "white" : "black"}
          fontSize="H6"
          marginBottom="None"
          marginTop="None"
          marginLeft="None"
        >
          {title}
        </Typography>
      </TitleContainer>
    </RootBackHeader>
  );
};

export default BackHeader;
