import React from "react";
import styled from "styled-components";

import wipImg from "./../../Assets/Icon/wip/wip.svg";

const NotisRootContainer = styled.div`
  background-color: #fafafa;
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NotisPage = () => {
  return (
    <NotisRootContainer>
      <img src={wipImg} alt="wip" />
    </NotisRootContainer>
  );
};

export default NotisPage;
