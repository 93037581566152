import React from "react";
import styled, { keyframes } from "styled-components";

import logoImg from "./../Assets/logo/logo.svg";

const dotPulseAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0.4);
  }
  40% {
    transform: scale(1.0);
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
`;

const DotPulse = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 100%;
  animation: ${dotPulseAnimation} 1.4s infinite ease-in-out both;
`;

const DotsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40px;
`;

const Loading: React.FC = () => {
  return (
    <LoadingWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <img height={"64px"} width={"64px"} src={logoImg} />
        <DotsWrapper>
          <DotPulse style={{ animationDelay: "-0.32s" }} />
          <DotPulse style={{ animationDelay: "-0.16s" }} />
          <DotPulse />
        </DotsWrapper>
      </div>
    </LoadingWrapper>
  );
};

export default Loading;
