import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "./Components/Atomic/Atoms";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrNoFacilitySelected, Facility, useFacility } from "./api/facility";
import { MobileFrame } from "./Components/Atomic/Organisms/MobileFrame/MobileFrame";
import { SevenFrame } from "./Components/Atomic/Organisms/SevenFrame/SevenFrame";
import { useMediaQuery } from "react-responsive";
import {
  defaultState as emptyEnergyPriceDay,
  EnergyPriceDay,
  fetchEnergyPrice,
} from "./api/energyprice";
import * as es from "./api/state";
import { priceLevel } from "./api/priceLevel";

const App = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [energyPriceDay, setEnergyPriceDay] = useState<EnergyPriceDay>(
    emptyEnergyPriceDay()
  );
  const [emsState, setEmsState] = useState<es.EmsState>(es.DefaultEmsState);

  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading: authIsLoading,
    error: authError,
    getAccessTokenSilently,
  } = useAuth0();

  const [
    facilityLoading,
    fError,
    facilities,
    facility,
    initFacilities,
    selectFacility,
  ] = useFacility();

  const fetchEnergyPriceDay = async (facility: Facility) => {
    const prices = await fetchEnergyPrice(
      facility,
      facility.localTime(),
      getAccessTokenSilently()
    );

    const priceLevelData = await priceLevel(
      facility,
      facility.localTime().minus({ day: 7 }),
      facility.localTime().plus({ day: 2 }),
      getAccessTokenSilently()
    );

    prices.breakdown = priceLevelData;

    setEnergyPriceDay(prices);
  };

  const fetchEmsState = async (facility: Facility) => {
    const state = await es.fetchEmsState(facility, getAccessTokenSilently());

    setEmsState(state);
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      initFacilities();
      return;
    }

    // Remove loading only when we failed to login.
    getAccessTokenSilently().catch(() => setIsLoading(false));
  }, [isAuthenticated]);

  useEffect(() => {
    if (facility == null) {
      if (fError == ErrNoFacilitySelected) {
        setIsLoading(false);
        navigate("/profile");
      }
      return;
    }

    const loads = [fetchEnergyPriceDay(facility), fetchEmsState(facility)];
    Promise.all(loads)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });

    const fetchEnergyPriceEveryHour = setInterval(() => {
      fetchEnergyPriceDay(facility);
    }, 60 * 60 * 1000);
    const fetchEmsStateEvery10sec = setInterval(() => {
      fetchEmsState(facility);
    }, 10 * 1000);

    return () => {
      clearInterval(fetchEnergyPriceEveryHour);
      clearInterval(fetchEmsStateEvery10sec);
    };
  }, [facility, fError]);

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1199px)",
  });
  const isDesktop = useMediaQuery({ query: "(min-width: 1200px)" });

  if (isLoading || authIsLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <img height={"64px"} src="logo.svg" />
        <div className="dot-pulse"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <Container
        padding="50px"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <img width="64px" src="logo512.png" alt="Logo" />
        <h2>Home Central</h2>
        <p>
          <button onClick={() => loginWithRedirect({ prompt: "login" })}>
            Login
          </button>
        </p>
        {authError !== undefined && (
          <div>
            <b>Login Failed</b>
            <p>{authError.message}</p>
          </div>
        )}
      </Container>
    );
  }

  if (isMobile) {
    return (
      <MobileFrame
        facility={facility}
        facilities={facilities}
        selectFacility={selectFacility}
        energyPriceDay={energyPriceDay}
        emsState={emsState}
      />
    );
  }

  if (isTablet) {
    return (
      <SevenFrame
        facility={facility}
        handleChooseFacility={selectFacility}
        energyPriceDay={energyPriceDay}
        emsState={emsState}
      />
    );
  }

  if (!isMobile && !isTablet) {
    window.location.href = "https://thiink.io";
    return null;
  }

  return (
    <Container>
      <h2>Your device is not yet supported.</h2>
    </Container>
  );
};

export default App;
