import { ReactNode, ElementType, HTMLAttributes } from "react";

export const TypographySize = {
  H1: "64px",
  H2: "48px",
  H3: "32px",
  H4: "24px",
  H5: "20px",
  H6: "18px",
  P: "16px",
  Small: "14px",
  Micro: "12px",
  Nano: "8px",
} as const;

export const TypographyWeight = {
  100: 100,
  300: 300,
  400: 400,
  500: 500,
  700: 700,
  900: 900,
};

export const TypographyMargin = {
  None: "0px",
  8: "8px",
  16: "16px",
  24: "24px",
  32: "32px",
  40: "40px",
};

export interface TypographyProps extends HTMLAttributes<HTMLHeadingElement> {
  as?: ElementType;
  children: ReactNode;
  color?: string;
  fontSize?: keyof typeof TypographySize;
  fontWeight?: keyof typeof TypographyWeight;
  marginBottom?: keyof typeof TypographyMargin;
  marginLeft?: keyof typeof TypographyMargin;
  marginRight?: keyof typeof TypographyMargin;
  marginTop?: keyof typeof TypographyMargin;
  noWrap?: "noWrap" | "normal" | "pre";
  noTransform?: boolean;
}
