import { ReactNode } from "react";

export const CardBackground = {
  IndigoToDarkIndigo: "linear-gradient(0deg, #141e31ff, #233a54ff)",
  White: "#ffffff",
  Black: "#000000",
  CharlestonToDarkCharleston: "linear-gradient(0deg, #212936ff, #3A4A5Eff)",
  AliceWhite: "#F1F7FC",
  40: "40px",
  GrayFox:
    "linear-gradient(160deg, rgba(123,133,142,1) 0%, rgba(83,94,106,1) 12%, rgba(41,54,68,1) 100%);",

  BluePrintBlue: "#2D87BE",
  Teal: "#24B09D",
  DeepBlue: "#143042",
  SkyLight: "#2DA4C7",
  DangerRed: "#DB4D4C",
  Rasphberry: "#D81E5B",
  Transparent: "00000000",
};

export interface CardProps {
  children: ReactNode;
  backgroundColor?: keyof typeof CardBackground;
  maxWidth?: boolean;
  noPadding?: boolean;
  flex?: string;
  minHeight?: string;
  flexDirection?: "column" | "row";
}
