import React, { ReactNode, FC } from "react";
import { Container } from "./../../Atoms/";
import styled from "styled-components";

const NavContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0px 0px 4px 0px #0000002e;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

export interface NavProps {
  children: ReactNode;
}

const Nav: FC<NavProps> = ({ children }) => {
  return <NavContainer>{children}</NavContainer>;
};

export default Nav;
