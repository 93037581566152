interface EnergyIdentifier {
  [key: string]: {
    title: string;
    color: string;
    helper: string;
  };
}

export const energyIdentifier: EnergyIdentifier = {
  energy_surcharge: {
    title: "Surcharge market price",
    color: "#82204A",
    helper: "surchargeMarketPrice",
  },
  energy: {
    title: "Market Price",
    color: "#219ebc",
    helper: "marketPrice",
  },
  balance_fee: {
    title: "Balance fee",
    color: "#CA1551",
    helper: "purBalanceFee",
  },
  certificate: {
    title: "Certificate",
    color: "#345995",
    helper: "purCertificate",
  },
  origin_guarantee: {
    title: "Origin guarantee",
    color: "#2CDA9D",
    helper: "purOriginGuarantee",
  },
  energy_tax: {
    title: "Energy tax",
    color: "#023047",
    helper: "energyTax",
  },
  transfer_fee: {
    title: "Transmission fee",
    color: "#ffb703",
    helper: "transmissionFee",
  },
  vat_supplier: {
    title: "VAT Supplier",
    color: "#fb8500",
    helper: "vatSupplier",
  },
  vat_grid: {
    title: "VAT Grid",
    color: "#d90429",
    helper: "vatGrid",
  },
  sell_60öringen: {
    title: "60 öringen",
    color: "#3587A4",
    helper: "sell60oringen",
  },
  sell_energy: {
    title: "Energy",
    color: "#D65780",
    helper: "sellEnergy",
  },
  sell_net_benefit: {
    title: "Net benefit",
    color: "#745C97",
    helper: "sellNetBenefit",
  },
  sell_vat_grid: {
    title: "VAT Grid",
    color: "#d90429",
    helper: "vatGrid",
  },
  sell_vat_supplier: {
    title: "VAT Supplier",
    color: "#fb8500",
    helper: "vatSupplier",
  },

  sell_energy_sale_markup: {
    title: "Energy sale markup",
    color: "#ff00ff",
    helper: "energySaleMarkup",
  },

  defaultFallBack: {
    title: "Missing",
    color: "#ff0000",
    helper: "defaultFallBack",
  },
};

export const costBreakdownIdentifier: EnergyIdentifier = {
  pur_energy_surcharge: {
    title: "Surcharge market price",
    color: "#82204A",
    helper: "purSurchargeMarketPrice",
  },

  balance_fee: {
    title: "Balance fee",
    color: "#CA1551",
    helper: "purBalanceFee",
  },
  pur_energy: {
    title: "Market Price",
    color: "#219ebc",
    helper: "purEnergy",
  },
  pur_energy_tax: {
    title: "Energy tax",
    color: "#023047",
    helper: "purEnergyTax",
  },
  pur_transfer_fee: {
    title: "Transmission fee",
    color: "#ffb703",
    helper: "purTransferFee",
  },
  pur_vat_supplier: {
    title: "VAT Supplier",
    color: "#fb8500",
    helper: "purVatSupplier",
  },
  pur_vat_grid: {
    title: "VAT Grid",
    color: "#d90429",
    helper: "purVatGrid",
  },
  sell_60öringen: {
    title: "60 öringen",
    color: "#3587A4",
    helper: "sell60oringen",
  },
  sell_energy: {
    title: "Energy",
    color: "#D65780",
    helper: "sellEnergy",
  },
  sell_net_benefit: {
    title: "Net benefit",
    color: "#745C97",
    helper: "sellNetBenefit",
  },
  certificate: {
    title: "Certificate",
    color: "#345995",
    helper: "purCertificate",
  },
  origin_guarantee: {
    title: "Origin guarantee",
    color: "#2CDA9D",
    helper: "purOriginGuarantee",
  },
  defaultFallBack: {
    title: "Missing",
    color: "#ff0000",
    helper: "defaultFallBack",
  },
};
