import React, { FC } from "react";
import styled from "styled-components";
import { Container, Typography } from "../../Atoms";
import type { FlatDataProps } from "./types";

const FlatDataContainer = styled.div<{ bgColor: string | undefined }>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#000000")};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;

  @media only screen and (max-width: 768px) {
    margin-bottom: 8px;
    padding: 8px;
  }
`;

const DataContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: space-evenly;
`;

const FlatData: FC<FlatDataProps> = ({
  bgColor,
  dataValue,
  dataValueColor,
  unit,
  noTransform,
  title,
}) => {
  return (
    <FlatDataContainer bgColor={bgColor}>
      <DataContainer>
        <Container
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Container
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
          >
            <Typography
              as="h5"
              fontSize="H5"
              color={dataValueColor ? dataValueColor : "#FFFFFF"}
              fontWeight={700}
              marginTop="None"
              marginRight="None"
              marginBottom="None"
              marginLeft="None"
            >
              {dataValue}
            </Typography>
            <Typography
              as="h6"
              fontSize="H6"
              fontWeight={400}
              color={dataValueColor ? dataValueColor : "#FFFFFF"}
              noTransform
            >
              {unit}
            </Typography>
          </Container>
          <Container
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              color="#ADADAD"
              fontSize="P"
              marginTop="None"
              marginRight="None"
              marginBottom={8}
              marginLeft="None"
            >
              {title}
            </Typography>
          </Container>
        </Container>
      </DataContainer>
    </FlatDataContainer>
  );
};
export default FlatData;
