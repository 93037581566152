// https://gate.thiink.io/hc/v1/facility/f6426e53-89b8-4fcf-a6a4-fb7081e5babc/price_levels?aggregate_duration=1m&start=2023-02-13&stop=2023-02-23
import { Facility } from "./facility";
import { GATE_ADDRESS } from "./server";
import { DateTime } from "luxon";

export interface CostBreakDown {}

export const priceLevel = async (
  facility: Facility,
  startDate: DateTime,
  endDate: DateTime,
  tokenGetter: Promise<string>
): Promise<CostBreakDown> => {
  const token = await tokenGetter;
  const parameters = new URLSearchParams({
    start: startDate.toFormat("yyyy-MM-dd"),
    stop: endDate.toFormat("yyyy-MM-dd"),
  });

  const address =
    GATE_ADDRESS + `/facility/${facility.id}/price_levels?${parameters}`;
  const response = await fetch(address, {
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
  const json = await response.json();

  return {
    date: DateTime.fromISO(json.date, { zone: json.timezone }),
    breakdown: {
      pur_breakdown: json.pur_price,
      sell_breakdown: json.sell_price,
    },
  };
};
