import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-luxon";
import {
  EnergyPriceDay,
  EnergyPriceSlot,
  useCurrentPriceSlot,
} from "../../../../api/energyprice";
import { DateTime } from "luxon";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend, TimeScale);

// @ts-ignore
Tooltip.positioners.stepperPositioner = function (elements: any) {
  if (!elements.length) {
    return false;
  }

  return {
    x: elements[0].element.x + 6,
    y: elements[0].element.y,
  };
};

export const SpotPriceChart: FC<{
  data: EnergyPriceDay;
  setSelected: null | ((slot: EnergyPriceSlot | null) => void);
  selected?: EnergyPriceSlot | null;
  chartHeight: string;
  enableTooltip: boolean;
  enableAnnotation?: boolean;
  timezone: string | undefined;
  priceLevel?: any;
  id: string;
}> = ({
  chartHeight,
  data,
  setSelected,
  selected,
  enableTooltip,
  enableAnnotation,
  timezone,
  priceLevel,
  id,
}) => {
  const [maxY, setMaxY] = useState<number>(() => {
    let max = Math.max(...data.slots.map((d) => d.purPrice));

    return (max = Math.max(3, Math.ceil(max * 1.2)));
  });
  // currentPriceSlot is used to trigger an update of the annotation
  // when slot is updated.
  const [currentPriceSlot] = useCurrentPriceSlot(timezone, data);
  const [counter, setCounter] = useState(0);

  const DEFAULT_VALUES = {
    very_low: 0,
    low: 1,
    normal: 1.5,
    high: 2,
    very_high: 3,
  };

  const getPriceLevel = (
    priceLevel: { levels: { [x: string]: any } },
    key: string,
    defaultValue: number
  ) => {
    return priceLevel && key in priceLevel.levels
      ? priceLevel.levels[key]
      : defaultValue;
  };

  // Helps us move the annotation every minute.
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const borderColor = (context: any) => {
    const chart = context.chart;
    const { ctx, chartArea } = chart;
    if (!chartArea) {
      // This case happens on initial chart load
      return;
    }
    return getGradient(ctx, chartArea);
  };

  const chartRef = useRef<ChartJS<"line", EnergyPriceSlot[], string>>(null);
  useEffect(() => {
    if (chartRef.current === null) {
      return;
    }
    chartRef.current.tooltip?.setActiveElements([], { x: 0, y: 0 });
  }, [data]);

  const options = useMemo((): any => {
    if (data.slots.length === 0) {
      return;
    }

    const now = DateTime.now().setZone(timezone);
    const isToday = data.slots[0].startTime.day == now.day;
    const index = data.slots.findIndex(
      (slot) => slot.startTime.get("hour") === now.get("hour")
    );
    const selectedPos = {
      x: now,
      y: data.slots[index].purPrice,
    };
    if (
      selected &&
      selected.startTime.toFormat("dd:hh") !== now.toFormat("dd:hh")
    ) {
      // If the selected is the same as the current hour, set it based on
      // the current time instead.
      selectedPos.x = selected.startTime.plus({ minutes: 30 });
      selectedPos.y = selected.purPrice;
    }

    const backgroundColor = (context: any) => {
      const chart = context.chart;
      const { ctx, chartArea } = chart;
      if (!chartArea) {
        // This case happens on initial chart load
        return;
      }

      const data = chart.data.datasets[0].data;
      const max = Math.max(...data.map((d: EnergyPriceSlot) => d.purPrice));

      const gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      );

      for (let i = 0; i < data.length; i++) {
        const slot = data[i];
        const stop = slot.purPrice / max;
        const color =
          stop <= 0.5
            ? `rgba(76, 223, 232, ${stop * 2})`
            : `rgba(${255 - Math.round(stop * 255)}, 0, 0, ${stop * 2})`;
        gradient.addColorStop(stop, color);
      }

      return gradient;
    };

    return {
      hover: { mode: null },
      responsive: true,
      maintainAspectRatio: false,
      annotations: {
        line1: {
          type: "line",
          mode: "vertical",
          scaleID: "x",
          value: selectedPos.x,
          borderColor: "#6D7278",
          borderWidth: 1,
          borderDash: [0, 1, 2],
          display: enableAnnotation ?? false,
        },
        now: {
          type: "point",
          xValue: DateTime.now().setZone(timezone),
          yValue: data.slots[index].purPrice,
          backgroundColor: borderColor,
          radius: 4,
          borderWidth: 1,
          borderColor: "#fff",
          display: enableAnnotation && isToday,
        },
        point1: {
          type: "point",
          xValue: selectedPos.x,
          yValue: selectedPos.y,
          backgroundColor: "#333",
          radius: 5,
          borderWidth: 1,
          borderColor: "#fff",
          display: enableAnnotation ?? false,
        },
      },
      interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: enableTooltip,
          position: "stepperPositioner",
          xAlign: "center",
          yAlign: "bottom",
          titleAlign: "center",
          bodyAlign: "center",
          caretPadding: 10,
          displayColors: false,
          callbacks: {
            title: (item: any) => {
              return `${item[0].raw.purPrice?.toFixed(2)} SEK / kWh`;
            },
            label: (item: any, here: any) => {
              if (setSelected !== null) {
                setSelected(item.raw);
              }
              return `${item.raw.startTime.toFormat(
                "HH:mm"
              )} - ${item.raw.endTime.toFormat("HH:mm")}`;
            },
          },
        },
      },
      parsing: {
        xAxisKey: "startTime",
        yAxisKey: "purPrice",
      },
      scales: {
        x: {
          type: "time",
          adapters: {
            date: {
              zone: timezone,
            },
          },
          time: {
            tooltipFormat: "HH:mm",
            displayFormats: {
              minute: "HH:mm",
              hour: "HH",
            },
          },
          grid: {
            color: "white",
            borderDash: [0, 1],
          },
          title: {
            display: false,
          },
          ticks: {
            color: "black",
          },
        },
        y: {
          max: maxY,
          ticks: {
            callback: function (label: any, index: any, labels: any) {
              return label.toFixed(1);
            },
            color: "#6D7278",
          },
        },
      },
    };
  }, [data, timezone, counter, selected, currentPriceSlot]);

  let width: number, height: number, gradient: any;
  const getGradient = useCallback((ctx: any, chartArea: any) => {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;

    const priceLevelVeryLow = getPriceLevel(
      priceLevel,
      "very_low",
      DEFAULT_VALUES.very_low
    );
    const priceLevelLow = getPriceLevel(priceLevel, "low", DEFAULT_VALUES.low);
    const priceLevelNormal = getPriceLevel(
      priceLevel,
      "normal",
      DEFAULT_VALUES.normal
    );
    const priceLevelHigh = getPriceLevel(
      priceLevel,
      "high",
      DEFAULT_VALUES.high
    );
    const priceLevelVeryHigh = getPriceLevel(
      priceLevel,
      "very_high",
      DEFAULT_VALUES.very_high
    );

    if (!gradient || width !== chartWidth || height !== chartHeight) {
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      );

      gradient.addColorStop(
        (priceLevelVeryLow - priceLevelVeryLow) / maxY,
        "#6DD400"
      );
      gradient.addColorStop(
        (priceLevelLow - priceLevelVeryLow) / maxY,
        "#A4D65E"
      );
      gradient.addColorStop(
        (priceLevelNormal - priceLevelVeryLow) / maxY,
        "#FFD700"
      );
      gradient.addColorStop(
        (priceLevelHigh - priceLevelVeryLow) / maxY,
        "#D65900"
      );
      gradient.addColorStop(
        (priceLevelVeryHigh - priceLevelVeryLow) / maxY,
        "#D42500"
      );
    }

    return gradient;
  }, []);
  return (
    <Line
      ref={chartRef}
      height={chartHeight}
      //@ts-ignore
      options={options}
      data={{
        datasets: [
          {
            type: "line",
            stepped: true,
            label: "Price",
            data: data.slots,
            borderColor: borderColor,
            fill: false,
            backgroundColor: "rgba(76, 223, 232, 0.2)",
            pointRadius: 0,
            borderWidth: 3,
          },
        ],
      }}
    />
  );
};
