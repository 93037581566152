import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Card, Button, Typography, Container } from "../../../Atoms";
import BackHeader from "../../../Molecules/BackHeader/BackHeader";
import { Facility } from "../../../../../api/facility";
import DateSelector, {
  DateSelection,
} from "../../../Molecules/DateScroll/DateSelector";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchEmsUsageRange, Usage } from "../../../../../api/usage/ems";
import { Cost, fetchCostRange } from "../../../../../api/usage/cost";
import {
  CostGrid,
  fetchCostRangeGrid,
} from "../../../../../api/usage/gridCost";
import { UsageQuery } from "../../../../../api/usage/query";
import { CompareUsageChart, CostChart, UsageChart } from "../../../Atoms/Chart";
import {
  ValueGridCard,
  ValueGridRow,
  ValueGridSlot,
} from "../../../Molecules/ValueGrid/ValueGridCard";
import { Link } from "react-router-dom";

import LineG from "./LineG";

const StatsPageRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  gap: 14px;
  height: 100%;
  background-color: #db4d4c;
  overflow: scroll;
`;

const Grid: FC<{
  facility: Facility;
}> = ({ facility }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [dateSelection, setDateSelection] = useState<
    DateSelection | undefined
  >();
  const [usage, setUsage] = useState<Array<Usage>>(new Array());
  const [cost, setCost] = useState<Array<Cost>>(new Array());
  const [costGrid, setCostGrid] = useState<Array<CostGrid>>(new Array());

  const loadUsage = async (query: UsageQuery) => {
    const usage = await fetchEmsUsageRange(
      facility.id,
      query,
      getAccessTokenSilently()
    );
    setUsage(usage);
  };

  const loadCost = async (query: UsageQuery) => {
    let cost = await fetchCostRange(
      facility.id,
      query,
      getAccessTokenSilently()
    );
    setCost(cost);
  };

  const loadCostGrid = async (query: UsageQuery) => {
    let costGrid = await fetchCostRangeGrid(
      facility.id,
      query,
      getAccessTokenSilently()
    );
    setCostGrid(costGrid);
  };

  useEffect(() => {
    if (dateSelection === undefined) {
      return;
    }

    let query: UsageQuery = {
      duration: "",
      start: dateSelection.start,
      stop: dateSelection.stop,
    };

    switch (dateSelection.period) {
      case "day":
        query.duration = "1h";
        break;
      case "week":
      case "month":
        query.duration = "1d";
        break;
      case "year":
        query.duration = "1mo";
        break;
    }

    loadUsage(query);
    loadCost(query);
    loadCostGrid(query);
  }, [dateSelection]);

  const total = [...usage];
  total.sort((a, b) => b.load - a.load);
  const [highest] = total;
  const lowest = total[total.length - 1];

  return (
    <Container
      flexDirection="column"
      padding="14px 14px 27px 14px"
      gap={16}
      height="100vh"
      bgColor="#fafafa"
      scroll="scroll"
    >
      <BackHeader title="Grid Usage" goBack="/stats" />

      <DateSelector
        timezone={facility.timezone}
        stateSetter={setDateSelection}
        sharedState={true}
      />

      {dateSelection !== undefined && (
        <>
          <Card backgroundColor="White" flex="0">
            <UsageChart
              timezone={facility.timezone}
              period={dateSelection.period}
              usage={usage}
              point={"grid"}
              mode="consumption"
            />
          </Card>

          <Card backgroundColor="White" flex="0">
            <LineG
              timezone={facility.timezone}
              period={dateSelection.period}
              usage={costGrid}
            />
            <Container justifyContent="center">
              <Link to="/breakdown" style={{ textDecoration: "none" }}>
                <Button
                  bgColor="#15C046"
                  padding="4px 8px"
                  margin="8px 8px 0 8px"
                >
                  <Typography
                    color="white"
                    fontSize="Small"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                  >
                    Show cost breakdown
                  </Typography>
                </Button>
              </Link>
            </Container>
          </Card>

          <Card backgroundColor="White" flex="0">
            <CompareUsageChart
              facility={facility}
              period={dateSelection.period}
              point="grid"
              highlight={6 - dateSelection.index}
              mode="consumption"
            />
          </Card>
        </>
      )}

      <ValueGridCard title="Consumed">
        <ValueGridRow>
          <ValueGridSlot
            value={usage
              .reduce((partialSum: number, a: Usage) => {
                return partialSum + a.grid;
              }, 0)
              ?.toFixed(2)}
            suffix="kWh"
            desc="Grid"
          />
          <ValueGridSlot
            value={cost
              .reduce((partialSum: number, a: Cost) => {
                return partialSum + a.grid_cost;
              }, 0)
              ?.toFixed(2)}
            suffix="SEK"
            desc="Cost"
          />
        </ValueGridRow>
      </ValueGridCard>
    </Container>
  );
};

export default Grid;
