import { ThemeStyleProps } from "./type";

export type FontSizes = {
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  h5: string;
  h6: string;
  p: string;
  mini: string;
  micro: string;
  nano: string;
};

export const fontSizes: FontSizes = {
  h1: "64px",
  h2: "48px",
  h3: "32px",
  h4: "24px",
  h5: "20px",
  h6: "18px",
  p: "16px",
  mini: "14px",
  micro: "12px",
  nano: "8px",
};

const themes: ThemeStyleProps[] = [
  {
    name: "DEFAULT",
    borderRadius: "4px",
    borders: { primary: "2px solid #0070f3", secondary: "2px solid #5352ed" },
    borderStyles: {
      solid: "solid",
      dotted: "dotted",
      dashed: "dashed",
    },
    borderWidths: {
      small: "1px",
      medium: "2px",
      large: "4px",
    },
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    colors: {
      primary: "#000000",
      secondary: "#ffffff",
      sunrise: "#fe9d70",
      lightGrey: "#f8f8f8",
      electricBlue: "#0000ff",
      electricGreen: "#3ee98d",
    },
    fonts: {
      primary: "Open Sans, sans-serif",
      secondary: "Lato, sans-serif",
    },
    fontSizes: fontSizes,

    fontWeights: { light: 300, regular: 400, medium: 500, bold: 700 },
    letterSpacings: { small: "1px", medium: "2px", large: "3px" },
    lineHeights: { small: "1.2em", medium: "1.5em", large: "2em" },
    sizes: { small: "24px", medium: "48px", large: "64px" },
    space: {
      xxsmall: "4px",
      xsmall: "8px",
      small: "12px",
      medium: "16px",
      large: "20px",
      xlarge: "24px",
      xxlarge: "28px",
    },
    transitions: {
      fast: "0.2s ease-in-out",
      regular: "0.4s ease-in-out",
      slow: "0.6s ease-in-out",
    },
    zIndex: { main: 1, modal: 9 },
  },
];
export default themes;
