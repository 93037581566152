import { FC, useMemo } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-luxon";
import { DateSelection } from "../../../Molecules/DateScroll/DateSelector";

ChartJS.register(LinearScale, BarElement, Title, Tooltip, Legend, TimeScale);

interface HistoryItem {
  soc: number;
  time: string;
}

export const SocChart: FC<{
  timezone: string;
  dateSelection: DateSelection;
  history: Array<HistoryItem>;
}> = ({ timezone, dateSelection, history }) => {
  const data = useMemo(() => {
    return {
      datasets: [
        {
          pointHitRadius: 16,
          data: history.map((item: HistoryItem) => {
            return {
              y: item.soc,
              x: item.time,
            };
          }),
          backgroundColor: "#5ba9f5",
        },
      ],
    };
  }, [history]);

  const options = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      borderColor: "#007aff",
      backgroundColor: "#007aff",
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        y: {
          ticks: {
            callback: function (label: any) {
              return label.toFixed(0) + "%";
            },
          },
          min: 0,
          max: 100,
          beginAtZero: true,
          title: {
            display: false,
            text: "%",
            color: "#6D7278",
          },
        },
        x: {
          offset: false,
          type: "time" as const,
          adapters: {
            date: {
              zone: timezone,
            },
          },
          time: {
            tooltipFormat: "HH:mm",
            displayFormats: {
              minute: "HH:mm",
              hour: "HH:00",
              day: "EEEE",
            },
          },
          max: dateSelection.stop.toISO(),
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "State of charge",
        },
        tooltip: {
          titleAlign: "center" as const,
          bodyAlign: "center" as const,
          caretPadding: 10,
          displayColors: false,
          callbacks: {
            label: (item: any) => {
              return `${item?.parsed?.y} %`;
            },
          },
        },
      },
    };
  }, [dateSelection, history]);

  return <Line options={options} data={data} id="statsBar" />;
};
