import React, { FC, useState } from "react";
import styled from "styled-components";

import { Container, Card } from "../../Atoms";
import type { WidgetProps } from "./types";

const WidgetContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const Widget: FC<WidgetProps> = ({ front, back, disableWidget, ...rest }) => {
  const [widgetOpen, setWidgetOpen] = useState<boolean>(false);
  return (
    <WidgetContainer {...rest}>
      <div
        className={`card ${widgetOpen ? "rotated" : ""} `}
        onClick={() =>
          disableWidget ? null : setWidgetOpen((oldState) => !oldState)
        }
      >
        <div className="front ">{front}</div>
        <div className="back ">{back}</div>
      </div>
    </WidgetContainer>
  );
};
export default Widget;
