import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Card, Container, Hr, Typography } from "../../Atoms";
import BackHeader from "./../../Molecules/BackHeader/BackHeader";
import { Facility } from "../../../../api/facility";
import { fetchEmsUsageRange, Usage } from "../../../../api/usage/ems";
import { Cost, fetchCostRange } from "../../../../api/usage/cost";
import { UsageQuery } from "../../../../api/usage/query";
import { useAuth0 } from "@auth0/auth0-react";
import DateSelector, {
  DateSelection,
} from "../../Molecules/DateScroll/DateSelector";
import { Period } from "../../../../ContextApi/StateProvider/type";
import { CostChart, UsageChart, CompareUsageChart } from "../../Atoms/Chart";
const StatsPageRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  gap: 14px;
  height: 100%;
  background-color: #000000;
  overflow: scroll;
`;
const DailyPage: FC<{
  facility: Facility;
}> = ({ facility }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [dateSelection, setDateSelection] = useState<
    DateSelection | undefined
  >();
  const [usage, setUsage] = useState<Array<Usage>>(new Array());
  const [cost, setCost] = useState<Array<Cost>>(new Array());

  const loadUsage = async (query: UsageQuery) => {
    const usage = await fetchEmsUsageRange(
      facility.id,
      query,
      getAccessTokenSilently()
    );
    setUsage(usage);
  };

  const loadCost = async (query: UsageQuery) => {
    let cost = await fetchCostRange(
      facility.id,
      query,
      getAccessTokenSilently()
    );
    setCost(cost);
  };

  useEffect(() => {
    if (dateSelection === undefined) {
      return;
    }

    let query: UsageQuery = {
      duration: "",
      start: dateSelection.start,
      stop: dateSelection.stop,
    };

    switch (dateSelection.period) {
      case "day":
        query.duration = "1h";
        break;
      case "week":
      case "month":
        query.duration = "1d";
        break;
      case "year":
        query.duration = "1mo";
        break;
    }

    loadUsage(query);
    loadCost(query);
  }, [dateSelection]);

  return (
    <Container
      flexDirection="column"
      padding="14px 14px 27px 14px"
      gap={16}
      height="100vh"
      bgColor="#fafafa"
      scroll="scroll"
    >
      <BackHeader title="Daily usage" goBack="/" />

      <DateSelector
        timezone={facility.timezone}
        stateSetter={setDateSelection}
      />

      {dateSelection !== undefined && (
        <>
          <Card backgroundColor="White" flex="0">
            <UsageChart
              timezone={facility.timezone}
              period={dateSelection.period}
              usage={usage}
              point={"load"}
              mode="consumption"
            />
          </Card>

          <Card backgroundColor="White" flex="0">
            <CostChart
              timezone={facility.timezone}
              period={dateSelection.period}
              cost={cost}
              point={"grid_cost"}
              mode="consumption"
            />
          </Card>

          <Card backgroundColor="White" flex="0">
            <CompareUsageChart
              facility={facility}
              period={dateSelection.period}
              point="load"
              highlight={6 - dateSelection.index}
              mode="consumption"
            />
          </Card>
        </>
      )}

      <Card backgroundColor="White" flex="0">
        <Container flexDirection="column" flex={1}>
          <Typography
            color="black"
            fontSize="H6"
            marginBottom="None"
            marginTop="None"
            marginLeft="None"
          >
            Consumed
          </Typography>
          <Hr color="D8D8D8" />
          <Container flexDirection="row" flex={1}>
            <Container flexDirection="column" flex={1}>
              <Container
                flexDirection="row"
                flex={1}
                justifyContent="flex-start"
                alignItems="flex-end"
              >
                <Typography
                  color="black"
                  fontSize="H4"
                  marginBottom="None"
                  marginTop="None"
                  marginLeft="None"
                >
                  {usage
                    .reduce((partialSum: number, a: Usage) => {
                      return partialSum + a.load;
                    }, 0)
                    ?.toFixed(2)}
                </Typography>
                <Typography
                  color="black"
                  fontSize="Small"
                  marginBottom="None"
                  marginTop="None"
                  marginLeft="None"
                  noTransform
                >
                  kWh
                </Typography>
              </Container>
              <Typography
                color="#A4A8AA"
                fontSize="Small"
                marginTop="None"
                marginLeft="None"
                marginBottom="None"
              >
                Home
              </Typography>
            </Container>
            <Container
              flexDirection="column"
              flex={1}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Container
                flexDirection="row"
                flex={1}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Typography
                  color="black"
                  fontSize="H4"
                  marginBottom="None"
                  marginTop="None"
                  marginLeft="None"
                >
                  {cost
                    .reduce((partialSum: number, a: Cost) => {
                      return partialSum + a.grid_cost;
                    }, 0)
                    ?.toFixed(2)}
                </Typography>
                <Typography
                  color="black"
                  fontSize="Small"
                  marginBottom="None"
                  marginTop="None"
                  marginLeft="None"
                >
                  SEK
                </Typography>
              </Container>
              <Typography
                color="#A4A8AA"
                fontSize="Small"
                marginTop="None"
                marginLeft="None"
                marginBottom="None"
              >
                Cost
              </Typography>
            </Container>
          </Container>
        </Container>
      </Card>
    </Container>
  );
};

export default DailyPage;
