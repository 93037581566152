import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Card, Container } from "../../../Atoms";
import BackHeader from "../../../Molecules/BackHeader/BackHeader";
import { useAuth0 } from "@auth0/auth0-react";
import { Facility } from "../../../../../api/facility";
import { Cost, fetchCostRange } from "../../../../../api/usage/cost";
import { Usage, fetchEmsUsageRange } from "../../../../../api/usage/ems";
import { UsageQuery } from "../../../../../api/usage/query";
import DateSelector, {
  DateSelection,
} from "../../../Molecules/DateScroll/DateSelector";
import { CostChart } from "../../../Atoms/Chart";
import { SocChart } from "./SocChart";
import { DateTime } from "luxon";
import {
  ValueGridCard,
  ValueGridRow,
  ValueGridSlot,
} from "../../../Molecules/ValueGrid/ValueGridCard";
import { ElectricityFromCard } from "../../../Molecules/WipCard/ElectricityFromCard";
import { PeaksCard } from "../../../Molecules/WipCard/PeaksCard";
import CompareCostChart from "./CompareCostChart";

const StatsPageRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  gap: 14px;
  height: 100%;
  background-color: #143042;
  overflow: scroll;
`;

const Battery: FC<{
  facility: Facility;
}> = ({ facility }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [dateSelection, setDateSelection] = useState<
    DateSelection | undefined
  >();
  const [usage, setUsage] = useState<Array<Usage>>(new Array());
  const [cost, setCost] = useState<Array<Cost>>(new Array());
  const [socHistory, setSocHistory] = useState<
    Array<{ time: string; soc: number }>
  >(new Array());

  const loadUsage = async (query: UsageQuery) => {
    const usage = await fetchEmsUsageRange(
      facility.id,
      query,
      getAccessTokenSilently()
    );
    setUsage(usage);
  };

  const loadCost = async (query: UsageQuery) => {
    let cost = await fetchCostRange(
      facility.id,
      query,
      getAccessTokenSilently()
    );
    setCost(cost);
  };

  useEffect(() => {
    if (dateSelection === undefined) {
      return;
    }

    let query: UsageQuery = {
      duration: "",
      start: dateSelection.start,
      stop: dateSelection.stop,
    };

    switch (dateSelection.period) {
      case "day":
        query.duration = "1h";
        break;
      case "week":
      case "month":
        query.duration = "1d";
        break;
      case "year":
        query.duration = "1mo";
        break;
    }

    loadUsage(query);
    loadCost(query);

    if (["day", "week"].includes(dateSelection.period)) {
      fetchSOC("10m", dateSelection.start, dateSelection.stop);
    }
  }, [dateSelection]);

  const fetchAuthenticated = async (url: string): Promise<Response> => {
    const accessToken = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return fetch(url, {
      headers: headers,
    });
  };

  const fetchSOC = async (duration: string, start: DateTime, end: DateTime) => {
    const response = await fetchAuthenticated(
      `https://gate.thiink.io/hc/v1/facility/${facility?.id}/state_history?` +
        new URLSearchParams({
          aggregate_duration: duration,
          start: start.toISO(),
          stop: end.toISO(),
        })
    );

    const history = await response.json();
    setSocHistory(history.points);
  };

  return (
    <Container
      flexDirection="column"
      padding="14px 14px 27px 14px"
      gap={16}
      height="100vh"
      bgColor="#fafafa"
      scroll="scroll"
    >
      <BackHeader title="Battery usage" goBack="/stats" />

      <DateSelector
        timezone={facility.timezone}
        stateSetter={setDateSelection}
        sharedState={true}
      />

      {dateSelection !== undefined && (
        <>
          {["day", "week"].includes(dateSelection.period) && (
            <Card backgroundColor="White" flex="0">
              <SocChart
                timezone={facility.timezone}
                dateSelection={dateSelection}
                history={socHistory}
              />
            </Card>
          )}

          <Card backgroundColor="White" flex="0">
            <CostChart
              timezone={facility.timezone}
              period={dateSelection.period}
              cost={cost}
              point={"battery_profit"}
              mode="production"
            />
          </Card>

          <Card backgroundColor="White" flex="0">
            <CompareCostChart
              facility={facility}
              period={dateSelection.period}
              point="battery_profit"
              highlight={6 - dateSelection.index}
              mode="production"
            />
          </Card>
        </>
      )}

      <ValueGridCard title="Produced">
        <ValueGridRow>
          <ValueGridSlot
            value={usage
              .reduce((partialSum: number, a: Usage) => {
                return partialSum + a.battery;
              }, 0)
              ?.toFixed(2)}
            suffix="kWh"
            desc="Battery"
          />
          <ValueGridSlot
            value={cost
              .reduce((partialSum: number, a: Cost) => {
                return partialSum + a.battery_profit;
              }, 0)
              ?.toFixed(2)}
            suffix="SEK"
            desc="Profit"
          />
        </ValueGridRow>
      </ValueGridCard>

      <ValueGridCard title="Earned" showWip={true}>
        <ValueGridRow>
          <ValueGridSlot value="45.25" suffix="kWh" desc="Sold" />
          <ValueGridSlot
            value="187.14"
            suffix="SEK"
            desc="Earned"
            valueColor="#58AC00"
          />
        </ValueGridRow>
      </ValueGridCard>

      <ValueGridCard title="Saved" showWip={true}>
        <ValueGridRow>
          <ValueGridSlot value="37.18" suffix="kWh" desc="Used" />
          <ValueGridSlot
            value="141.14"
            suffix="SEK"
            desc="Earned"
            valueColor="#58AC00"
          />
        </ValueGridRow>
      </ValueGridCard>

      <ValueGridCard title="Battery coverage" showWip={true}>
        <ValueGridRow>
          <ValueGridSlot value="80" suffix="kWh" desc="Coverage" />
          <ValueGridSlot value="160" suffix="kWh" desc="Total Usage" />
        </ValueGridRow>
      </ValueGridCard>

      <ValueGridCard title="Batteries" showWip={true}>
        <ValueGridRow>
          <ValueGridSlot value="1" suffix="battery" desc="Owned" />
          <ValueGridSlot value="2" suffix="batteries" desc="Recommended" />
        </ValueGridRow>
      </ValueGridCard>

      <ValueGridCard title="Battery cycles" showWip={true}>
        <ValueGridRow>
          <ValueGridSlot value="415" suffix="times" desc="Charged" />
          <ValueGridSlot value="10000" suffix="times" desc="Max Cycles" />
        </ValueGridRow>
      </ValueGridCard>

      <ElectricityFromCard />
      {/* <PeaksCard /> */}
    </Container>
  );
};

export default Battery;
