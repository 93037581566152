import React, { FC } from "react";
import styled from "styled-components";
import { ButtonProps } from "./types";

const ButtonElement = styled.button<ButtonProps>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#000000")};
  border: none;
  padding: ${(props) => (props.padding ? props.padding : 0)};
  border-radius: 4px;
  margin: ${(props) => (props.margin ? props.margin : 0)};
`;

const Button: FC<ButtonProps> = ({
  bgColor,
  padding,
  margin,
  children,
  ...rest
}) => {
  return (
    <ButtonElement
      bgColor={bgColor}
      padding={padding}
      margin={margin}
      {...rest}
    >
      {children}
    </ButtonElement>
  );
};
export default Button;
