import { useAuth0 } from "@auth0/auth0-react";
import { FC, useEffect, useMemo, useRef } from "react";
import { Facility } from "../../../../api/facility";
import { useCost } from "../../../../api/usage/cost";
import { useEmsUsage } from "../../../../api/usage/ems";
import { DailyQuery } from "../../../../api/usage/query";
import { Card, Container, Typography } from "../../Atoms";
import FlatData from "../../Molecules/FlatData/FlatData";

export interface DailyProps {
  facility: Facility;
}

const Daily: FC<DailyProps> = ({ facility }) => {
  const { getAccessTokenSilently } = useAuth0();

  const { usage, mutate: reloadEms } = useEmsUsage(
    facility,
    DailyQuery(facility.localTime()),
    getAccessTokenSilently()
  );

  const { cost, mutate: reloadCost } = useCost(
    facility,
    DailyQuery(facility.localTime()),
    getAccessTokenSilently()
  );

  let todaysUsage = useMemo(() => {
    if (usage === undefined) {
      return {
        battery: 0,
        pv: 0,
        load: 0,
        grid: 0,
      };
    }
    return usage;
  }, [usage]);

  let todaysCost = useMemo(() => {
    if (cost === undefined) {
      return {
        grid_cost: 0,
      };
    }
    return {
      grid_cost: cost.grid_cost,
    };
  }, [cost]);

  const initialLoad = useRef(false);
  useEffect(() => {
    if (initialLoad.current === true) {
      // component already initialized.
      return;
    }

    const interval = setInterval(() => {
      reloadCost();
      reloadEms();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container padding="0px" flexDirection="column" id="mobile">
      <Card backgroundColor="Black">
        <Container
          flexDirection="column"
          justifyContent="center"
          flex={1}
          gap={16}
        >
          <Typography color="White" marginBottom="None" marginTop="None">
            Daily
          </Typography>
          <Container
            flexDirection="row"
            justifyContent="space-between"
            flex={1}
            gap={16}
          >
            <FlatData
              bgColor="#ffffff1f"
              dataValue={todaysUsage.load.toFixed(2)}
              title="Home usage"
              unit="kWh"
              noTransform
            />
            <FlatData
              bgColor="#ffffff1f"
              dataValue={Math.abs(todaysUsage.battery).toFixed(2)}
              title={todaysUsage.battery < 0 ? "To battery" : "From battery"}
              unit="kWh"
              noTransform
            />
          </Container>
          <Container
            flexDirection="row"
            justifyContent="space-between"
            flex={1}
            gap={16}
          >
            <FlatData
              bgColor="#ffffff1f"
              dataValue={Math.abs(todaysCost.grid_cost).toFixed(2)}
              dataValueColor={todaysCost.grid_cost < 0 ? "#00ff00" : "#ff0000"}
              title={todaysCost.grid_cost < 0 ? "Earned" : "Cost"}
              unit="SEK"
            />
            <FlatData
              bgColor="#ffffff1f"
              dataValue={todaysUsage.pv.toFixed(2)}
              title="Solar panels"
              unit="kWh"
            />
          </Container>
        </Container>
      </Card>
    </Container>
  );
};

export default Daily;
