import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
import Clock from "./../../Molecules/Clock/Clock";

import { Card, Container, Typography, Icon } from "../../Atoms";
import FlatData from "../../Molecules/FlatData/FlatData";
import Widget from "./../../Molecules/Widget/Widget";
import Prius from "./../../Organisms/Prius/Prius";
import { SpotPriceChart } from "../Price/SpotPriceChart";
import Daily from "./Daily";

import type { SevenInchProps } from "./type";
import { format, sub } from "date-fns";
import { useCurrentPriceSlot } from "../../../../api/energyprice";

const Orientation = styled.div<{ padding?: string }>`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  @media (orientation: portrait) {
    display: flex;
    flex-direction: column;
  }

  @media (orientation: landscape) {
    display: flex;
    flex-direction: row;
  }
`;

const SevenInch: FC<SevenInchProps> = ({
  emsState,
  dailyUsagedata,
  detailUsage,
  usageCostData,
  facility,
  fetchOverviewData,
  jIT,
  fetchUsage,
  fetchUsageCost,
  overViewData,
  energyPriceDay,
}) => {
  const [disableSwipe, setDisableSwipe] = useState<boolean>(true);
  const [isOpenDaily, setIsOpenDaily] = useState<boolean>(false);
  const [isOpenPrius, setIsOpenPrius] = useState<boolean>(false);
  const [isOpenSpotPrice, setIsOpenSpotPrice] = useState<boolean>(false);
  const [swiper, setSwiper] = useState<any>();
  const [currentPriceSpot] = useCurrentPriceSlot(
    facility?.timezone,
    energyPriceDay
  );

  const todaydate = new Date();

  const fetchDaily = async () => {
    await fetchOverviewData(
      "day",
      format(sub(todaydate, { days: 6 }), `yyyy-MM-dd`),
      format(new Date(), `yyyy-MM-dd`),
      "load"
    );
  };

  useEffect(() => {
    fetchDaily();
  }, []);

  return (
    <Orientation padding="16px" className="rpi7inch">
      <Swiper
        grabCursor={true}
        onSwiper={(item) => setSwiper(item)}
        slidesPerView={2}
        pagination={true}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide style={{ width: `${isOpenDaily ? "100%" : "384px"}` }}>
          <Widget
            onClick={() => {
              setIsOpenDaily((oldState) => !oldState);
              swiper.slideTo(0);
              swiper.allowTouchMove = !disableSwipe;
              setDisableSwipe((oldState) => !oldState);
            }}
            front={
              <Card backgroundColor="Transparent" noPadding>
                <Container
                  flexDirection="column"
                  justifyContent="center"
                  flex={1}
                  gap={16}
                >
                  <Clock time />
                </Container>
                <Container
                  flexDirection="column"
                  justifyContent="center"
                  flex={2}
                  gap={16}
                >
                  <Container
                    flexDirection="row"
                    justifyContent="space-between"
                    flex={1}
                    gap={16}
                  >
                    <FlatData
                      dataValue={
                        dailyUsagedata?.load
                          ? dailyUsagedata?.load?.toFixed(2)
                          : 0
                      }
                      dataValueColor="#ffffff"
                      title="Home usage"
                      unit="kWh"
                    />
                    <FlatData
                      dataValue={
                        dailyUsagedata?.battery
                          ? dailyUsagedata?.battery?.toFixed(2)
                          : 0
                      }
                      title={
                        dailyUsagedata?.battery < 0
                          ? "To battery"
                          : "From battery"
                      }
                      dataValueColor="#ffffff"
                      unit="kWh"
                    />
                  </Container>

                  <Container
                    flexDirection="row"
                    justifyContent="space-between"
                    flex={1}
                    gap={16}
                  >
                    <FlatData
                      dataValue={
                        jIT.length
                          ? Math.abs(
                              jIT
                                .reduce(
                                  (partialSum: number, a: number) =>
                                    partialSum + a
                                )
                                ?.toFixed(2)
                            )
                          : 0
                      }
                      dataValueColor={
                        jIT
                          .reduce(
                            (partialSum: number, a: number) => partialSum + a,
                            0
                          )
                          ?.toFixed(2) < 0
                          ? "#6DD400"
                          : "#E02020"
                      }
                      title={
                        jIT
                          .reduce(
                            (partialSum: number, a: number) => partialSum + a,
                            0
                          )
                          ?.toFixed(2) < 0
                          ? "Earned"
                          : "Cost"
                      }
                      unit="SEK"
                    />
                    <FlatData
                      dataValue={
                        dailyUsagedata?.pv ? dailyUsagedata?.pv?.toFixed(2) : 0
                      }
                      dataValueColor="#ffffff"
                      title="Solar panels"
                      unit="kWh"
                    />
                  </Container>
                </Container>
              </Card>
            }
            back={
              <Card backgroundColor="Black" maxWidth>
                <Daily
                  detailUsage={detailUsage}
                  fetchOverviewData={fetchOverviewData}
                  fetchUsage={fetchUsage}
                  fetchUsageCost={fetchUsageCost}
                  overViewData={overViewData}
                  usageCostData={usageCostData}
                />
              </Card>
            }
          />
        </SwiperSlide>
        <SwiperSlide style={{ width: `${isOpenPrius ? "100%" : "384px"}` }}>
          <Widget
            id="priusWidget"
            onClick={() => {
              setIsOpenPrius((oldState) => !oldState);
              swiper.slideTo(isOpenPrius ? 0 : 1);
              swiper.allowTouchMove = !disableSwipe;
              setDisableSwipe((oldState) => !oldState);
            }}
            front={
              <Card backgroundColor="BluePrintBlue" noPadding>
                <Prius
                  emsState={emsState}
                  batteryActive={true}
                  solarPanelActive={emsState.pv > 0 ? true : false}
                  houseActive={true}
                  priusHeight="360px"
                  priceSlot={currentPriceSpot}
                  gridActive={true}
                  width="100%"
                  height="100%"
                  viewBox="0 0 640 400"
                  hideInfoData={true}
                />
              </Card>
            }
            back={
              <Card backgroundColor="BluePrintBlue" maxWidth noPadding>
                <Container
                  flexDirection="column"
                  justifyContent="flex-start"
                  flex={1}
                  gap={16}
                  padding="0 0 0 16px"
                >
                  <Prius
                    emsState={emsState}
                    batteryActive={true}
                    solarPanelActive={emsState.pv > 0 ? true : false}
                    houseActive={true}
                    priusHeight="360px"
                    priceSlot={currentPriceSpot}
                    gridActive={true}
                    width="700px"
                    viewBox="-60 -30 800 400"
                    hideInfoData={false}
                  />
                </Container>
              </Card>
            }
          />
        </SwiperSlide>

        <SwiperSlide
          style={{
            width: `${isOpenSpotPrice ? "100%" : "384px"}`,
          }}
        >
          <Orientation>
            <Widget
              id="spotPrice"
              disableWidget
              front={
                <Card backgroundColor="White">
                  <Container
                    flexDirection="column"
                    justifyContent="center"
                    flex={1}
                    gap={16}
                  >
                    <Typography
                      color="black"
                      marginBottom="None"
                      marginTop="None"
                    >
                      Electricity price
                    </Typography>
                    <Container
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="baseline"
                      flex={1}
                    >
                      <Container
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="baseline"
                        flex={1}
                      >
                        <Typography
                          color="black"
                          marginBottom="None"
                          marginTop="None"
                          fontSize="H2"
                        >
                          {currentPriceSpot.purPrice.toFixed(2)}
                        </Typography>
                        <Typography
                          color="black"
                          marginBottom="None"
                          marginTop="None"
                          fontSize="H5"
                        >
                          SEK / kWh
                        </Typography>
                      </Container>
                      <Container
                        flexDirection="column"
                        alignItems="center"
                        flex={1}
                      >
                        <Typography
                          color="black"
                          marginBottom="None"
                          marginTop="None"
                          fontSize="H5"
                        >
                          Current price
                        </Typography>
                      </Container>
                    </Container>

                    <Container
                      flexDirection="row"
                      justifyContent="space-between"
                      flex={1}
                      gap={16}
                    >
                      {energyPriceDay !== undefined && (
                        <SpotPriceChart
                          timezone={facility?.timezone}
                          enableAnnotation={true}
                          enableTooltip={false}
                          chartHeight="150px"
                          setSelected={null}
                          data={energyPriceDay}
                          id="sevenInchChart"
                        ></SpotPriceChart>
                      )}
                    </Container>
                  </Container>
                </Card>
              }
              back={
                <Card backgroundColor="White" maxWidth>
                  <Container
                    flexDirection="column"
                    justifyContent="center"
                    flex={1}
                  >
                    <Typography
                      color="black"
                      marginBottom="None"
                      marginTop="None"
                      fontSize="H5"
                    >
                      Coming soon
                    </Typography>
                  </Container>
                </Card>
              }
            />
          </Orientation>
        </SwiperSlide>
      </Swiper>
    </Orientation>
  );
};

export default SevenInch;
