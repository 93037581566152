import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

import { Card, Container } from "../../Atoms";
import BackHeader from "./../../Molecules/BackHeader/BackHeader";
import Prius from "./../../Organisms/Prius/Prius";
import { Facility } from "../../../../api/facility";
import {
  EnergyPriceDay,
  useCurrentPriceSlot,
} from "../../../../api/energyprice";
import { EmsState } from "../../../../api/state";
import { Weather } from "../../../../api/weather";
import { DateTime } from "luxon";

import Timeline from "../../Molecules/Timeline/Timeline";
import { batteryScheduler } from "../../../../api/batteryScheduler";

import logoImg from "./../../../../logo.svg";
import Loading from "../../Loading/Loading";

interface BatterySchedule {
  date: DateTime | null;
  schedule: OriginalBatteryScheduleItem[];
}
interface OriginalBatteryScheduleItem {
  startsAt: DateTime;
  endsAt: DateTime;
  energy: number;
}

const PriusPage: FC<{
  facility: Facility | null;
  energyPriceDay: EnergyPriceDay;
  emsState: EmsState;
  weather?: Weather;
}> = ({ facility, energyPriceDay, emsState, weather }) => {
  const [batterySchedule, setBatterySchedule] = useState<any>({
    date: null,
    schedule: [],
  });

  const [currentPriceSlot] = useCurrentPriceSlot(
    facility?.timezone,
    energyPriceDay
  );
  const { getAccessTokenSilently } = useAuth0();

  const fetchBatteryScheduler = async () => {
    if (facility === null) {
      return;
    }
    const batteryScheduleData: BatterySchedule = await batteryScheduler(
      facility,
      getAccessTokenSilently
    );
    const blocks = batteryScheduleData.schedule.map(
      (item: OriginalBatteryScheduleItem) => {
        return {
          start: item.startsAt.toFormat("HH:mm"),
          end: item.endsAt.toFormat("HH:mm"),
          label: item.energy > 0 ? "Discharge" : "Charge",
          color: item.energy > 0 ? "#4caf50" : "#f44336",
        };
      }
    );

    setBatterySchedule({ date: batteryScheduleData.date, schedule: blocks });
  };

  useEffect(() => {
    fetchBatteryScheduler();
  }, []);

  return (
    <Container
      flexDirection="column"
      padding="14px 14px 27px 14px"
      gap={16}
      height="100vh"
    >
      <BackHeader title="Home status" goBack="/" />
      <Card backgroundColor="BluePrintBlue" noPadding minHeight="200px">
        <Prius
          emsState={emsState}
          batteryActive={true}
          solarPanelActive={emsState.pv > 0 ? true : false}
          houseActive={true}
          priceSlot={currentPriceSlot}
          gridActive={true}
          width="100%"
          height="800px"
          viewBox="-30 -30 400 900"
          hideInfoData={false}
          mobile={true}
          weather={weather}
        />
      </Card>
      {batterySchedule.schedule.length > 0 ? (
        <Timeline blocks={batterySchedule.schedule} />
      ) : (
        <Loading />
      )}
    </Container>
  );
};

export default PriusPage;
