import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";

import { Card } from "../../Atoms";
import BackHeader from "./../../Molecules/BackHeader/BackHeader";
import {
  format,
  sub,
  subWeeks,
  endOfWeek,
  startOfMonth,
  subMonths,
  endOfYear,
  subYears,
  getWeek,
} from "date-fns";
import annotationPlugin from "chartjs-plugin-annotation";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const StatsPageRootContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  background-color: #000000;
`;

const GraphRoot = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
const GraphCotainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const labels = {
  day: [
    format(sub(new Date(), { days: 6 }), `EEEE`),
    format(sub(new Date(), { days: 5 }), `EEEE`),
    format(sub(new Date(), { days: 4 }), `EEEE`),
    format(sub(new Date(), { days: 3 }), `EEEE`),
    format(sub(new Date(), { days: 2 }), `EEEE`),
    "Yesterday",
    "Today",
  ],
  week: [
    `v.${getWeek(
      endOfWeek(subWeeks(new Date(), 6), {
        weekStartsOn: 1,
      }),
      {
        weekStartsOn: 1,
        firstWeekContainsDate: 4,
      }
    )}`,
    `v.${getWeek(
      endOfWeek(subWeeks(new Date(), 5), {
        weekStartsOn: 1,
      }),
      {
        weekStartsOn: 1,
        firstWeekContainsDate: 4,
      }
    )}`,
    `v.${getWeek(
      endOfWeek(subWeeks(new Date(), 4), {
        weekStartsOn: 1,
      }),
      {
        weekStartsOn: 1,
        firstWeekContainsDate: 4,
      }
    )}`,
    `v.${getWeek(
      endOfWeek(subWeeks(new Date(), 3), {
        weekStartsOn: 1,
      }),
      {
        weekStartsOn: 1,
        firstWeekContainsDate: 4,
      }
    )}`,
    `v.${getWeek(
      endOfWeek(subWeeks(new Date(), 2), {
        weekStartsOn: 1,
      }),
      {
        weekStartsOn: 1,
        firstWeekContainsDate: 4,
      }
    )}`,
    "Last week",
    "This week",
  ],
  month: [
    format(startOfMonth(subMonths(new Date(), 6)), `MMM`),
    format(startOfMonth(subMonths(new Date(), 5)), `MMM`),
    format(startOfMonth(subMonths(new Date(), 4)), `MMM`),
    format(startOfMonth(subMonths(new Date(), 3)), `MMM`),
    format(startOfMonth(subMonths(new Date(), 2)), `MMM`),
    "Last month",
    "This month",
  ],
  year: [
    format(endOfYear(subYears(new Date(), 6)), `yyyy`),
    format(endOfYear(subYears(new Date(), 5)), `yyyy`),
    format(endOfYear(subYears(new Date(), 4)), `yyyy`),
    format(endOfYear(subYears(new Date(), 3)), `yyyy`),
    format(endOfYear(subYears(new Date(), 2)), `yyyy`),
    "Last year",
    "This year",
  ],
};

export const data = {
  labels,
  datasets: [
    {
      label: "Home",
      data: [4, 1, 4, 2, 3, 6, 10],
      backgroundColor: "#5ba9f5",
      borderRadius: 50,
    },
  ],
};

const Daily: FC<{
  detailUsage: any;
  fetchOverviewData: (
    overview: any,
    startDate: any,
    endDate: any,
    dataType: string
  ) => Promise<void>;
  fetchUsage: (
    duration: string,
    start: string,
    end: string,
    dataType: string
  ) => Promise<void>;
  fetchUsageCost: (
    duration: string,
    start: string,
    end: string,
    dataType: string,
    isToday?: boolean
  ) => Promise<void>;

  overViewData: number[];
  usageCostData: any;
}> = ({
  detailUsage,
  fetchOverviewData,
  fetchUsage,
  fetchUsageCost,
  overViewData,
  usageCostData,
}) => {
  const [currentState, setCurrentState] = useState<
    "day" | "week" | "month" | "year"
  >("day");
  const [daysInMonth, setDaysInMonth] = useState(0);
  const usageLabels = {
    day: [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ],
    week: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    month: ["1"],
    year: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  };

  usageLabels.month = Array.from({ length: daysInMonth }, (_, i) => `${i + 1}`);

  const todaydate = new Date();

  const fetchDaily = async () => {
    await fetchOverviewData(
      "day",
      format(sub(todaydate, { days: 6 }), `yyyy-MM-dd`),
      format(new Date(), `yyyy-MM-dd`),
      "load"
    );
    setCurrentState("day");
  };

  useEffect(() => {
    fetchDaily();
  }, []);

  const graphLabelsUsage = {
    day: "Usage per hour",
    week: "Usage per day",
    month: "Usage per day",
    year: "Usage per month",
  };

  const graphLabelsCost = {
    day: "Cost per hour",
    week: "Cost per day",
    month: "Cost per day",
    year: "Cost per month",
  };

  const graphLabelsCompare = {
    day: "Usage, compared to other days",
    week: "Usage, compared to other weeks",
    month: "Usage, compared to other months",
    year: "Usage, compared to other years",
  };

  const datafullOverview = {
    labels: labels[currentState],
    datasets: [
      {
        label: "Usage",
        data: overViewData,
        backgroundColor: [
          overViewData[0] > 0 ? "#e91e63" : "#4caf50",
          overViewData[1] > 0 ? "#e91e63" : "#4caf50",

          overViewData[2] > 0 ? "#e91e63" : "#4caf50",

          overViewData[3] > 0 ? "#e91e63" : "#4caf50",

          overViewData[4] > 0 ? "#e91e63" : "#4caf50",

          overViewData[5] > 0 ? "#e91e63" : "#4caf50",

          "#5ba9f5",
        ],
        borderRadius: 50,
      },
    ],
  };

  const dataUsageOverview = {
    labels: usageLabels[currentState],
    datasets: [
      {
        label: "Usage",
        data: detailUsage,
        backgroundColor: detailUsage.map((item: number) =>
          item > 0 ? "#e91e63" : "#4caf50"
        ),
        borderRadius: 50,
      },
    ],
  };

  const dataUsageCost = {
    labels: usageLabels[currentState],
    datasets: [
      {
        label: "Cost",
        data: usageCostData,
        backgroundColor: usageCostData.map((item: number) =>
          item > 0 ? "#e91e63" : "#4caf50"
        ),
        borderRadius: 50,
      },
    ],
  };

  const costOptions = {
    responsive: true,
    maintainAspectRatio: false,
    borderColor: "rgba(109, 212, 0, 1)",
    backgroundColor: "rgba(76, 223, 232, 0.2)",
    tension: 0,
    pointRadius: 0,
    scales: {
      y: {
        stepSize: 5,
        beginAtZero: true,
        title: {
          display: true,
          text: "SEK",
          color: "#6D7278",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: true,
        text: graphLabelsCost[currentState],
      },
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    borderColor: "#007aff",
    backgroundColor: "#007aff",
    tension: 0,
    pointRadius: 0,
    scales: {
      y: {
        stepSize: 5,
        beginAtZero: true,
        title: {
          display: true,
          text: "Usage kWh",
          color: "#6D7278",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: true,
        text: graphLabelsUsage[currentState],
      },
    },
  };
  const optionsOverView = {
    responsive: true,
    maintainAspectRatio: false,
    borderColor: "#007aff",
    backgroundColor: "#007aff",
    tension: 0,
    pointRadius: 0,
    scales: {
      y: {
        stepSize: 5,
        beginAtZero: true,
        title: {
          display: true,
          text: "Usage kWh",
          color: "#6D7278",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: true,
        text: graphLabelsCompare[currentState],
      },
    },
  };

  useEffect(() => {
    fetchUsageCost(
      "1h",
      format(todaydate, `yyyy-MM-dd`),
      format(todaydate, `yyyy-MM-dd`),
      "load_cost",
      true
    );
    fetchUsage(
      "1h",
      format(todaydate, `yyyy-MM-dd`),
      format(todaydate, `yyyy-MM-dd`),
      "load"
    );
  }, []);

  return (
    <StatsPageRootContainer className="widgetBackScrollX">
      <GraphRoot>
        <GraphCotainer>
          <Card backgroundColor="White" flex="0">
            <Bar options={options} data={dataUsageOverview} id="statsBar" />
          </Card>
        </GraphCotainer>
        <GraphCotainer>
          <Card backgroundColor="White" flex="0">
            <Bar options={costOptions} data={dataUsageCost} id="statsBar" />
          </Card>
        </GraphCotainer>
        <GraphCotainer>
          <Card backgroundColor="White" flex="0">
            <Bar
              options={optionsOverView}
              data={datafullOverview}
              id="statsBar"
            />
          </Card>
        </GraphCotainer>
      </GraphRoot>
    </StatsPageRootContainer>
  );
};

export default Daily;
