import { DateTime } from "luxon";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-luxon";
import { Period } from "../../../../../ContextApi/StateProvider/type";

import { CostGrid } from "./../../../../../api/usage/gridCost";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend, TimeScale);

// @ts-ignore
Tooltip.positioners.stepperPositioner = function (elements, eventPosition) {
  if (!elements.length) {
    return false;
  }

  return {
    x: elements[0].element.x + 6,
    y: elements[0].element.y,
  };
};

const LineG: FC<{ usage: CostGrid[]; period: Period; timezone: string }> = ({
  usage,
  period,
  timezone,
}) => {
  const chartRef = useRef(null);
  const selectedPosRef = useRef({ x: "14:00", y: 15 });
  const [selectedPos, setSelectedPos] = useState({
    x: "14:00",
    y: 15,
  });

  const dataGrid = usage.map((item) => ({
    startTime: item.time,
    gridCost: item.grid_cost,
    gridNet: item.grid_net,
    gridProfit: item.grid_profit,
  }));

  const handleDragEnd = (annotation: any) => {
    setSelectedPos({
      x: annotation,
      y: 0,
    });
  };

  const usageData = dataGrid;

  console.log("usage", usage);

  const createData = (inputData: any[]) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const gradientBuy = ctx?.createLinearGradient(0, 0, 0, 500);
    gradientBuy?.addColorStop(1, "rgba(251, 178, 50, 0)");
    gradientBuy?.addColorStop(0.2, "rgba(233,30,100, 0.2)");
    gradientBuy?.addColorStop(0, "rgba(233,30,100,0.6)");

    const gradientSell = ctx?.createLinearGradient(0, 0, 0, 500);
    gradientSell?.addColorStop(0, "rgba(76,175,81,0.6)");
    gradientSell?.addColorStop(0.2, "rgba(76,175,81,0.2)");
    gradientSell?.addColorStop(1, "rgba(32,139, 215, 0)");

    const gradientProfit = ctx?.createLinearGradient(0, 0, 0, 500);
    gradientProfit?.addColorStop(0, "rgba(0,123,255,0.6)");
    gradientProfit?.addColorStop(0.2, "rgba(0,123,255,0.2)");
    gradientProfit?.addColorStop(1, "rgba(0,123,255,0)");

    return {
      labels: inputData.map((item: { startTime: string }) => {
        const date = DateTime.fromISO(item.startTime);
        switch (period) {
          case "week":
            return date.toFormat("yyyy-MM-dd");
          case "month":
            return date.toFormat("yyyy-MM-dd");
          case "year":
            return date.toFormat("yyyy-MM-dd");
          default:
            return date.toFormat("HH:mm");
        }
      }),

      datasets: [
        {
          label: "Bought",
          data: inputData.map((item: { gridCost: any }) => item.gridCost),
          borderColor: "#E91E6490",
          backgroundColor: gradientBuy,
          fill: true,
          tension: 0.4,
          pointBackgroundColor: "#E91E64",
          pointBorderColor: "rgba(255, 255, 255, 1)",
          pointBorderWidth: 0,
          pointRadius: 0,
          pointHoverRadius: 0,
        },
        {
          label: "Sold",
          data: inputData.map((item: { gridProfit: any }) => item.gridProfit),
          borderColor: "#4CAF5190",
          backgroundColor: gradientSell,
          fill: true,
          tension: 0.4,
          pointBackgroundColor: "#4CAF51",
          pointBorderColor: "rgba(255, 255, 255, 1)",
          pointBorderWidth: 0,
          pointRadius: 0,
          pointHoverRadius: 0,
        },
        {
          label: "Cost",
          data: inputData.map((item: { gridNet: any }) => item.gridNet),
          borderColor: "#007BFF90",
          backgroundColor: gradientProfit,
          fill: true,
          tension: 0.4,
          pointBackgroundColor: "#007BFF",
          pointBorderColor: "rgba(255, 255, 255, 1)",
          pointBorderWidth: 0,
          pointRadius: 0,
          pointHoverRadius: 0,
          hidden: true,
        },
      ],
    };
  };

  const data = createData(usageData);
  let dayFormat = "dd";
  if (period === "week") {
    dayFormat = "cccc";
  }

  let unit: string, displayFormat: string;
  if (period === "day") {
    unit = "hour";
    displayFormat = "HH:mm";
  } else if (period === "week") {
    unit = "day";
    displayFormat = "EEEE";
  } else if (period === "month") {
    unit = "day";
    displayFormat = "dd";
  } else if (period === "year") {
    unit = "month";
    displayFormat = "MMMM";
  } else {
    unit = "hour";
    displayFormat = "HH:mm";
  }

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,
      scales: {
        y: {
          ticks: {
            callback: function (label: any) {
              return label.toFixed(0) + " SEK";
            },
            stepSize: 1,
          },
          beginAtZero: true,
        },
        x: {
          type: "time" as const,
          adapters: {
            date: {
              zone: timezone,
            },
          },
          time: {
            unit: unit,

            displayFormats: {
              minute: "HH:mm",
              hour: "HH:00",
              day: displayFormat,
              week: displayFormat,
              month: displayFormat,
            },

            tooltipFormat: displayFormat,
          },
        },
      },
      annotations: {
        line1: {
          type: "line",
          mode: "vertical",
          scaleID: "x",
          value: selectedPos.x,
          borderColor: "#6D7278",
          borderWidth: 1,
          borderDash: [0, 1, 2],
          display: true,
          drawTime: "afterDraw",
        },
        point1: {
          type: "point",
          xValue: selectedPos.x,
          yValue: selectedPosRef.current.y,
          backgroundColor: "#333",
          radius: 5,
          borderWidth: 1,
          borderColor: "#fff",
          display: false,
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: "Sales and Costs Overview",
        },
        tooltip: {
          enabled: true,
          position: "stepperPositioner",
          intersect: false,
          mode: "index",
          caretPadding: 10,
          displayColors: true,
          callbacks: {
            title: (item: { raw: { purPrice: number } }[]) => {
              return `Sales and Costs Overview`;
            },
            label: (context: {
              dataIndex: any;
              dataset: any;
              chart: { data: { labels: { [x: string]: any } } };
            }) => {
              const index = context.dataIndex;
              const dataset = context.dataset;
              const value = dataset.data[index];

              if (
                context.chart.data.labels[index] !== selectedPosRef.current.x
              ) {
                handleDragEnd(context.chart.data.labels[index]);
              }

              selectedPosRef.current = {
                x: context.chart.data.labels[index],
                y: value,
              };
              return `${dataset.label}: ${value.toFixed(2)} SEK`;
            },
          },
        },
      },
      parsing: {
        xAxisKey: "time",
      },
    }),
    [selectedPos, usage, period, timezone]
  );

  return (
    <div style={{ height: "500px", width: "100%" }}>
      <Line
        data={data}
        //@ts-ignore
        options={options}
        ref={chartRef}
      />
    </div>
  );
};

export default LineG;
