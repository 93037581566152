import { FC, useMemo } from "react";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-luxon";
import { Usage } from "../../../../api/usage/ems";
import { Period } from "../../../../ContextApi/StateProvider/type";
import { DateTimeFormatFromDuration } from "../../Utils/chart";
import { barBackground, Mode } from "./shared";

ChartJS.register(
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const labels = (mode: Mode) => {
  const prefix = mode === "consumption" ? "Usage" : "Production";
  return {
    day: `${prefix} per hour`,
    week: `${prefix} per day`,
    month: `${prefix} per day`,
    year: `${prefix} per month`,
  };
};

const UsageChart: FC<{
  timezone: string;
  period: Period;
  usage: Array<Usage>;
  point: "load" | "pv" | "grid" | "battery";
  mode: Mode;
}> = ({ timezone, period, usage, point, mode }) => {
  const graphLabelsUsage = labels(mode);

  const data = useMemo(() => {
    return {
      datasets: [
        {
          label: "Usage",
          data: usage,
          backgroundColor: usage.map((item: Usage) =>
            barBackground(mode, item[point])
          ),
          borderRadius: 50,
        },
      ],
    };
  }, [usage]);

  const options = useMemo(() => {
    let dayFormat = "dd";
    if (period === "week") {
      dayFormat = "cccc";
    }

    let duration = usage.length > 0 ? usage[0].duration : "";
    let tooltipFormat = DateTimeFormatFromDuration(duration, "HH:mm");

    return {
      responsive: true,
      maintainAspectRatio: false,
      borderColor: "#007aff",
      backgroundColor: "#007aff",
      tension: 0,
      pointRadius: 0,
      scales: {
        y: {
          ticks: {
            callback: function (label: any) {
              return label.toFixed(0) + " kWh";
            },
            stepSize: 1,
          },
          beginAtZero: true,
        },
        x: {
          type: "time" as const,
          adapters: {
            date: {
              zone: timezone,
            },
          },
          time: {
            tooltipFormat: tooltipFormat,
            displayFormats: {
              minute: "HH:mm",
              hour: "HH:00",
              day: dayFormat,
              month: "LLL",
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
          position: "top" as const,
        },
        title: {
          display: true,
          text: graphLabelsUsage[period],
        },
        tooltip: {
          titleAlign: "center" as const,
          bodyAlign: "center" as const,
          caretPadding: 10,
          displayColors: false,
          callbacks: {
            label: (item: any) => {
              return `${item?.parsed?.y?.toFixed(2)} kWh`;
            },
          },
        },
      },
      parsing: {
        xAxisKey: "time",
        yAxisKey: point,
      },
    };
  }, [point, period, usage]);

  return <Bar options={options} data={data} id="statsBar" />;
};

export default UsageChart;
