import React, { FC } from "react";
import styled from "styled-components";
import { CardProps, CardBackground } from "./types";

const CardContainer = styled.div<CardProps>`
  background: ${(props) =>
    props.backgroundColor ? CardBackground[props.backgroundColor] : "#ffffff"};
  border-radius: 8px;
  box-shadow: 0 0 3px 0px #0000002b;
  box-sizing: border-box;
  display: flex;
  flex: ${(props) => (props.flex ? props.flex : "1")};
  max-height: unset;
  max-width: ${(props) => (props.maxWidth ? "100%" : "600px")};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "unset")};
  overflow: ${(props) => (props.noPadding ? "hidden" : "unset")};
  padding: ${(props) => (props.noPadding ? 0 : "20px")};
  width: 100%;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "column"};
`;

const Card: FC<CardProps> = ({ backgroundColor, flex, children, ...rest }) => {
  return (
    <CardContainer backgroundColor={backgroundColor} flex={flex} {...rest}>
      {children}
    </CardContainer>
  );
};

export default Card;
