import { FC, useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Container, DropDown, Hr, Typography } from "../../Atoms";
import BackHeader from "../../Molecules/BackHeader/BackHeader";
import { Facility } from "../../../../api/facility";
import { SpotPriceChart } from "./SpotPriceChart";
import { Period, TimePeriodSelector } from "./TimePeriodSelector";
import Breakdown from "../../Molecules/Breakdown/Breakdown";
import { energyIdentifier } from "./../../Utils/EnergyIdentifier/EnergyIdentifier";

import {
  EnergyPriceDay,
  EnergyPriceSlot,
  fetchEnergyPrice,
} from "../../../../api/energyprice";

import { ValueGridCard } from "../../Molecules/ValueGrid/ValueGridCard";
const StatsPageRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px 14px 27px 14px;
  box-sizing: border-box;
  gap: 14px;
  height: 100vh;
  background-color: #fafafa;
  overflow: scroll;
`;
const PricePage: FC<{
  facility: Facility | null;
  energyPriceDay: EnergyPriceDay;
}> = ({ facility, energyPriceDay }) => {
  const [spotPriceData, setSpotPriceData] = useState<EnergyPriceDay>();
  const [selPriceSlot, setSelectedSlot] = useState<EnergyPriceSlot | null>(
    null
  );
  const [selPeriod, setSelectedPeriod] = useState<Period>(Period.Day);
  const [error, setError] = useState<string | null>(null);
  const [breakDownState, setBreakDownState] = useState<
    "pur_breakdown" | "sell_breakdown"
  >("pur_breakdown");
  const [selectedFilter, setSelectedFilter] = useState<
    "electricityTradingCompany" | "energyPrice" | "networkProvider" | "all"
  >("all");

  const filterData = {
    all: [
      "marketPrice",
      "surchargeMarketPrice",
      "energyTax",
      "purBalanceFee",
      "transmissionFee",
      "vatSupplier",
      "vatGrid",
      "60öringen",
      "purOriginGuarantee",
      "sell60oringen",
      "sellEnergy",
      "sellNetBenefit",
      "energySaleMarkup",
      "purCertificate",
      "purEnergyTax",
      "defaultFallBack",
    ],
    electricityTradingCompany: [
      "purBalanceFee",
      "purCertificate",
      "marketPrice",
      "surchargeMarketPrice",
      "purOriginGuarantee",
      "energySaleMarkup",
      "vatSupplier",
      "sellEnergy",
    ],
    energyPrice: ["sellEnergy", "marketPrice"],
    networkProvider: [
      "transmissionFee",
      "energyTax",
      "vatGrid",
      "sellNetBenefit",
    ],
  };

  const { getAccessTokenSilently } = useAuth0();

  const fetchSpotPriceData = async (period: Period) => {
    if (facility === null) {
      return;
    }

    setError(null);

    let date = facility?.localTime();
    switch (period) {
      case Period.DayAhead: {
        date = facility?.localTime().plus({ days: 1 });
      }
    }

    try {
      const spotPriceDataFetch = await fetchEnergyPrice(
        facility,
        date,
        getAccessTokenSilently()
      );

      const filteredSpotPricedata = { ...spotPriceDataFetch };

      filteredSpotPricedata.slots.map((slot) => {
        let newPrice = 0;
        for (const key in slot[breakDownState]) {
          if (Object.hasOwnProperty.call(slot[breakDownState], key)) {
            if (
              filterData[selectedFilter].includes(
                //@ts-ignore
                energyIdentifier[key as keyof typeof slot.breakDownState].helper
              ) && //@ts-ignore
              slot[breakDownState][key as keyof typeof slot.breakDownState]
            ) {
              newPrice += Number(
                //@ts-ignore
                slot[breakDownState][key as keyof typeof slot.breakDownState]
              );
            }
          }
        }

        slot.purPrice = newPrice;
      });

      setSpotPriceData(filteredSpotPricedata);
    } catch (error) {
      setSpotPriceData(undefined);
      setError("no_available");
    }
  };

  useEffect(() => {
    fetchSpotPriceData(selPeriod);
  }, [selPeriod, selectedFilter, breakDownState]);

  useEffect(() => {
    if (spotPriceData === undefined || facility === null) {
      return;
    }

    // Select first hour if tomorrow is selected.
    if (selPeriod === Period.DayAhead) {
      setSelectedSlot(spotPriceData.slots[0]);
      return;
    }

    // Set selected time to current when loading page.
    const current = spotPriceData.slots.find(
      (slot) => slot.startTime.get("hour") === facility.localTime().get("hour")
    );
    if (current !== undefined) {
      setSelectedSlot(current);
    }
  }, [spotPriceData, facility]);

  const avgPrice = useMemo((): string => {
    if (spotPriceData === undefined) {
      return "-";
    }
    const avg =
      spotPriceData.slots.reduce((total, next) => total + next.purPrice, 0) /
      spotPriceData.slots.length;
    return avg.toFixed(2);
  }, [spotPriceData]);

  const periodDesc = useMemo((): string => {
    switch (selPeriod) {
      case Period.DayAhead:
        return "Tomorrow";
      case Period.Day:
        return "Today";
    }
    return "-";
  }, [selPeriod]);

  const getSegmentsData = (
    selPriceSlot: EnergyPriceSlot | null | undefined,
    breakDownState: "pur_breakdown" | "sell_breakdown",
    selectedFilter:
      | "electricityTradingCompany"
      | "energyPrice"
      | "networkProvider"
      | "all"
  ) => {
    const segmentsData: {
      title: string;
      percent: number;
      amount: number;
      currency: string;
      color: string;
      helper: string;
    }[] = [];

    if (!selPriceSlot) return segmentsData;

    const selPriceSlotState = selPriceSlot[breakDownState];

    if (!selPriceSlotState) return segmentsData;

    Object.entries(selPriceSlotState).forEach(([key, energyValue]) => {
      const energyKey = key as keyof typeof selPriceSlotState;

      if (
        energyValue &&
        filterData[selectedFilter].includes(energyIdentifier[energyKey]?.helper)
      ) {
        const percent =
          (Number(energyValue) / Number(selPriceSlot.purPrice)) * 100;

        segmentsData.push({
          title: energyIdentifier[energyKey].title
            ? energyIdentifier[energyKey].title
            : energyKey,
          percent,
          amount: Number(energyValue),
          currency: "SEK",
          color: energyIdentifier[energyKey].color
            ? energyIdentifier[energyKey].color
            : "#ff0000",
          helper: energyIdentifier[energyKey].helper
            ? energyIdentifier[energyKey].helper
            : energyKey,
        });
      }
    });

    return segmentsData;
  };

  const segmentsData = getSegmentsData(
    selPriceSlot,
    breakDownState,
    selectedFilter
  );

  return (
    <Container
      flexDirection="column"
      padding="14px 14px 27px 14px"
      gap={16}
      height="100vh"
      bgColor="#fafafa"
      scroll="scroll"
    >
      <BackHeader title="Electricity price" goBack="/" />

      <TimePeriodSelector
        defaultPeriod={selPeriod}
        periodCallback={setSelectedPeriod}
      />

      {error === "no_available" && (
        <Card backgroundColor="White" flex="0">
          <Container flexDirection="column" flex={1} alignItems="center">
            <img width="64px" src="time-left.png" alt="Wait" />
            <Typography marginTop={16} color="black" fontSize="H5">
              Prices not available
            </Typography>
            <Typography color="#A4A8AA" fontSize="Small" marginTop={"None"}>
              Tomorrow's prices will be available tomorrow about 13.00.
            </Typography>
          </Container>
        </Card>
      )}
      {error === null && (
        <>
          <ValueGridCard title="Select a filter:">
            <DropDown
              onChange={(e) => {
                const target = e.target as HTMLSelectElement;
                setSelectedFilter(
                  target.value as
                    | "electricityTradingCompany"
                    | "energyPrice"
                    | "networkProvider"
                    | "all"
                );
              }}
              options={[
                { value: "all", label: "All" },
                {
                  value: "electricityTradingCompany",
                  label: "Electricity Trading Company",
                },
                { value: "energyPrice", label: "Energy Price" },
                { value: "networkProvider", label: "Network Provider" },
              ]}
            />

            <Container
              flexDirection="row"
              flex={1}
              justifyContent="space-between"
              padding="0"
            >
              <Typography
                color={
                  breakDownState === "pur_breakdown" ? "#5AA9F5" : "#000000"
                }
                marginBottom="None"
                marginTop="None"
                marginLeft="None"
                marginRight="None"
                fontSize="H6"
                onClick={() => setBreakDownState("pur_breakdown")}
              >
                Purchase
              </Typography>

              <Typography
                color={
                  breakDownState === "sell_breakdown" ? "#5AA9F5" : "#000000"
                }
                marginBottom="None"
                marginTop="None"
                marginLeft="None"
                marginRight="None"
                fontSize="H6"
                onClick={() => setBreakDownState("sell_breakdown")}
              >
                Sell
              </Typography>
            </Container>
          </ValueGridCard>

          <Card backgroundColor="White" flex="1">
            <Container flexDirection="column" flex={1} alignItems="center">
              <Typography color="black" fontSize="H3">
                <label tabIndex={-1}>
                  {selPriceSlot?.purPrice?.toFixed(2)} SEK
                </label>
              </Typography>
              <Typography color="#A4A8AA" fontSize="Small" marginTop={"None"}>
                {selPriceSlot !== null && (
                  <label tabIndex={-1}>
                    {selPriceSlot.startTime.toFormat("HH:mm")} -{" "}
                    {selPriceSlot.endTime.toFormat("HH:mm")}
                  </label>
                )}
              </Typography>

              {spotPriceData !== undefined && (
                <Container flexDirection="column" flex={1}>
                  <SpotPriceChart
                    timezone={facility?.timezone}
                    enableAnnotation={true}
                    enableTooltip={true}
                    chartHeight="300px"
                    setSelected={setSelectedSlot}
                    selected={selPriceSlot}
                    data={spotPriceData}
                    priceLevel={
                      energyPriceDay.breakdown.breakdown[breakDownState]
                    }
                    id="spotPriceChart"
                  ></SpotPriceChart>
                </Container>
              )}
            </Container>
          </Card>
          <Card backgroundColor="White" flex="1">
            <Container flexDirection="column" flex={1}>
              {selPriceSlot ? (
                <div style={{ width: "100%" }}>
                  <Breakdown
                    segments={segmentsData}
                    selPriceSlot={selPriceSlot}
                    splitBy={2}
                    title="Price breakdown"
                  />
                </div>
              ) : null}
            </Container>
          </Card>

          <Card backgroundColor="White" flex="0">
            <Container flexDirection="column" flex={1}>
              <Typography
                color="black"
                fontSize="H6"
                marginBottom="None"
                marginTop="None"
                marginLeft="None"
              >
                Avg. price
              </Typography>
              <Hr color="D8D8D8" />
              <Container flexDirection="row" flex={1}>
                <Container flexDirection="column" flex={1}>
                  <Container
                    flexDirection="row"
                    flex={1}
                    justifyContent="flex-start"
                    alignItems="flex-end"
                  >
                    <Typography
                      color="black"
                      fontSize="H4"
                      marginBottom="None"
                      marginTop="None"
                      marginLeft="None"
                    >
                      {avgPrice}
                    </Typography>
                    <Typography
                      color="black"
                      fontSize="Small"
                      marginBottom="None"
                      marginTop="None"
                      marginLeft="None"
                      noTransform
                    >
                      SEK / kWh
                    </Typography>
                  </Container>
                  <Typography
                    color="#A4A8AA"
                    fontSize="Small"
                    marginTop="None"
                    marginLeft="None"
                  >
                    {periodDesc}
                  </Typography>
                </Container>
              </Container>
            </Container>
          </Card>
        </>
      )}
    </Container>
  );
};

export default PricePage;
