import React, { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import "chartjs-adapter-luxon";
import { Container, Hr, Icon, Typography } from "./../../Atoms";
import ColorLabelInfoPrice from "./../../Molecules/ColorLabelInfoPrice/ColorLabelInfoPrice";

import {
  EnergyPriceDay,
  EnergyPriceSlot,
  fetchEnergyPrice,
} from "../../../../api/energyprice";
import { DateTime } from "luxon";
import { Facility } from "../../../../api/facility";

import infoImg from "./../../Assets/Icon/infoButton.svg";
import { DateSelection } from "../DateScroll/DateSelector";

export interface Order {
  [key: string]: number;
}

const Breakdown = ({
  segments,
  selPriceSlot,
  splitBy,
  selectedPeriod,
  showPeriod,
  breakDownState,
  title,
  facility,
}: {
  segments: {
    title: string;
    percent: number;
    amount: number;
    currency: string;
    color: string;
    helper: string;
  }[];
  selPriceSlot?: EnergyPriceSlot;
  splitBy?: number;
  selectedPeriod?: DateSelection;
  showPeriod?: boolean;
  breakDownState?: string;
  title: string;
  facility?: Facility | null;
}) => {
  const order: Order = {
    marketPrice: 1,
    surchargeMarketPrice: 2,
    vatSupplier: 3,
    transmissionFee: 4,
    energyTax: 5,
    vatGrid: 6,
  };

  const sortedSegmentsData = segments.sort(
    (a: { helper: string | number }, b: { helper: string | number }) => {
      const aHelperValue = order[a.helper];
      const bHelperValue = order[b.helper];
      return aHelperValue - bHelperValue;
    }
  );

  const centerText = {
    id: "centerText",
    afterDatasetsDraw(
      chart: { data?: any; ctx?: any; chartArea?: any },
      args: any,
      options: any
    ) {
      const {
        ctx,
        chartArea: { top, left, width, height },
      } = chart;
      ctx.save();
      ctx.font = "bolder 20px Arial";
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      ctx.fillText(
        `${Number(chart.data.datasets[0].label.split(";")[0]).toLocaleString(
          undefined,
          {
            maximumFractionDigits:
              Number(chart.data.datasets[0].label.split(";")[0]) < 1000 ? 2 : 0,
          }
        )} SEK`,
        width / 2 + left,
        height / 2 + top - 6
      );

      ctx.font = "bolder 12px Arial";
      ctx.fillStyle = "#A4A8AA";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      ctx.fillText(
        chart.data.datasets[0].label.split(";")[1],
        width / 2 + left,
        height / 2 + top + 12
      );
    },
  };

  const doughnutLabelsLine = {
    id: "doughnutLabelsLine",
    afterDraw(chart: any, arg: any, options: any) {
      const {
        ctx,
        chartArea: { width, height },
      } = chart;

      chart.data.datasets.forEach((dataset: any, i: any) => {
        chart.getDatasetMeta(i).data.forEach((datapoint: any, index: any) => {
          if (dataset.data[index] > 0) {
            const { x, y } = datapoint.tooltipPosition();
            const segment = chart.getDatasetMeta(i).data[index];
            const angle = (segment.startAngle + segment.endAngle) / 2;
            const radius = segment.outerRadius * 0.4;
            const x2 = x + Math.cos(angle) * radius;
            const y2 = y + Math.sin(angle) * radius;

            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.lineTo(x2, y2);
            ctx.strokeStyle = "black";
            ctx.stroke();

            const dx = x2 - x;
            const dy = y2 - y;
            const length = Math.sqrt(dx * dx + dy * dy);
            const directionX = dx / length;
            const directionY = dy / length;

            const label = chart.data.labels[index];
            const value =
              Number(chart.data.datasets[0].data[index]).toFixed(2) + "%";
            let spacing = 6;
            if (angle >= 4.4 && angle <= 4.9) {
              spacing = 26;
            }
            const labelX = x2 + directionX * spacing;
            const labelY = y2 + directionY * spacing;

            if (angle > Math.PI) {
              ctx.font = "bolder  12px Arial";
              if (
                (angle >= 4.4 && angle <= 4.9) ||
                (angle >= 1.3 && angle <= 1.9)
              ) {
                ctx.textAlign = "center";
              } else {
                ctx.textAlign = directionX < 0 ? "right" : "left";
              }
              ctx.fillStyle = "black";
              ctx.fillText(label, labelX, labelY);
              ctx.fillStyle = "grey";
              ctx.fillText(value, labelX, labelY + 15);
            } else {
              ctx.font = "bolder 12px Arial";
              if (
                (angle >= 4.4 && angle <= 4.9) ||
                (angle >= 1.3 && angle <= 1.9)
              ) {
                ctx.textAlign = "center";
              } else {
                ctx.textAlign = directionX < 0 ? "right" : "left";
              }
              ctx.fillStyle = "black";
              ctx.fillText(label, labelX, labelY);
              ctx.fillStyle = "grey";
              ctx.fillText(value, labelX, labelY + 15);
            }
          }
        });
      });
    },
  };

  let sStartTime: any = "";
  let sEndTime: any = "";

  switch (selectedPeriod?.period) {
    case "day":
      sStartTime = selPriceSlot?.startTime
        .startOf("day")
        .toFormat("yyyy-MM-dd");
      sEndTime = selPriceSlot?.endTime.endOf("day").toFormat("yyyy-MM-dd");
      break;
    case "week":
      sStartTime = selPriceSlot?.startTime
        .startOf("week")
        .toFormat("yyyy-MM-dd");
      sEndTime = selPriceSlot?.endTime.endOf("week").toFormat("yyyy-MM-dd");
      break;
    case "month":
      sStartTime = selPriceSlot?.startTime.startOf("month").toFormat("LLLL");
      sEndTime = selPriceSlot?.endTime.endOf("month").toFormat("LLLL");

      break;
    case "year":
      sStartTime = selPriceSlot?.startTime.startOf("year").toFormat("yyyy");
      sEndTime = selPriceSlot?.endTime.endOf("year").toFormat("yyyy");

      break;

    default:
      break;
  }

  const data = useMemo(() => {
    const startEndTimeFormated = `${selPriceSlot?.startTime.toFormat(
      "HH:mm"
    )} - ${selPriceSlot?.endTime.toFormat("HH:mm")}`;
    let selectedPeriodFormated: string | DateTime | undefined = "";

    // if (selectedPeriod === undefined) {
    //   return;
    // }

    let date = DateTime.now();
    const now = DateTime.now();
    if (selectedPeriod) {
      date = DateTime.fromISO(selectedPeriod.start.toISO());
    }

    switch (selectedPeriod?.period) {
      case "day":
        selPriceSlot?.startTime.toFormat("yyyy");

        const nowDay = now.startOf("day");

        if (date.diff(nowDay, "days").days === 0) {
          selectedPeriodFormated = "Today";
        } else if (date.diff(nowDay, "days").days === -1) {
          selectedPeriodFormated = "Yesterday";
        } else {
          selectedPeriodFormated = date.toFormat("cccc");
        }

        // selectedPeriodFormated = "Today";
        break;
      case "week":
        const nowWeekNr = now.weekNumber;

        if (date.weekNumber === nowWeekNr) {
          selectedPeriodFormated = "This week";
        } else if (nowWeekNr - date.weekNumber === 1) {
          selectedPeriodFormated = "Last week";
        } else {
          selectedPeriodFormated = `Week ${date.weekNumber}`;
        }
        break;
      case "month":
        const nowMonth = now.month;

        if (date.month === nowMonth) {
          selectedPeriodFormated = "This month";
        } else if (nowMonth - date.month === 1) {
          selectedPeriodFormated = "Last month";
        } else {
          selectedPeriodFormated = date.toFormat("LLLL");
        }

        break;

      case "year":
        const nowYear = now.year;
        if (date.year === nowYear) {
          selectedPeriodFormated = "This year";
        } else if (nowYear - date.year === 1) {
          selectedPeriodFormated = "Last year";
        } else {
          selectedPeriodFormated = date.toFormat("yyyy");
        }

        // selectedPeriodFormated = facility?.localTime().toFormat("yyyy");
        break;

      default:
        break;
    }

    return {
      labels: sortedSegmentsData.map(
        (item: { amount: number; currency: any }) =>
          `${item.amount.toFixed(2)} ${item.currency}`
      ),
      datasets: [
        {
          label: `${sortedSegmentsData.reduce(
            (accumulator: any, currentValue: { amount: any }) => {
              return accumulator + currentValue.amount;
            },
            0
          )};${showPeriod ? selectedPeriodFormated : startEndTimeFormated}`,
          data: sortedSegmentsData.map(
            (item: { percent: any }) => item.percent
          ),
          backgroundColor: sortedSegmentsData.map((item) => item.color),
          borderColor: "#ffffff",
          borderWidth: 1,
          cutout: "70%",
        },
      ],
    };
  }, [sortedSegmentsData, selPriceSlot, selectedPeriod]);

  return (
    <Container flexDirection="column" flex={1}>
      <Container flexDirection="column" flex={1}>
        <Container flexDirection="column">
          <Typography
            color="black"
            fontSize="H6"
            marginBottom="None"
            marginTop="None"
            marginLeft="None"
          >
            {title}
          </Typography>
          <Hr color="D8D8D8" />
        </Container>
        {sortedSegmentsData.length ? (
          <div style={{ width: "100%" }}>
            <Doughnut
              height="300px"
              options={{
                animation: {
                  duration: 0,
                },
                layout: {
                  padding: 20,
                },
                maintainAspectRatio: true,
                cutout: 10,
                radius: 80,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
              plugins={[doughnutLabelsLine, centerText]}
              data={data}
            />
            {sortedSegmentsData.map(
              ({ title, percent, amount, currency, color, helper }, index) => {
                if (splitBy === index) {
                  return (
                    <ColorLabelInfoPrice
                      key={index}
                      color={color}
                      currency={currency}
                      helper
                      helperItem={helper}
                      title={title}
                      value={amount}
                      paddingBottom={"24px"}
                    />
                  );
                }
                return (
                  <ColorLabelInfoPrice
                    key={index}
                    color={color}
                    currency={currency}
                    helper
                    helperItem={helper}
                    title={title}
                    value={amount}
                  />
                );
              }
            )}
            <Hr color="D8D8D8" />
            <ColorLabelInfoPrice
              currency="Kr"
              title="Total"
              value={sortedSegmentsData.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.amount;
              }, 0)}
            />
          </div>
        ) : (
          <Container
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding="16px"
          >
            <Icon src={infoImg} width="32px" height="32px" />
            <Typography
              color="grey"
              fontSize="H6"
              marginBottom="None"
              marginTop={16}
              marginLeft="None"
            >
              You have not {breakDownState} anything
            </Typography>
          </Container>
        )}
      </Container>
    </Container>
  );
};

export default Breakdown;
