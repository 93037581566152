import React, { FC, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { useAuth0 } from "@auth0/auth0-react";

import { Container, DropDown, Typography } from "../../Atoms";

import { Facility } from "../../../../api/facility";
import { StateContext } from "../../../../ContextApi/StateProvider/StateProvider";
import BackHeader from "../../Molecules/BackHeader/BackHeader";
import Breakdown from "../../Molecules/Breakdown/Breakdown";

import { ValueGridCard } from "../../../Atomic/Molecules/ValueGrid/ValueGridCard";
import { costBreakdown } from "../../../../api/usage/costBreakdown";
import { costBreakdownIdentifier } from "../../Utils/EnergyIdentifier/EnergyIdentifier";
import { DateTime } from "luxon";
import DateSelector, {
  DateSelection,
} from "../../Molecules/DateScroll/DateSelector";
const StatsPageRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  gap: 14px;
  height: 100%;
`;

const BreakdownPage: FC<{
  facility: Facility;
}> = ({ facility }) => {
  const [load, setLoad] = useState(0);
  const [dateSelection, setDateSelection] = useState<
    DateSelection | undefined
  >();
  const [breakDownState, setBreakDownState] = useState<"purchased" | "sold">(
    "purchased"
  );
  const [segments, setSegments] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState<
    "electricityTradingCompany" | "energyPrice" | "networkProvider" | "all"
  >("all");

  const filterData = {
    all: [
      "purSurchargeMarketPrice",
      "purEnergy",
      "purEnergyTax",
      "purCertificate",
      "purOriginGuarantee",
      "purBalanceFee",
      "purTransferFee",
      "purVatSupplier",
      "purVatGrid",
      "sell60oringen",
      "sellEnergy",
      "sellNetBenefit",
    ],
    electricityTradingCompany: ["purVatGrid", "sellEnergy", "purBalanceFee"],
    energyPrice: ["purEnergy", "sell60oringen"],
    networkProvider: [
      "purTransferFee",
      "purVatSupplier",
      "purBalanceFee",
      "purVatGrid",
      "sellNetBenefit",
    ],
  };

  let startTime = DateTime.now();
  let endTime = DateTime.now();

  const context = useContext(StateContext);

  const { getAccessTokenSilently } = useAuth0();

  const fetchAuthenticated = async (url: string): Promise<Response> => {
    const accessToken = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return fetch(url, {
      headers: headers,
    });
  };

  const fetchUsageTotal = async (
    duration: string,
    start: string,
    end: string
  ) => {
    const facilitiesAlldata = await fetchAuthenticated(
      `https://gate.thiink.io/hc/v1/facility/${facility?.id}/usage?` +
        new URLSearchParams({
          aggregate_duration: duration,
          start: `${start}T00:00:00+01:00`,
          stop: `${end}T23:59:59+01:00`,
        })
    );

    const convData = await facilitiesAlldata.json();

    const loadData = [];

    for (const [key, value] of Object.entries(convData)) {
      //@ts-ignore
      loadData.push(value?.load / 60 / 1000);
    }

    const loadTotal = loadData
      .reduce((partialSum: number, a: number) => partialSum + a, 0)
      ?.toFixed(2);

    setLoad(parseFloat(loadTotal));
  };

  useEffect(() => {
    if (dateSelection === undefined) {
      return;
    }

    if (facility) {
      switch (context.selectedPeriod.value) {
        case "day":
          fetchData("1d", dateSelection?.start, dateSelection?.stop);
          break;

        case "week":
          fetchData("1d", dateSelection?.start, dateSelection?.stop);
          break;

        case "month":
          fetchData("1mo", dateSelection?.start, dateSelection?.stop);
          break;

        case "year":
          fetchData("1y", dateSelection?.start, dateSelection?.stop);
          break;
        default:
          break;
      }
    }
  }, [
    facility,
    dateSelection,
    context.selectedPeriod.value,
    selectedFilter,
    breakDownState,
  ]);

  const fetchData = async (
    duration: string,
    startDate: DateTime,
    endDate: DateTime
  ) => {
    if (facility === null) {
      return;
    }

    const costBDData = await costBreakdown(
      facility,
      startDate,
      endDate,
      duration,
      getAccessTokenSilently()
    );

    startTime = startDate;
    endTime = endDate;

    const segmentsData: any[] = [];

    //@ts-ignore
    costBDData?.breakdown?.map((item) => {
      if (item) {
        let totalAmount = 0;

        for (const key in item[breakDownState].breakdown) {
          totalAmount += item[breakDownState].breakdown[key];
        }

        for (const key in item[breakDownState].breakdown) {
          if (Object.hasOwnProperty.call(item[breakDownState].breakdown, key)) {
            const energyKey = key as string;

            const energyValue = item[breakDownState].breakdown[energyKey];

            const helperIdentifier = costBreakdownIdentifier[energyKey]?.helper
              ? costBreakdownIdentifier[energyKey].helper
              : costBreakdownIdentifier["defaultFallBack"].helper;

            if (
              energyValue &&
              filterData[selectedFilter].includes(helperIdentifier)
            ) {
              segmentsData.push({
                title: costBreakdownIdentifier[energyKey].title,
                percent: (Number(energyValue) / Number(totalAmount)) * 100,
                amount: Number(item[breakDownState].breakdown[energyKey]),
                currency: "SEK",
                color: costBreakdownIdentifier[energyKey].color,
                helper: helperIdentifier,
              });
            }
          }
        }
      }
    });

    //@ts-ignore
    setSegments([...segmentsData]);
  };

  return (
    <Container
      flexDirection="column"
      padding="14px 14px 27px 14px"
      gap={16}
      height="100vh"
      bgColor="#fafafa"
      scroll="scroll"
    >
      <BackHeader title="Breakdown" goBack="/gridusage" />
      <DateSelector
        timezone={facility.timezone}
        stateSetter={setDateSelection}
        sharedState={true}
      />
      <ValueGridCard title="Select a filter:">
        <DropDown
          onChange={(e) => {
            const target = e.target as HTMLSelectElement;
            setSelectedFilter(
              target.value as
                | "electricityTradingCompany"
                | "energyPrice"
                | "networkProvider"
                | "all"
            );
          }}
          options={[
            { value: "all", label: "All" },
            {
              value: "electricityTradingCompany",
              label: "Electricity Trading Company",
            },
            { value: "energyPrice", label: "Energy Price" },
            { value: "networkProvider", label: "Network Provider" },
          ]}
        />

        <Container
          flexDirection="row"
          flex={1}
          justifyContent="space-between"
          padding="0"
        >
          <Typography
            color={breakDownState === "purchased" ? "#5AA9F5" : "#000000"}
            marginBottom="None"
            marginTop="None"
            marginLeft="None"
            marginRight="None"
            fontSize="H6"
            onClick={() => setBreakDownState("purchased")}
          >
            Purchased
          </Typography>
          <Typography
            color={breakDownState === "sold" ? "#5AA9F5" : "#000000"}
            marginBottom="None"
            marginTop="None"
            marginLeft="None"
            marginRight="None"
            fontSize="H6"
            onClick={() => setBreakDownState("sold")}
          >
            Sold
          </Typography>
        </Container>
      </ValueGridCard>
      <ValueGridCard title="">
        <Container flexDirection="column" flex={1}>
          {segments ? (
            <Breakdown
              segments={segments}
              //@ts-ignore
              selPriceSlot={{ startTime: startTime, endTime: endTime }}
              showPeriod
              splitBy={2}
              facility={facility}
              selectedPeriod={dateSelection}
              breakDownState={breakDownState}
              title="Cost Breakdown"
            />
          ) : null}
        </Container>
      </ValueGridCard>
    </Container>
  );
};
export default BreakdownPage;
