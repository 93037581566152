import React, { FC } from "react";
import styled from "styled-components";
import Typography from "../Typography/Typography";

import type { InfoValueProps } from "./types";

const InfoValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const InfoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InfoValue: FC<InfoValueProps> = ({
  icon,
  iconWidth,
  iconHeight,
  infoData,
  infoDataPrefix,
  noTransform,
  ...rest
}) => {
  return (
    <InfoValueContainer {...rest}>
      <InfoDataContainer>
        <Typography
          marginBottom="None"
          marginTop="None"
          marginLeft="None"
          marginRight="None"
          color="#ffffff"
          fontSize="H4"
        >
          {infoData}
        </Typography>
        <Typography
          marginBottom="None"
          marginTop="None"
          marginLeft="None"
          marginRight="None"
          color="#ffffff"
          fontSize="Micro"
          noTransform
        >
          {infoDataPrefix}
        </Typography>
      </InfoDataContainer>
    </InfoValueContainer>
  );
};

export default InfoValue;
