import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

import wipImg from "./../../Assets/Icon/wip/wip.svg";
import { Facility } from "../../../../api/facility";
import { DropDown } from "../../Atoms";

const ProfileRootContainer = styled.div`
  align-items: center;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
`;

const InfoContainer = styled.div`
  display: flex;
  background-color: #000000;
  flex-direction: column;
  width: 100%;
  padding: 16px 0px;
  margin-bottom: 16px;
`;

const ProfileInfoRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  h3,
  h5 {
    margin: 0;
    color: #ffffff;
  }
`;

const ProfleAvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #000000;
`;
const ProfleAvatar = styled.img`
  border-radius: 50%;
  margin-right: 16px;
  height: 128px;
`;

const SignOutContainer = styled.div`
  display: flex;
  button {
    background-color: #000000;
    color: #ffffff;
    border: 0;
    display: inline-flex;
    font-size: 16px;
    border-radius: 8px;
    padding: 8px 32px;
  }
`;

const FacilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CurrentFacilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  border-radius: 0 0 8px 8px;
  h4 {
    text-align: center;
  }
`;

const CurrentFacilityLabel = styled.div`
  background-color: #9e9e9e;
  color: #ffffff;
  display: flex;
  padding: 0px 16px;
  border-radius: 8px 8px 0 0;
`;

const CurrentFacilityId = styled.div`
  display: flex;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

const RootDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
`;

const SelectFacility = styled.select`
  padding: 8px;
  margin-bottom: 16px;
  border: none;
  border-right: 16px solid transparent;
  background-color: #000000;
  color: #ffffff;
  font-size: 16px;
`;

const UseButton = styled.button`
  background-color: #000000;
  color: #ffffff;
  border: 0;
  font-size: 16px;
  border-radius: 8px;
  padding: 8px 32px;
`;

const ProfilePage: FC<{
  facility: Facility | null;
  facilities: Facility[];
  handleChooseFacility: (value: string) => void;
}> = ({ handleChooseFacility, facility, facilities }) => {
  const { user, isAuthenticated, logout } = useAuth0();

  return (
    <ProfileRootContainer>
      {/* <img src={wipImg} alt="wip" /> */}

      {isAuthenticated && (
        <InfoContainer>
          <ProfleAvatarContainer>
            <ProfleAvatar
              style={{ verticalAlign: "sub" }}
              src={user?.picture || ""}
              alt="Avatar"
              referrerPolicy="no-referrer"
            />
            <ProfileInfoRootContainer>
              <ProfileInfoContainer>
                <h3>{user?.name}</h3>
              </ProfileInfoContainer>
              <ProfileInfoContainer>
                <h5>{user?.email}</h5>
              </ProfileInfoContainer>
            </ProfileInfoRootContainer>
          </ProfleAvatarContainer>
        </InfoContainer>
      )}

      <RootDataContainer>
        <FacilityContainer>
          <CurrentFacilityContainer>
            <CurrentFacilityLabel>
              <h3>Current facility ID </h3>
            </CurrentFacilityLabel>
            <CurrentFacilityId>
              <h4>{facility?.id}</h4>
            </CurrentFacilityId>
          </CurrentFacilityContainer>
          <h3>Choose a facility</h3>
          <SelectFacility
            name="facilities"
            id="facilitiesId"
            onChange={(e) => handleChooseFacility(e.target.value)}
            value={facility?.id}
          >
            <option key="null" value="">
              No facility selected
            </option>
            {facilities?.map((item: any) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </SelectFacility>
        </FacilityContainer>
        {isAuthenticated && (
          <SignOutContainer>
            <button
              onClick={() =>
                logout({
                  returnTo: window.location.origin,
                })
              }
            >
              Logout
            </button>
          </SignOutContainer>
        )}
      </RootDataContainer>
    </ProfileRootContainer>
  );
};

export default ProfilePage;
