import React, { FC, useEffect, useState } from "react";
import { format } from "date-fns";
import { Container, Typography } from "./../../Atoms/";

interface ClockProps {
  time?: boolean;
}

const Clock: FC<ClockProps> = ({ time }) => {
  const [clockTime, setClockTime] = useState<string>("00:00");
  const [versionClick, setVersionClick] = useState<number>(0);

  const showVersion = () => {
    if (versionClick > 2) {
      alert(`Version: ${process.env.REACT_APP_VERSION}`);
      setVersionClick(0);
    }
    setVersionClick((oldState) => ++oldState);
  };

  useEffect(() => {
    const updateTime = setInterval(() => {
      setClockTime(format(Date.now(), `HH:mm`));
    }, 1000);
    return () => {
      clearInterval(updateTime);
    };
  }, []);
  if (time) {
    return (
      <Container
        onClick={() => showVersion()}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography
          as="h4"
          fontSize="H4"
          color="#000000"
          fontWeight={700}
          marginTop="None"
          marginRight="None"
          marginBottom="None"
          marginLeft="None"
        >
          {format(Date.now(), `EEEE, MMM yyy`)}
        </Typography>
        <Typography
          as="h1"
          fontSize="H1"
          color="#000000"
          fontWeight={700}
          marginTop="None"
          marginRight="None"
          marginBottom="None"
          marginLeft="None"
        >
          {format(Date.now(), `HH:mm`)}
        </Typography>
      </Container>
    );
  }

  return (
    <Container onClick={() => showVersion()} justifyContent="flex-end">
      {format(Date.now(), `EEEE, MMM yyy`)}
    </Container>
  );
};

export default Clock;
