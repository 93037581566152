import { Facility } from "./facility";
import { GATE_ADDRESS } from "./server";

export interface Weather {
  condition_text: string;
  condition_icon: string;
  temp: string;
  cloud: string;
}

export const fetchWeather = async (
  facility: Facility,
  tokenGetter: Promise<string>
): Promise<Weather | undefined> => {
  const token = await tokenGetter;

  const address = GATE_ADDRESS + `/facility/${facility.id}/weather`;
  const response = await fetch(address, {
    headers: {
      Authorization: `bearer ${token}`,
    },
  });

  if (response.status != 200) {
    return undefined;
  }

  return (await response.json()) as Weather;
};
