import useSWR from "swr";
import { Facility } from "../facility";
import { GATE_ADDRESS } from "../server";
import { UsageQuery } from "./query";

export interface Usage {
  duration: string;
  time: string;
  pv: number;
  grid: number;
  load: number;
  battery: number;
}

const transformJsonToUsage = (
  duration: string,
  time: string,
  points: any
): Usage => {
  return {
    duration: duration,
    time: time,
    pv: points.pv / 1000 / 60,
    grid: points.grid / 1000 / 60,
    battery: points.battery / 1000 / 60,
    load: points.load / 1000 / 60,
  };
};

export const useEmsUsage = (
  facility: Facility,
  query: UsageQuery,
  tokenGetter: Promise<string>
) => {
  const { data, error, isLoading, mutate } = useSWR(
    constructPath(facility.id, query),
    () => fetchEmsUsage(facility.id, query, tokenGetter)
  );

  return {
    usage: data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const fetchEmsUsage = async (
  facilityId: string,
  query: UsageQuery,
  tokenGetter: Promise<string>
): Promise<Usage> => {
  const response = await fetchFromGate(facilityId, query, tokenGetter);
  const json = await response.json();

  for (let time in json) {
    return transformJsonToUsage(query.duration, time, json[time]);
  }

  throw Error("no ems point found");
};

export const fetchEmsUsageRange = async (
  facilityId: string,
  query: UsageQuery,
  tokenGetter: Promise<string>
): Promise<Array<Usage>> => {
  const response = await fetchFromGate(facilityId, query, tokenGetter);
  const json = await response.json();

  let items = new Array();
  for (let time in json) {
    items.push(transformJsonToUsage(query.duration, time, json[time]));
  }

  return items;
};

const fetchFromGate = async (
  facilityId: string,
  query: UsageQuery,
  tokenGetter: Promise<string>
): Promise<Response> => {
  const token = await tokenGetter;
  return await fetch(GATE_ADDRESS + constructPath(facilityId, query), {
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
};

const constructPath = (facilityId: string, query: UsageQuery): string => {
  const parameters = new URLSearchParams({
    aggregate_duration: query.duration,
    start: query.start.toISO(),
    stop: query.stop.toISO(),
  });
  return `/facility/${facilityId}/usage?${parameters}`;
};
