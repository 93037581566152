import { useAuth0 } from "@auth0/auth0-react";
import { add, format } from "date-fns";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Facility } from "../../../../api/facility";
import { Container, Icon } from "../../Atoms";
import SevenInch from "../../Pages/SevenInch/SevenInch";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";

import avatarImg from "./../../Assets/Icon/avatar.svg";
import thiinkroundImg from "./../../Assets/logo/logo-symbol.png";
import { EnergyPriceDay } from "../../../../api/energyprice";
import { EmsState } from "../../../../api/state";

export const SevenFrame: FC<{
  facility: Facility | null;
  handleChooseFacility: (value: string) => void;
  energyPriceDay: EnergyPriceDay;
  emsState: EmsState;
}> = ({ facility, handleChooseFacility, energyPriceDay, emsState }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [overViewData, setOverViewData] = useState<number[]>([]);
  const [jIT, setJIT] = useState([]);
  const [detailUsage, setDetailUsage] = useState<any>([]);
  const [usageCostData, setUsagCosteData] = useState<any>([]);
  const [dailyUsagedata, setDailyUsagedata] = useState<{
    date?: string;
    load: number;
    battery: number;
    grid: number;
    pv: number;
  }>({ date: "", load: 0, battery: 0, grid: 0, pv: 0 });

  const fetchAuthenticated = async (url: string): Promise<Response> => {
    const accessToken = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return fetch(url, {
      headers: headers,
    });
  };

  const setDefaultFacility = async () => {
    handleChooseFacility("003f3a0b-749d-4259-be13-ff77669e48bc");
    alert("Set default facility Id to : 003f3a0b-749d-4259-be13-ff77669e48bc");
    window.location.reload();
  };

  const initialLoad = useRef(false);
  useEffect(() => {
    if (initialLoad.current === true) {
      // Already initialized.
      return;
    }
    initialLoad.current = true;

    jit();
    fetchUsageCost(
      "1d",
      format(new Date(), `yyyy-MM-dd`),
      format(new Date(), `yyyy-MM-dd`),
      "grid_cost"
    );
    fetchDailyUsage();
  }, [facility]);

  const fetchfacilitieDataUsage = async (
    duration: string,
    start: string,
    end: string
  ) => {
    const facilitiesAlldata = await fetchAuthenticated(
      `https://gate.thiink.io/hc/v1/facility/${facility?.id}/usage?` +
        new URLSearchParams({
          aggregate_duration: duration,
          start: `${start}`,
          stop: `${end}`,
        })
    );

    const convData = await facilitiesAlldata.json();

    const allUsageData = [];
    for (const [key, value] of Object.entries(convData)) {
      allUsageData.push({
        // @ts-ignore
        battery: value?.battery / 1000 / 60,
        // @ts-ignore
        date: key,
        // @ts-ignore
        grid: value?.grid / 1000 / 60,
        // @ts-ignore
        load: value?.load / 1000 / 60,
        // @ts-ignore
        pv: value?.pv / 1000 / 60,
      });
    }

    return allUsageData;
  };

  const jit = async () => {
    const facilitiesAlldata = await fetchAuthenticated(
      `https://gate.thiink.io/hc/v1/facility/${facility?.id}/usage_cost?` +
        new URLSearchParams({
          aggregate_duration: "1d",
          start: `${format(new Date(), `yyyy-MM-dd`)}T00:00:00+01:00`,
          stop: `${format(new Date(), `yyyy-MM-dd`)}T23:59:59+01:00`,
        })
    );

    const convData = await facilitiesAlldata.json();

    const allUsageData = [];
    for (const [key, value] of Object.entries(convData.points)) {
      // @ts-ignore
      allUsageData.push(value?.grid_cost);
    }

    // @ts-ignore
    setJIT(allUsageData);
  };

  const fetchDailyUsage = async () => {
    const todayDate = format(new Date(), "yyyy-MM-dd'T'00:00:00'+01:00'");
    const tomorrowDate = format(
      add(new Date(), { days: 1 }),
      "yyyy-MM-dd'T'00:00:00'+01:00'"
    );

    const dailyUsage = await fetchfacilitieDataUsage(
      "1d",
      todayDate,
      tomorrowDate
    );

    const [daily] = dailyUsage;

    await jit();
    setDailyUsagedata(daily);
  };

  const fetchUsage = useCallback(
    async (
      duration: string,
      start: string,
      end: string,
      dataType: string,
      isToday: boolean = false
    ) => {
      const facilitiesAlldata = await fetchAuthenticated(
        `https://gate.thiink.io/hc/v1/facility/${facility?.id}/usage?` +
          new URLSearchParams({
            aggregate_duration: duration,
            start: `${start}T00:00:00+01:00`,
            stop: `${end}T23:59:59+01:00`,
          })
      );

      const convData = await facilitiesAlldata.json();

      const allUsageData = [];
      for (const [key, value] of Object.entries(convData)) {
        // @ts-ignore
        if (isToday && value?.[dataType] === 0) {
          // @ts-ignore
          allUsageData.push(undefined);
        } else {
          // @ts-ignore
          allUsageData.push(value?.[dataType] / 60 / 1000);
        }
      }

      if (isToday) {
        allUsageData.map((item) => {
          if (item === 0) {
            //@ts-ignore
            return (item = undefined);
          }
          return item;
        });
      }

      setDetailUsage(allUsageData);
    },
    [facility]
  );

  const fetchUsageCost = async (
    duration: string,
    start: string,
    end: string,
    dataType: string,
    isToday: boolean = false
  ) => {
    const facilitiesAlldata = await fetchAuthenticated(
      `https://gate.thiink.io/hc/v1/facility/${facility?.id}/usage_cost?` +
        new URLSearchParams({
          aggregate_duration: duration,
          start: `${start}T00:00:00+01:00`,
          stop: `${end}T23:59:59+01:00`,
        })
    );

    const convData = await facilitiesAlldata.json();

    const allUsageData = [];
    for (const [key, value] of Object.entries(convData.points)) {
      // @ts-ignore
      if (isToday && value?.[dataType] === 0) {
        // @ts-ignore
        allUsageData.push(undefined);
      } else {
        // @ts-ignore
        allUsageData.push(value?.[dataType]);
      }
    }
    setUsagCosteData(allUsageData);
  };

  const fetchOverviewData = async (
    overview: any,
    startDate: any,
    endDate: any,
    dataType: string
  ) => {
    const startTimeDate = `${startDate}T00:00:00+01:00`;
    const endTimeDate = `${endDate}T23:59:59+01:00`;

    switch (overview) {
      case "day":
        const overviewDataDay = await fetchfacilitieDataUsage(
          "1d",
          startTimeDate,
          endTimeDate
        );
        const cleanUpOverview = overviewDataDay.map((item) => {
          //@ts-ignore
          return item[dataType];
        });

        setOverViewData(cleanUpOverview);

        break;
      case "week":
        const overviewDataWeek = await fetchfacilitieDataUsage(
          "1w",
          startTimeDate,
          endTimeDate
        );
        const cleanUpOverviewWeek = overviewDataWeek.map(
          //@ts-ignore
          (item) => item[dataType]
        );

        setOverViewData(cleanUpOverviewWeek);
        break;
      case "month":
        const overviewDataMonth = await fetchfacilitieDataUsage(
          "1mo",
          startTimeDate,
          endTimeDate
        );
        const cleanUpOverviewMonth = overviewDataMonth.map(
          //@ts-ignore
          (item) => item[dataType]
        );
        setOverViewData(cleanUpOverviewMonth);
        break;

      case "year":
        const overviewDataYear = await fetchfacilitieDataUsage(
          "1y",
          startTimeDate,
          endTimeDate
        );
        const cleanUpOverviewYear = overviewDataYear.map(
          //@ts-ignore
          (item) => item[dataType]
        );
        setOverViewData(cleanUpOverviewYear);
        break;

      default:
        break;
    }
  };

  return (
    <Container flexDirection="column" id="mainRoot">
      <div id="sevenInchContainer">
        <SevenInch
          emsState={emsState}
          dailyUsagedata={dailyUsagedata}
          detailUsage={detailUsage}
          usageCostData={usageCostData}
          fetchOverviewData={fetchOverviewData}
          facility={facility}
          jIT={jIT}
          fetchUsage={fetchUsage}
          fetchUsageCost={fetchUsageCost}
          overViewData={overViewData}
          energyPriceDay={energyPriceDay}
        />
        <Container
          padding="8px 0 0 0"
          justifyContent="space-between"
          className="rpi7inch"
        >
          <Container alignItems="center" gap={8}>
            <Icon src={thiinkroundImg} />
          </Container>

          <Container onClick={() => setDefaultFacility()}>
            <Icon src={avatarImg} />
          </Container>
        </Container>
      </div>
    </Container>
  );
};
