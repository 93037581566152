import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import StateProvider from "./ContextApi/StateProvider/StateProvider";
import { ThemeProvider } from "styled-components";
import themes from "./ContextApi/ThemeProvider/theme";

import ErrorBoundary from "./Components/Atomic/Molecules/ErrorBoundary/ErrorBoundary";

import { BrowserRouter, useLocation } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Wrapper = ({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const [defaultTheme] = themes;

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <StateProvider>
        <ThemeProvider theme={defaultTheme}>
          <BrowserRouter>
            <Wrapper>
              <Auth0Provider
                domain="auth.thiink.io"
                clientId="IlR70YE0cShVSgEEhgtGS8aMzKcxvIuQ"
                useRefreshTokens={true}
                redirectUri={window.location.origin}
                cacheLocation="localstorage"
                audience="https://gate.thiink.io/hc"
                scope="offline_access"
              >
                <App />
              </Auth0Provider>
            </Wrapper>
          </BrowserRouter>
        </ThemeProvider>
      </StateProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("./service-worker.js");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
