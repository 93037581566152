import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";

interface IconProps extends HTMLAttributes<HTMLImageElement> {
  src: string;
  width?: string;
  height?: string;
}

const ImgContainer = styled.img``;

const Icon: FC<IconProps> = ({ src, width, height, ...rest }) => {
  return (
    <ImgContainer
      src={src}
      alt="img"
      width={width ? width : "32px"}
      height={height ? height : "32px"}
      {...rest}
    />
  );
};

export default Icon;
