import { DateTime } from "luxon";
import { Facility } from "./facility";
import { GATE_ADDRESS } from "./server";

export interface EmsState {
  battery: number;
  pv: number;
  grid: number;
  load: number;
  soc: number;
  updated: DateTime;
}

export const DefaultEmsState: EmsState = {
  battery: 0,
  pv: 0,
  grid: 0,
  load: 0,
  soc: 0,
  updated: DateTime.fromSeconds(0),
};

export const fetchEmsState = async (
  facility: Facility,
  tokenGetter: Promise<string>
): Promise<EmsState> => {
  const token = await tokenGetter;

  const response = await fetch(
    `${GATE_ADDRESS}/facility/${facility.id}/state/device.state.ems`,
    {
      headers: {
        Authorization: `bearer ${token}`,
      },
    }
  );

  const json = await response.json();

  const battery =
    json.data.battery > 0
      ? -Math.abs(parseInt(json.data.battery))
      : Math.abs(parseInt(json.data.battery));

  return {
    battery: battery,
    pv: json.data.pv,
    grid: json.data.grid,
    load: json.data.load,
    soc: json.data.soc,
    updated: DateTime.fromISO(json.updated).setZone(facility.timezone),
  };
};
