import React, { FC, useContext, useEffect, useState } from "react";
import DateScroll from "./../../Molecules/DateScroll/DateScroll";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
} from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";

import { costBreakdown } from "../../../../api/usage/costBreakdown";

import { Card, Container, Hr, Typography } from "../../Atoms";

import { Facility } from "../../../../api/facility";
import { StateContext } from "./../../../../ContextApi/StateProvider/StateProvider";

import { DateTime } from "luxon";
import { fetchCostRange } from "../../../../api/usage/cost";

const StatsPage: FC<{
  facility: Facility | null;
}> = ({ facility }) => {
  const [load, setLoad] = useState(0);
  const [battery, setBattery] = useState(0);
  const [pv, setPv] = useState(0);
  const [grid, setGrid] = useState(0);
  const [costs, setCosts] = useState({
    battery_profit: 0,
    grid_cost: 0,
    load_cost: 0,
    pv_profit: 0,
  });

  const context = useContext(StateContext);

  const { getAccessTokenSilently } = useAuth0();

  const fetchAuthenticated = async (url: string): Promise<Response> => {
    const accessToken = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    return fetch(url, {
      headers: headers,
    });
  };

  const fetchUsageTotal = async (
    duration: string,
    start: string,
    end: string
  ) => {
    const facilitiesAlldata = await fetchAuthenticated(
      `https://gate.thiink.io/hc/v1/facility/${facility?.id}/usage?` +
        new URLSearchParams({
          aggregate_duration: duration,
          start: `${start}T00:00:00+01:00`,
          stop: `${end}T23:59:59+01:00`,
        })
    );

    const convData = await facilitiesAlldata.json();

    const loadData = [];
    const gridData = [];
    const pvData = [];
    const batteryData = [];

    for (const [key, value] of Object.entries(convData)) {
      //@ts-ignore
      loadData.push(value?.load / 60 / 1000);
      //@ts-ignore
      gridData.push(value?.grid / 60 / 1000);
      //@ts-ignore
      pvData.push(value?.pv / 60 / 1000);
      //@ts-ignore
      batteryData.push(value?.battery / 60 / 1000);
    }

    const loadTotal = loadData
      .reduce((partialSum: number, a: number) => partialSum + a, 0)
      ?.toFixed(2);
    const gridTotal = gridData
      .reduce((partialSum: number, a: number) => partialSum + a, 0)
      ?.toFixed(2);
    const pvTotal = pvData
      .reduce((partialSum: number, a: number) => partialSum + a, 0)
      ?.toFixed(2);
    const batteryTotal = batteryData
      .reduce((partialSum: number, a: number) => partialSum + a, 0)
      ?.toFixed(2);

    setLoad(parseFloat(loadTotal));
    setBattery(parseFloat(batteryTotal));
    setPv(parseFloat(pvTotal));
    setGrid(parseFloat(gridTotal));
  };

  const fetchCostTotal = async (
    duration: string,
    startDate: DateTime,
    endDate: DateTime
  ) => {
    if (facility === null) {
      return;
    }

    let cost = await fetchCostRange(
      facility.id,
      { duration: duration, start: startDate, stop: endDate },
      getAccessTokenSilently()
    );

    const totalCosts = cost.reduce(
      (accumulator, currentObject) => {
        return {
          battery_profit:
            accumulator.battery_profit + currentObject.battery_profit,
          load_cost: accumulator.load_cost + currentObject.load_cost,
          pv_profit: accumulator.pv_profit + currentObject.pv_profit,
          grid_cost: accumulator.grid_cost + currentObject.grid_cost,
        };
      },
      {
        battery_profit: 0,
        load_cost: 0,
        pv_profit: 0,
        grid_cost: 0,
      }
    );

    setCosts({ ...totalCosts });
  };

  useEffect(() => {
    if (facility === null) {
      return;
    }
    switch (context.selectedPeriod.value) {
      case "day":
        fetchUsageTotal(
          "1d",
          format(new Date(), `yyyy-MM-dd`),
          format(new Date(), `yyyy-MM-dd`)
        );

        fetchCostTotal(
          "1d",
          facility.localTime().startOf("day"),
          facility.localTime().endOf("day")
        );
        break;

      case "week":
        fetchUsageTotal(
          "1d",
          format(startOfWeek(new Date(), { weekStartsOn: 1 }), `yyyy-MM-dd`),
          format(endOfWeek(new Date(), { weekStartsOn: 1 }), `yyyy-MM-dd`)
        );
        fetchCostTotal(
          "1d",
          facility.localTime().startOf("week"),
          facility.localTime().endOf("week")
        );
        break;

      case "month":
        fetchUsageTotal(
          "1mo",
          format(startOfMonth(subMonths(new Date(), 0)), `yyyy-MM-dd`),

          format(endOfMonth(subMonths(new Date(), 0)), `yyyy-MM-dd`)
        );
        fetchCostTotal(
          "1d",
          facility.localTime().startOf("month"),
          facility.localTime().endOf("month")
        );
        break;

      case "year":
        fetchUsageTotal(
          "1y",
          format(startOfYear(new Date()), `yyyy-MM-dd`),
          format(endOfYear(new Date()), `yyyy-MM-dd`)
        );
        fetchCostTotal(
          "1d",
          facility.localTime().startOf("year"),
          facility.localTime().endOf("year")
        );
        break;
      default:
        break;
    }
  }, [facility, context.selectedPeriod.value]);

  return (
    <Container
      flexDirection="column"
      padding="14px 14px 27px 14px"
      gap={16}
      height="100vh"
      bgColor="#fafafa"
      scroll="scroll"
    >
      <DateScroll
        fetchUsageTotal={fetchUsageTotal}
        setCurrentDuration={context.selectedPeriod?.setter}
        currentDuration={context.selectedPeriod.value}
      />
      <Link to="/homeusage" style={{ textDecoration: "none", width: "100%" }}>
        <Card backgroundColor="Teal" flex="0">
          <Container flexDirection="column" flex={1}>
            <Typography
              color="white"
              fontSize="Small"
              marginBottom="None"
              marginTop="None"
              marginLeft="None"
            >
              Home usage
            </Typography>
            <Hr />
            <Container flexDirection="row" flex={1}>
              <Container flexDirection="column" flex={1}>
                <Container
                  flexDirection="row"
                  flex={1}
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  <Typography
                    color="white"
                    fontSize="H4"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                  >
                    {load.toFixed(2)}
                  </Typography>
                  <Typography
                    color="white"
                    fontSize="Small"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                    noTransform
                  >
                    kWh
                  </Typography>
                </Container>
                <Typography
                  color="white"
                  fontSize="Small"
                  marginBottom="None"
                  marginTop="None"
                  marginLeft="None"
                >
                  Electricity used
                </Typography>
              </Container>
              <Container
                flexDirection="column"
                flex={1}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Container
                  flexDirection="row"
                  flex={1}
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Typography
                    color="white"
                    fontSize="H4"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                  >
                    {costs.load_cost.toFixed(2)}
                  </Typography>
                  <Typography
                    color="white"
                    fontSize="Small"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                  >
                    SEK
                  </Typography>
                </Container>
                <Typography
                  color="white"
                  fontSize="Small"
                  marginTop="None"
                  marginLeft="None"
                >
                  Cost
                </Typography>
              </Container>
            </Container>
          </Container>
        </Card>
      </Link>
      <Link
        to="/batteryusage"
        style={{ textDecoration: "none", width: "100%" }}
      >
        <Card backgroundColor="DeepBlue" flex="0">
          <Container flexDirection="column" flex={1}>
            <Typography
              color="white"
              fontSize="Small"
              marginBottom="None"
              marginTop="None"
              marginLeft="None"
            >
              Battery usage
            </Typography>
            <Hr />
            <Container flexDirection="row" flex={1}>
              <Container flexDirection="column" flex={1}>
                <Container
                  flexDirection="row"
                  flex={1}
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  <Typography
                    color="white"
                    fontSize="H4"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                  >
                    {battery < 0
                      ? Math.abs(battery).toFixed(2)
                      : battery.toFixed(2)}
                  </Typography>
                  <Typography
                    color="white"
                    fontSize="Small"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                    noTransform
                  >
                    kWh
                  </Typography>
                </Container>
                <Typography
                  color="white"
                  fontSize="Small"
                  marginTop="None"
                  marginLeft="None"
                >
                  {costs.battery_profit < 0 ? "Charged" : "Discharged"}
                </Typography>
              </Container>
              <Container
                flexDirection="column"
                flex={1}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Container
                  flexDirection="row"
                  flex={1}
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Typography
                    color="white"
                    fontSize="H4"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                  >
                    {/* {costs.battery_profit.toFixed(2)} */}

                    {costs.battery_profit < 0
                      ? Math.abs(costs.battery_profit).toFixed(2)
                      : costs.battery_profit.toFixed(2)}
                  </Typography>
                  <Typography
                    color="white"
                    fontSize="Small"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                  >
                    SEK
                  </Typography>
                </Container>
                <Typography
                  color="white"
                  fontSize="Small"
                  marginTop="None"
                  marginLeft="None"
                >
                  {costs.battery_profit > 0 ? "Profit" : "Cost"}
                </Typography>
              </Container>
            </Container>
          </Container>
        </Card>
      </Link>
      <Link to="/solarpanels" style={{ textDecoration: "none", width: "100%" }}>
        <Card backgroundColor="SkyLight" flex="0">
          <Container flexDirection="column" flex={1}>
            <Typography
              color="white"
              fontSize="Small"
              marginBottom="None"
              marginTop="None"
              marginLeft="None"
            >
              Solar panels
            </Typography>
            <Hr />
            <Container flexDirection="row" flex={1}>
              <Container flexDirection="column" flex={1}>
                <Container
                  flexDirection="row"
                  flex={1}
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  <Typography
                    color="white"
                    fontSize="H4"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                  >
                    {pv.toFixed(2)}
                  </Typography>
                  <Typography
                    color="white"
                    fontSize="Small"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                    noTransform
                  >
                    kWh
                  </Typography>
                </Container>
                <Typography
                  color="white"
                  fontSize="Small"
                  marginTop="None"
                  marginLeft="None"
                >
                  Electricity generated
                </Typography>
              </Container>
              <Container
                flexDirection="column"
                flex={1}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Container
                  flexDirection="row"
                  flex={1}
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Typography
                    color="white"
                    fontSize="H4"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                  >
                    {costs.pv_profit.toFixed(2)}
                  </Typography>
                  <Typography
                    color="white"
                    fontSize="Small"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                  >
                    SEK
                  </Typography>
                </Container>
                <Typography
                  color="white"
                  fontSize="Small"
                  marginTop="None"
                  marginLeft="None"
                >
                  Profit
                </Typography>
              </Container>
            </Container>
          </Container>
        </Card>
      </Link>
      <Link to="/gridusage" style={{ textDecoration: "none", width: "100%" }}>
        <Card backgroundColor="DangerRed" flex="0">
          <Container flexDirection="column" flex={1}>
            <Typography
              color="white"
              fontSize="Small"
              marginBottom="None"
              marginTop="None"
              marginLeft="None"
            >
              Grid usage
            </Typography>
            <Hr />
            <Container flexDirection="row" flex={1}>
              <Container flexDirection="column" flex={1}>
                <Container
                  flexDirection="row"
                  flex={1}
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  <Typography
                    color="white"
                    fontSize="H4"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                  >
                    {grid < 0 ? Math.abs(grid) : grid}
                  </Typography>
                  <Typography
                    color="white"
                    fontSize="Small"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                    noTransform
                  >
                    kWh
                  </Typography>
                </Container>
                <Typography
                  color="white"
                  fontSize="Small"
                  marginTop="None"
                  marginLeft="None"
                >
                  {grid < 0 ? "Electricity sold" : "Electricity used"}
                </Typography>
              </Container>
              <Container
                flexDirection="column"
                flex={1}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Container
                  flexDirection="row"
                  flex={1}
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Typography
                    color="white"
                    fontSize="H4"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                  >
                    {costs.grid_cost < 0
                      ? Math.abs(costs.grid_cost).toFixed(2)
                      : costs.grid_cost.toFixed(2)}
                  </Typography>
                  <Typography
                    color="white"
                    fontSize="Small"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                  >
                    SEK
                  </Typography>
                </Container>
                <Typography
                  color="white"
                  fontSize="Small"
                  marginBottom="None"
                  marginTop="None"
                  marginLeft="None"
                >
                  {costs.grid_cost < 0 ? "Profit" : "Cost"}
                </Typography>
              </Container>
            </Container>
          </Container>
        </Card>
      </Link>
    </Container>
  );
};
export default StatsPage;
