import { FC, useState } from "react";
import { Card, Container, Typography } from "../../Atoms";
import styled from "styled-components";

export enum Period {
  DayAhead,
  Day,
  Week,
  Month,
  Year,
}

type PeriodOptions = {
  period: Period;
  title: string;
  enabled: boolean;
};

const options: PeriodOptions[] = [
  { period: Period.Year, enabled: false, title: "1Y" },
  { period: Period.Month, enabled: false, title: "1M" },
  { period: Period.Week, enabled: false, title: "1W" },
  { period: Period.Day, enabled: true, title: "1D" },
  { period: Period.DayAhead, enabled: true, title: "+1D" },
];

export type TimePeriodSelectorProps = {
  defaultPeriod: Period;
  periodCallback: (period: Period) => void;
};

const RootContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const TimePeriodSelector: FC<TimePeriodSelectorProps> = ({
  defaultPeriod,
  periodCallback,
}) => {
  const [selectPeriod, setSelectedPeriod] = useState<Period>(defaultPeriod);

  const onClick = (option: PeriodOptions) => {
    if (option.enabled === false) {
      return;
    }

    if (option.period === selectPeriod) {
      return;
    }

    setSelectedPeriod(option.period);
    periodCallback(option.period);
  };

  const selectionColor = (option: PeriodOptions): string => {
    if (option.enabled === false) {
      return "#D7D6D9";
    }
    return selectPeriod == option.period ? "#5AA9F5" : "#000000";
  };

  return (
    <RootContainer>
      <Card backgroundColor="White" noPadding>
        <Container
          flexDirection="row"
          justifyContent="space-between"
          flex={1}
          gap={16}
          padding="16px"
        >
          {options.map((option) => (
            <Typography
              color={selectionColor(option)}
              marginBottom="None"
              marginTop="None"
              fontSize="H6"
              onClick={() => {
                onClick(option);
              }}
              key={option.period}
            >
              <label>{option.title}</label>
            </Typography>
          ))}
        </Container>
      </Card>
    </RootContainer>
  );
};
