import { Facility } from "../facility";
import { UsageQuery } from "./query";
import useSWR from "swr";
import { GATE_ADDRESS } from "../server";

export interface Cost {
  grid_cost: number;
  pv_profit: number;
  load_cost: number;
  battery_profit: number;
  duration: string;
  time: string;
}

const transformRespBodyToCost = (
  duration: string,
  point: RespBodyPoint
): Cost => {
  return {
    duration: duration,
    time: point.time,
    grid_cost: point.grid_cost,
    battery_profit: point.battery_profit,
    pv_profit: point.pv_profit,
    load_cost: point.load_cost,
  };
};

export const useCost = (
  facility: Facility,
  query: UsageQuery,
  tokenGetter: Promise<string>
) => {
  const { data, error, isLoading, mutate } = useSWR(
    constructPath(facility.id, query),
    () => fetchCost(facility.id, query, tokenGetter)
  );

  return {
    cost: data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const fetchCost = async (
  facilityId: string,
  query: UsageQuery,
  tokenGetter: Promise<string>
): Promise<Cost> => {
  const response = await fetchFromGate(facilityId, query, tokenGetter);
  const json = (await response.json()) as RespBody;

  if (json.points.length > 0) {
    return transformRespBodyToCost(query.duration, json.points[0]);
  }

  throw Error("no cost point found");
};

export const fetchCostRange = async (
  facilityId: string,
  query: UsageQuery,
  tokenGetter: Promise<string>
): Promise<Array<Cost>> => {
  const response = await fetchFromGate(facilityId, query, tokenGetter);
  const json = (await response.json()) as RespBody;

  let items = new Array();
  json.points.forEach((point) => {
    items.push(transformRespBodyToCost(query.duration, point));
  });
  return items;
};

interface RespBody {
  timezone: string;
  points: Array<RespBodyPoint>;
}

interface RespBodyPoint {
  grid_cost: number;
  pv_profit: number;
  load_cost: number;
  battery_profit: number;
  time: string;
}

const fetchFromGate = async (
  facilityId: string,
  query: UsageQuery,
  tokenGetter: Promise<string>
): Promise<Response> => {
  const token = await tokenGetter;
  return await fetch(GATE_ADDRESS + constructPath(facilityId, query), {
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
};

const constructPath = (facilityId: string, query: UsageQuery): string => {
  const parameters = new URLSearchParams({
    aggregate_duration: query.duration,
    start: query.start.toISO(),
    stop: query.stop.toISO(),
  });

  return `/facility/${facilityId}/usage_cost?${parameters}`;
};
