import { useState, useEffect, useCallback } from "react";
import { useAPI } from "./fetcher";
import { DateTime } from "luxon";

const selFacilityIdKey = "facilityId";

export const ErrNoFacilitySelected = "no_facility_selected";

export class Facility {
  id: string;
  name: string;
  timezone: string;
  default: boolean;

  constructor(id: string, name: string, timezone: string, def: boolean) {
    this.id = id;
    this.name = name;
    this.timezone = timezone;
    this.default = def;
  }

  localTime(): DateTime {
    return DateTime.now().setZone(this.timezone);
  }
}

export const useFacility = (): [
  loading: boolean,
  error: string | null,
  facilities: Facility[],
  facility: Facility | null,
  loader: () => void,
  setter: (facilityId: string) => void
] => {
  const [loading, , response, fetcher] = useAPI();
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [facility, setFacility] = useState<Facility | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (response === undefined) {
      return;
    }

    const fs = (response as any[]).map((i) => {
      return new Facility(i.id, i.name, i.timezone, i.default);
    });

    // First, let's see if we can find user's default facility.
    let selFacility = fs.find((f) => f.default === true);

    // Secondly, if there is an active selection done.
    const selFacilityId = localStorage.getItem(selFacilityIdKey);
    if (selFacilityId !== undefined) {
      const find = fs.find((f) => f.id === selFacilityId);
      if (find !== undefined) {
        selFacility = find;
      }
    }

    if (selFacility === undefined) {
      setError(ErrNoFacilitySelected);
    }

    setFacility(selFacility === undefined ? null : selFacility);
    setFacilities(fs);
  }, [response]);

  const loader = useCallback(async () => {
    fetcher("/facility", "GET", null);
  }, [fetcher]);

  const setter = useCallback(
    (facilityId: string) => {
      const facility = facilities.find((f) => f.id === facilityId);
      if (facility === undefined) {
        setError(ErrNoFacilitySelected);
        return;
      }

      localStorage.setItem(selFacilityIdKey, facility.id);
      setError(null);
      setFacility(facility);
    },
    [facilities]
  );

  return [loading, error, facilities, facility, loader, setter];
};
