import { FC, useEffect, useMemo, useState } from "react";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-luxon";
import { Facility } from "../../../../api/facility";
import { fetchEmsUsageRange, Usage } from "../../../../api/usage/ems";
import { UsageQuery } from "../../../../api/usage/query";
import { useAuth0 } from "@auth0/auth0-react";
import { Period } from "../../../../ContextApi/StateProvider/type";
import { DateTimeFormatFromDuration } from "../../Utils/chart";
import { barBackground, Mode } from "./shared";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const labels = (mode: Mode) => {
  const prefix = mode === "consumption" ? "Usage" : "Production";
  return {
    day: `${prefix}, compared to other days`,
    week: `${prefix}, compared to other weeks`,
    month: `${prefix}, compared to other months`,
    year: `${prefix}, compared to other years`,
  };
};

const CompareUsageChart: FC<{
  facility: Facility;
  period: Period;
  point: "load" | "pv" | "grid" | "battery";
  highlight: number;
  mode: Mode;
}> = ({ facility, period, highlight, point, mode }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [usage, setUsage] = useState<Array<Usage>>(new Array());

  const graphLabelsCompare = labels(mode);

  const loadUsage = async (query: UsageQuery) => {
    const usage = await fetchEmsUsageRange(
      facility.id,
      query,
      getAccessTokenSilently()
    );
    setUsage(usage);
  };

  useEffect(() => {
    const today = facility.localTime().startOf("day");

    let query: UsageQuery | null = null;
    switch (period) {
      case "day":
        query = {
          duration: "1d",
          start: today.minus({ days: 6 }),
          stop: today.plus({ days: 1 }),
        };
        break;
      case "week":
        query = {
          duration: "1w",
          start: today.startOf("week").minus({ weeks: 6 }),
          stop: today.startOf("week").plus({ week: 1 }),
        };
        break;
      case "month":
        query = {
          duration: "1mo",
          start: today.startOf("month").minus({ months: 6 }),
          stop: today.startOf("month").plus({ months: 1 }),
        };
        break;
      case "year":
        query = {
          duration: "1y",
          start: today.startOf("year").minus({ years: 6 }),
          stop: today.startOf("year").plus({ years: 1 }),
        };
        break;
    }

    loadUsage(query);
  }, [period]);

  const compareChartData = useMemo(() => {
    return {
      datasets: [
        {
          label: "Usage",
          data: usage,
          backgroundColor: usage.map((item: Usage, i: number) => {
            if (i === highlight) {
              return "#5ba9f5";
            }
            return barBackground(mode, item[point]);
          }),
          borderRadius: 50,
        },
      ],
    };
  }, [usage, highlight]);

  const compareChartOptions = useMemo(() => {
    let duration = usage.length > 0 ? usage[0].duration : "";
    let tooltipFormat = DateTimeFormatFromDuration(duration, "HH:mm");

    return {
      responsive: true,
      maintainAspectRatio: false,
      borderColor: "#007aff",
      backgroundColor: "#007aff",
      tension: 0,
      pointRadius: 0,
      scales: {
        y: {
          ticks: {
            callback: function (label: any, index: any, labels: any) {
              return label.toFixed(0) + " kWh";
            },
          },
          stepSize: 5,
          beginAtZero: true,
        },
        x: {
          type: "time" as const,
          adapters: {
            date: {
              zone: facility.timezone,
            },
          },
          time: {
            unit: period,
            tooltipFormat: tooltipFormat,
            displayFormats: {
              day: "cccc",
              week: "w.W",
              month: "LLL",
              year: "yyyy",
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
          position: "top" as const,
        },
        title: {
          display: true,
          text: graphLabelsCompare[period],
        },
        tooltip: {
          titleAlign: "center" as const,
          bodyAlign: "center" as const,
          caretPadding: 10,
          displayColors: false,
          callbacks: {
            label: (item: any) => {
              return `${item?.parsed?.y?.toFixed(2)} kWh`;
            },
          },
        },
      },
      parsing: {
        xAxisKey: "time",
        yAxisKey: point,
      },
    };
  }, [period, point, usage]);

  return (
    <Bar options={compareChartOptions} data={compareChartData} id="statsBar" />
  );
};

export default CompareUsageChart;
