import React, { FC, useState } from "react";
import styled from "styled-components";

import { Container, Icon, Typography } from "../../Atoms";

import questionImg from "./../../Assets/Icon/question.svg";
import closeImg from "./../../Assets/Icon/close.svg";

import { helperText } from "./helperText";

const ModalBackDrop = styled.div`
  background-color: #000000aa;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 64px;
`;

const Questionmark: FC<{
  helperItem: string;
}> = ({ helperItem }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleModal = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setModalOpen((oldState) => !oldState);
  };

  return (
    <Container justifyContent="center" alignItems="center">
      <Icon
        src={questionImg}
        width="18px"
        height="18px"
        onClick={(e) => handleModal(e)}
      />
      {modalOpen ? (
        <ModalBackDrop onClick={(e) => handleModal(e)}>
          <Container
            bgColor="#ffffff"
            padding="12px"
            flexDirection="column"
            borderRadius="8px 8px 0 0"
          >
            <Container justifyContent="space-between">
              <Typography
                as="h4"
                fontSize="H4"
                marginBottom={16}
                marginLeft="None"
                marginTop="None"
              >
                {helperText[helperItem].title}
              </Typography>
              <Icon
                src={closeImg}
                width="32px"
                height="32px"
                onClick={(e) => handleModal(e)}
              />
            </Container>
            <Typography
              as="p"
              fontSize="P"
              marginBottom={16}
              marginLeft="None"
              marginTop="None"
              style={{ whiteSpace: "break-spaces" }}
            >
              {helperText[helperItem].text}
            </Typography>
            <Typography
              as="p"
              fontSize="Small"
              marginBottom={24}
              marginLeft="None"
              marginTop="None"
            >
              <a href={helperText[helperItem].source} target="_blank">
                {helperText[helperItem].sourceTitle}
              </a>
            </Typography>
          </Container>
        </ModalBackDrop>
      ) : null}
    </Container>
  );
};
export default Questionmark;
