import React, { useState, createContext } from "react";

import type { Period, SelectedPeriodType } from "./type";

export const StateContext = createContext({
  selectedPeriod: {
    value: "day" as Period,
    setter: (selectedPeriod: Period) => {},
  },
  selectedPeriodIndex: {
    value: 0,
    setter: (selectedPeriodIndex: number) => {},
  },
});

const StateProvider = ({ children }: any) => {
  const [selectedPeriod, setSelectedPeriod] = useState<Period>("day");
  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState<number>(6);
  const contextValue = {
    selectedPeriod: {
      value: selectedPeriod,
      setter: setSelectedPeriod,
    } as SelectedPeriodType,
    selectedPeriodIndex: {
      value: selectedPeriodIndex,
      setter: setSelectedPeriodIndex,
    },
  };

  return (
    <StateContext.Provider value={contextValue}>
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;
