import React, { FC } from "react";
import { Card, Container, Typography } from "../../Atoms";
import styled from "styled-components";
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
} from "date-fns";

import type { Period } from "./../../../../ContextApi/StateProvider/type";

const DateScrollRootContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  p {
    cursor: pointer;
    user-select: none;
  }
`;

const DateScroll: FC<{
  fetchUsageTotal: (duration: string, start: string, end: string) => void;
  setCurrentDuration: (duration: Period) => void;
  currentDuration: string;
}> = ({ fetchUsageTotal, setCurrentDuration, currentDuration }) => {
  return (
    <DateScrollRootContainer id="ads">
      <Card backgroundColor="White" noPadding>
        <Container
          flexDirection="row"
          justifyContent="space-between"
          flex={1}
          gap={16}
          padding="16px"
        >
          <Typography
            color={currentDuration === "day" ? "#5AA9F5" : "#000000"}
            marginBottom="None"
            marginTop="None"
            fontSize="H6"
            // onClick={() => fetchUsageData("day")}
            onClick={() => {
              fetchUsageTotal(
                "1d",
                format(new Date(), `yyyy-MM-dd`),
                format(new Date(), `yyyy-MM-dd`)
              );
              setCurrentDuration("day");
            }}
          >
            Day
          </Typography>
          <Typography
            color={currentDuration === "week" ? "#5AA9F5" : "#000000"}
            marginBottom="None"
            marginTop="None"
            fontSize="H6"
            onClick={() => {
              fetchUsageTotal(
                "1d",
                format(
                  startOfWeek(new Date(), { weekStartsOn: 1 }),
                  `yyyy-MM-dd`
                ),
                format(endOfWeek(new Date(), { weekStartsOn: 1 }), `yyyy-MM-dd`)
              );
              setCurrentDuration("week");
            }}
          >
            Week
          </Typography>
          <Typography
            color={currentDuration === "month" ? "#5AA9F5" : "#000000"}
            marginBottom="None"
            marginTop="None"
            fontSize="H6"
            onClick={() => {
              fetchUsageTotal(
                "1mo",
                format(startOfMonth(subMonths(new Date(), 0)), `yyyy-MM-dd`),

                format(endOfMonth(subMonths(new Date(), 0)), `yyyy-MM-dd`)
              );
              setCurrentDuration("month");
            }}
          >
            Month
          </Typography>
          <Typography
            color={currentDuration === "year" ? "#5AA9F5" : "#000000"}
            marginBottom="None"
            marginTop="None"
            fontSize="H6"
            onClick={() => {
              fetchUsageTotal(
                "1mo",
                format(startOfYear(new Date()), `yyyy-MM-dd`),
                format(endOfYear(new Date()), `yyyy-MM-dd`)
              );
              setCurrentDuration("year");
            }}
          >
            Year
          </Typography>
        </Container>
      </Card>
    </DateScrollRootContainer>
  );
};

export default DateScroll;
