import React, { FC } from "react";
import styled from "styled-components";
import Container from "../Container/Container";
import type {
  DropDownProps,
  DropDownOptionProps,
  DropDownContainerProps,
} from "./types";

const DropDownContainer = styled.select<DropDownContainerProps>`
  padding: 8px;
  margin-bottom: 16px;
  border: none;
  border-right: 16px solid transparent;
  background-color: #000000;
  color: #ffffff;
  font-size: 16px;
  width: 100%;
`;

const DropDownItem = styled.option<DropDownOptionProps>``;

const DropDown: FC<DropDownProps> = ({ options, ...rest }) => {
  return (
    <Container>
      <DropDownContainer {...rest}>
        {options.map((option: DropDownOptionProps) => (
          <DropDownItem
            key={option.value}
            value={option.value}
            label={option.label}
          >
            {option.label}
          </DropDownItem>
        ))}
      </DropDownContainer>
    </Container>
  );
};

export default DropDown;
