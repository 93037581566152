import React, { FC } from "react";
import styled from "styled-components";
import type { InfoSignProps } from "./types";

const InfosignContainer = styled.div<InfoSignProps>`
  display: flex;
  background-color: #000000;
  border: solid 2px #d9d9d8;
  border-radius: 10px;
  padding: 8px;
  gap: 16px;
  flex-direction: column;
`;

const InfoSign: FC<InfoSignProps> = ({ children, ...rest }) => {
  return <InfosignContainer {...rest}>{children}</InfosignContainer>;
};
export default InfoSign;
