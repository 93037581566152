import { Children, FC } from "react";
import { Card, Container, Hr, Typography } from "../../Atoms";
import wipImg from "./../../Assets/Icon/wip/wip_small.svg";

interface ValueGridCardProps {
  title?: string;
  showWip?: boolean;
  children:
    | React.ReactElement<typeof ValueGridRow>[]
    | React.ReactElement<typeof ValueGridRow>;
}

export const ValueGridCard: FC<ValueGridCardProps> = ({
  title,
  showWip,
  children,
}) => {
  const arrayChildren = Children.toArray(children);

  return (
    <Card backgroundColor="White" flex="0">
      <Container flexDirection="column" flex={1}>
        <Typography
          color="black"
          fontSize="H6"
          marginBottom="None"
          marginTop="None"
          marginLeft="None"
        >
          {title}
          {showWip === true && (
            <img src={wipImg} style={{ paddingLeft: "5px", width: "24px" }} />
          )}
        </Typography>
        {title ? <Hr color="D8D8D8" /> : null}
        {arrayChildren.map((child, i) => {
          return child;
        })}
      </Container>
    </Card>
  );
};

interface ValueGridRowProps {
  children:
    | React.ReactElement<typeof ValueGridSlot>[]
    | React.ReactElement<typeof ValueGridSlot>;
}

export const ValueGridRow: FC<ValueGridRowProps> = ({ children }) => {
  const arrayChildren = Children.toArray(children);

  return (
    <Container
      flexDirection="row"
      flex={1}
      className={"value-grid-row"}
      style={{ marginBottom: "16px" }}
    >
      {arrayChildren.map((child, i) => {
        return child;
      })}
    </Container>
  );
};

interface ValueGridSlotProps {
  value: any;
  suffix: string;
  desc: string;
  valueColor?: string;
}

export const ValueGridSlot: FC<ValueGridSlotProps> = ({
  value,
  suffix,
  desc,
  valueColor,
}) => {
  return (
    <Container flexDirection="column" flex={1} className="value-grid-slot">
      <Container
        flexDirection="row"
        flex={1}
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Typography
          color={valueColor === undefined ? "black" : valueColor}
          fontSize="H4"
          marginBottom="None"
          marginTop="None"
          marginLeft="None"
        >
          {value}
        </Typography>
        <Typography
          color="black"
          fontSize="Small"
          marginBottom="None"
          marginTop="None"
          marginLeft="None"
          noTransform
        >
          {suffix}
        </Typography>
      </Container>
      <Typography
        color="#A4A8AA"
        fontSize="Small"
        marginTop="None"
        marginLeft="None"
        marginBottom="None"
      >
        {desc}
      </Typography>
    </Container>
  );
};
