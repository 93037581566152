import { FC, useEffect, useState } from "react";
import { Routes, Route, NavLink, useLocation } from "react-router-dom";
import { Facility } from "../../../../api/facility";
import { Container } from "../../Atoms";
import DailyPage from "../../Pages/DailyPage/DailyPage";
import MainPage from "../../Pages/MainPage/MainPage";
import NotisPage from "../../Pages/Notis/NotisPage";
import PricePage from "../../Pages/Price/PricePage";
import PriusPage from "../../Pages/Prius/PriusPage";
import ProfilePage from "../../Pages/Profile/ProfilePage";
import Battery from "../../Pages/Stats/Battery/Battery";
import Home from "../../Pages/Stats/Home/Home";
import SolarPanels from "../../Pages/Stats/SolarPanels/SolarPanels";
import StatsPage from "../../Pages/Stats/StatsPage";
import Nav from "../Nav/Nav";
import Grid from "../../Pages/Stats/Grid/Grid";

import homeIcon from "../../Assets/Icon/nav/home.svg";
import homeActiveIcon from "../../Assets/Icon/nav/homeActive.svg";
import statsIcon from "../../Assets/Icon/nav/stats.svg";
import statsActiveIcon from "../../Assets/Icon/nav/statsActive.svg";
import notisIcon from "../../Assets/Icon/nav/notis.svg";
import profileIcon from "../../Assets/Icon/nav/profile.svg";
import profileActiveIcon from "../../Assets/Icon/nav/profileActive.svg";
import { EnergyPriceDay } from "../../../../api/energyprice";
import { EmsState } from "../../../../api/state";
import BreakdownPage from "../../Pages/BreakdownPage/BreakdownPage";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchWeather, Weather } from "../../../../api/weather";

export const MobileFrame: FC<{
  facility: Facility | null;
  facilities: Facility[];
  selectFacility: (value: string) => void;
  energyPriceDay: EnergyPriceDay;
  emsState: EmsState;
}> = ({ facility, facilities, selectFacility, energyPriceDay, emsState }) => {
  const location = useLocation();

  // Weather is only available for mobile for the moment. It can be moved
  // up when more mature and we know exactly how and where to use it.
  const [weather, setWeather] = useState<Weather | undefined>();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function load() {
      if (facility === null) {
        return undefined;
      }

      setWeather(await fetchWeather(facility, getAccessTokenSilently()));
    }
    load();

    const interval = setInterval(() => {
      load();
    }, 10 * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [facility]);

  return (
    <Container flexDirection="column" id="mainRoot">
      <Routes>
        {facility !== null && (
          <>
            <Route
              path="/"
              element={
                <MainPage
                  facility={facility}
                  energyPriceDay={energyPriceDay}
                  emsState={emsState}
                />
              }
            />
            <Route path="/stats" element={<StatsPage facility={facility} />} />
            <Route path="/notis" element={<NotisPage />} />
            <Route path="/homeusage" element={<Home facility={facility} />} />
            <Route
              path="/breakdown"
              element={<BreakdownPage facility={facility} />}
            />

            <Route
              path="/batteryusage"
              element={<Battery facility={facility} />}
            />
            <Route
              path="/solarpanels"
              element={<SolarPanels facility={facility} />}
            />
            <Route path="/gridusage" element={<Grid facility={facility} />} />
            <Route path="/daily" element={<DailyPage facility={facility} />} />
            <Route
              path="/price"
              element={
                <PricePage
                  facility={facility}
                  energyPriceDay={energyPriceDay}
                />
              }
            />
            <Route
              path="/prius"
              element={
                <PriusPage
                  facility={facility}
                  energyPriceDay={energyPriceDay}
                  emsState={emsState}
                  weather={weather}
                />
              }
            />
          </>
        )}
        <Route
          path="/profile"
          element={
            <ProfilePage
              facility={facility}
              facilities={facilities}
              handleChooseFacility={selectFacility}
            />
          }
        />
      </Routes>

      <Nav>
        <Container
          bgColor="#ffffff"
          flex={1}
          padding="26px"
          justifyContent="space-between"
        >
          <NavLink to="/" end>
            {({ isActive }) => {
              if (
                location.pathname === "/" ||
                location.pathname === "/daily" ||
                location.pathname === "/prius" ||
                location.pathname === "/price"
              ) {
                return <img src={homeActiveIcon} alt="home Icon" />;
              } else {
                return <img src={homeIcon} alt="home Icon" />;
              }
            }}
          </NavLink>
          <NavLink to="/stats">
            {({ isActive }) => {
              if (
                location.pathname === "/stats" ||
                location.pathname === "/homeusage" ||
                location.pathname === "/gridusage" ||
                location.pathname === "/solarpanels" ||
                location.pathname === "/batteryusage"
              ) {
                return <img src={statsActiveIcon} alt="home Icon" />;
              } else {
                return <img src={statsIcon} alt="home Icon" />;
              }
            }}
          </NavLink>
          <NavLink to="#">
            <img src={notisIcon} className="disabled" alt="home Icon" />
          </NavLink>
          <NavLink to="/profile">
            {({ isActive }) =>
              isActive ? (
                <img src={profileActiveIcon} alt="home Icon" />
              ) : (
                <img src={profileIcon} alt="home Icon" />
              )
            }
          </NavLink>
        </Container>
      </Nav>
      {process.env.REACT_APP_SHOW_STAGING_BANNER === "true" && (
        <div className="staging-stripes"></div>
      )}
    </Container>
  );
};
