import { FC, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Card, Container, Typography } from "../../../Atoms";
import BackHeader from "./../../../Molecules/BackHeader/BackHeader";
import {
  ValueGridCard,
  ValueGridRow,
  ValueGridSlot,
} from "../../../Molecules/ValueGrid/ValueGridCard";
import DateSelector, {
  DateSelection,
} from "../../../Molecules/DateScroll/DateSelector";
import { Facility } from "../../../../../api/facility";
import { useAuth0 } from "@auth0/auth0-react";
import { Cost, fetchCostRange } from "../../../../../api/usage/cost";
import { Usage, fetchEmsUsageRange } from "../../../../../api/usage/ems";
import { UsageQuery } from "../../../../../api/usage/query";
import { CompareUsageChart, CostChart, UsageChart } from "../../../Atoms/Chart";
import { ElectricityFromCard } from "../../../Molecules/WipCard/ElectricityFromCard";
import { PeaksCard } from "../../../Molecules/WipCard/PeaksCard";
import { Link } from "react-router-dom";

const StatsPageRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  gap: 14px;
  height: 100%;
  background-color: #24b09d;
  overflow: scroll;
`;

const Home: FC<{
  facility: Facility;
}> = ({ facility }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [dateSelection, setDateSelection] = useState<
    DateSelection | undefined
  >();
  const [usage, setUsage] = useState<Array<Usage>>(new Array());
  const [cost, setCost] = useState<Array<Cost>>(new Array());
  const [costs, setCosts] = useState({
    battery_profit: 0,
    grid_cost: 0,
    load_cost: 0,
    pv_profit: 0,
  });

  const loadUsage = async (query: UsageQuery) => {
    const usage = await fetchEmsUsageRange(
      facility.id,
      query,
      getAccessTokenSilently()
    );
    setUsage(usage);
  };

  const loadCost = async (query: UsageQuery) => {
    let cost = await fetchCostRange(
      facility.id,
      query,
      getAccessTokenSilently()
    );

    setCost(cost);
  };

  const fetchCostTotal = async (query: UsageQuery) => {
    if (facility === null) {
      return;
    }

    let cost = await fetchCostRange(
      facility.id,
      query,
      getAccessTokenSilently()
    );

    const totalCosts = cost.reduce(
      (accumulator, currentObject) => {
        return {
          battery_profit:
            accumulator.battery_profit + currentObject.battery_profit,
          load_cost: accumulator.load_cost + currentObject.load_cost,
          pv_profit: accumulator.pv_profit + currentObject.pv_profit,
          grid_cost: accumulator.grid_cost + currentObject.grid_cost,
        };
      },
      {
        battery_profit: 0,
        load_cost: 0,
        pv_profit: 0,
        grid_cost: 0,
      }
    );

    setCosts({ ...totalCosts });
  };

  useEffect(() => {
    if (dateSelection === undefined) {
      return;
    }

    let query: UsageQuery = {
      duration: "",
      start: dateSelection.start,
      stop: dateSelection.stop,
    };

    switch (dateSelection.period) {
      case "day":
        query.duration = "1h";
        break;
      case "week":
      case "month":
        query.duration = "1d";
        break;
      case "year":
        query.duration = "1mo";
        break;
    }

    loadUsage(query);
    loadCost(query);
    fetchCostTotal(query);
  }, [dateSelection]);

  const consumedValues = useMemo(() => {
    const homeUsage = usage
      .reduce((partialSum: number, a: Usage) => {
        return partialSum + a.load;
      }, 0)
      ?.toFixed(2);

    const homeCost = cost
      .reduce((partialSum: number, a: Cost) => {
        if (a === undefined) return partialSum;
        return partialSum + a.load_cost;
      }, 0)
      ?.toFixed(2);

    return { homeUsage, homeCost };
  }, [usage, cost]);

  const total = [...usage];
  total.sort((a, b) => b.load - a.load);
  const [highest] = total;
  const lowest = total[total.length - 1];

  let result = {
    pvTotal: 0,
    gridTotal: 0,
    batteryTotal: 0,
    loadTotal: 0,
  };

  total.forEach((item) => {
    result.pvTotal += item.pv;
    result.gridTotal += item.grid;
    result.batteryTotal += item.battery;
    result.loadTotal += item.load;
  });

  return (
    <Container
      flexDirection="column"
      padding="14px 14px 27px 14px"
      gap={16}
      height="100vh"
      bgColor="#fafafa"
      scroll="scroll"
    >
      <BackHeader title="Home usage" goBack="/stats" />

      <DateSelector
        timezone={facility.timezone}
        stateSetter={setDateSelection}
        sharedState={true}
      />

      {dateSelection !== undefined && (
        <>
          <Card backgroundColor="White" flex="0">
            <UsageChart
              timezone={facility.timezone}
              period={dateSelection.period}
              usage={usage}
              point={"load"}
              mode="consumption"
            />
          </Card>

          <Card backgroundColor="White" flex="0">
            <CostChart
              timezone={facility.timezone}
              period={dateSelection.period}
              cost={cost}
              point={"load_cost"}
              mode="consumption"
            />
            <Container justifyContent="center">
              <Link to="/breakdown" style={{ textDecoration: "none" }}>
                <Button bgColor="#15C046" padding="4px 8px" margin="8px">
                  <Typography
                    color="white"
                    fontSize="Small"
                    marginBottom="None"
                    marginTop="None"
                    marginLeft="None"
                  >
                    Show cost breakdown
                  </Typography>
                </Button>
              </Link>
            </Container>
          </Card>

          <Card backgroundColor="White" flex="0">
            <CompareUsageChart
              facility={facility}
              period={dateSelection.period}
              point="load"
              highlight={6 - dateSelection.index}
              mode="consumption"
            />
          </Card>
        </>
      )}

      <ValueGridCard title="Consumed">
        <ValueGridRow>
          <ValueGridSlot
            value={consumedValues.homeUsage}
            suffix="kWh"
            desc="Home"
          />
          <ValueGridSlot
            value={consumedValues.homeCost}
            suffix="SEK"
            desc="Cost"
          />
        </ValueGridRow>
      </ValueGridCard>
      <ElectricityFromCard
        pv={result.pvTotal}
        grid={result.gridTotal}
        battery={result.batteryTotal}
        pvCost={costs.pv_profit}
        gridCost={costs.grid_cost}
        batteryCost={costs.battery_profit}
      />
    </Container>
  );
};

export default Home;
