import { FC, useMemo } from "react";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-luxon";
import { Period } from "../../../../ContextApi/StateProvider/type";
import { Cost } from "../../../../api/usage/cost";
import { DateTimeFormatFromDuration } from "../../Utils/chart";
import { barBackground, Mode } from "./shared";

ChartJS.register(
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const labels = (mode: Mode) => {
  const prefix = mode === "consumption" ? "Cost" : "Profit";
  return {
    day: `${prefix} per hour`,
    week: `${prefix} per day`,
    month: `${prefix} per day`,
    year: `${prefix} per month`,
  };
};

const CostChart: FC<{
  timezone: string;
  period: Period;
  cost: Array<Cost>;
  point: "grid_cost" | "pv_profit" | "load_cost" | "battery_profit";
  mode: Mode;
}> = ({ timezone, period, cost, point, mode }) => {
  const graphLabelsCost = labels(mode);

  const data = useMemo(() => {
    return {
      datasets: [
        {
          label: "Cost",
          data: cost,
          backgroundColor: cost.map((item: Cost) =>
            barBackground(mode, item[point])
          ),
          borderRadius: 50,
        },
      ],
    };
  }, [cost]);

  const options = useMemo(() => {
    let dayFormat = "dd";
    if (period === "week") {
      dayFormat = "cccc";
    }

    let duration = cost.length > 0 ? cost[0].duration : "";
    let tooltipFormat = DateTimeFormatFromDuration(duration, "HH:mm");

    return {
      responsive: true,
      maintainAspectRatio: false,
      borderColor: "rgba(109, 212, 0, 1)",
      backgroundColor: "rgba(76, 223, 232, 0.2)",
      tension: 0,
      pointRadius: 0,
      scales: {
        y: {
          ticks: {
            callback: function (label: any, index: any, labels: any) {
              return label.toFixed(0) + " SEK";
            },
          },
          stepSize: 5,
          beginAtZero: true,
        },
        x: {
          type: "time" as const,
          adapters: {
            date: {
              zone: timezone,
            },
          },
          time: {
            tooltipFormat: tooltipFormat,
            displayFormats: {
              minute: "HH:mm",
              hour: "HH:00",
              day: dayFormat,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
          position: "top" as const,
        },
        title: {
          display: true,
          text: graphLabelsCost[period],
        },
        tooltip: {
          titleAlign: "center" as const,
          bodyAlign: "center" as const,
          caretPadding: 10,
          displayColors: false,
          callbacks: {
            label: (item: any) => {
              return `${item?.parsed?.y?.toFixed(2)} SEK`;
            },
          },
        },
      },
      parsing: {
        xAxisKey: "time",
        yAxisKey: point,
      },
    };
  }, [point, period, cost]);

  return <Bar options={options} data={data} id="statsBar" />;
};

export default CostChart;
