// ErrorBoundary.tsx
import React, { ReactNode } from "react";
import styled from "styled-components";
import { useErrorBoundary } from "./useErrorBoundary";

interface ErrorBoundaryProps {
  children: ReactNode;
  fallback?: ReactNode;
}

const StyledErrorBoundary = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #f8d7da;
  color: #721c24;
  font-size: 24px;
  font-weight: bold;
`;

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
  fallback = "Oh no, something is wrong!",
}) => {
  const [state, setError] = useErrorBoundary();

  if (state.hasError) {
    return <StyledErrorBoundary>{fallback}</StyledErrorBoundary>;
  }

  return <>{children}</>;
};

export default ErrorBoundary;
