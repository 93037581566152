import { Facility } from "./../facility";
import { GATE_ADDRESS } from "./../server";
import { DateTime } from "luxon";

export interface CostBreakDown {}

export const costBreakdown = async (
  facility: Facility,
  startDate: DateTime,
  endDate: DateTime,
  duration: string,
  tokenGetter: Promise<string>
): Promise<CostBreakDown> => {
  const token = await tokenGetter;
  const parameters = new URLSearchParams({
    aggregate_duration: duration,
    start: startDate.toISO(),
    stop: endDate.toISO(),
  });

  const address =
    GATE_ADDRESS +
    `/facility/${facility.id}/usage_cost_breakdown?${parameters}`;
  const response = await fetch(address, {
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
  const json = await response.json();

  const result = json.points.reduce(
    (
      accumulator: {
        grid_cost: number;
        breakdown: { [x: string]: number };
      },
      currentValue: {
        grid_cost: any;
        breakdown: { [s: string]: unknown } | ArrayLike<unknown>;
      }
    ) => {
      // add the grid_cost values
      accumulator.grid_cost += currentValue.grid_cost;

      // add the breakdown values
      for (const [key, value] of Object.entries(currentValue.breakdown)) {
        if (typeof value === "number") {
          if (value < 0) {
            accumulator.breakdown[key] =
              (accumulator.breakdown[key] || 0) + Math.abs(value);
          } else {
            accumulator.breakdown[key] =
              (accumulator.breakdown[key] || 0) + value;
          }
        }
      }

      return accumulator;
    },
    { grid_cost: 0, breakdown: {} }
  );

  const {
    sell_60öringen,
    sell_energy,
    sell_net_benefit,
    ...segmentedResultPur
  } = result.breakdown;

  const {
    pur_energy,
    pur_balance_fee,
    pur_certificate,
    pur_origin_guarantee,
    pur_energy_surcharge,
    pur_energy_tax,
    pur_transfer_fee,
    pur_vat_grid,
    pur_vat_supplier,
    ...segmentedResultSell
  } = result.breakdown;

  return {
    date: DateTime.fromISO(json.date, { zone: json.timezone }),
    breakdown: [
      {
        purchased: { breakdown: segmentedResultPur },
        sold: { breakdown: segmentedResultSell },
      },
    ],
  };
};
