import React, { FC } from "react";
import styled from "styled-components";

import { Container, Typography } from "../../Atoms";
import Questionmark from "./../Questionmark/Questionmark";

export interface ColorLabelInfoPriceProps {
  color?: string;
  currency?: string;
  helper?: boolean;
  title: string;
  value?: number;
  helperItem?: string;
  paddingBottom?: string;
}

const ColorSquare = styled.div<{ color: string | undefined }>`
  display: flex;
  height: 18px;
  width: 18px;
  background-color: ${(props) => (props.color ? props.color : "#ff0000")};
  margin-right: 8px;
`;

const ColorLabelInfoPrice: FC<ColorLabelInfoPriceProps> = ({
  color,
  currency,
  helper,
  title,
  helperItem,
  value,
  paddingBottom,
}) => {
  return (
    <Container
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      padding={`0 0 ${paddingBottom ? paddingBottom : "8px"} 0`}
      height="unset"
    >
      <Container flexDirection="row" alignItems="center" flex={4}>
        {color ? <ColorSquare color={color} /> : null}
        <Typography
          as="p"
          fontSize="Small"
          marginBottom="None"
          marginLeft="None"
          marginTop="None"
          noWrap="pre"
        >
          {title}
        </Typography>
        {helper ? (
          <Questionmark helperItem={helperItem ? helperItem : ""} />
        ) : null}
      </Container>
      <Container
        flexDirection="row"
        alignItems="center"
        flex={2}
        justifyContent="flex-end"
      >
        <Typography
          as="p"
          fontSize="P"
          marginBottom="None"
          marginLeft="None"
          marginTop="None"
          marginRight="None"
        >
          {`${Number(value).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })} ${currency}`}
        </Typography>
      </Container>
    </Container>
  );
};
export default ColorLabelInfoPrice;
