import React, { FC } from "react";
import styled from "styled-components";

type HrProps = {
  color?: string;
};

const HrContainer = styled.hr<HrProps>`
  width: 100%;
  background-color: ${(props) => (props.color ? props.color : "#ffffff")};
  height: 1px;
  border: none;
`;

const Hr: FC<HrProps> = ({ color }) => {
  return <HrContainer color={color} />;
};

export default Hr;
