import { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";
import { Card, Container, Typography, Icon } from "../../Atoms";
import { Link } from "react-router-dom";
import Prius from "./../../Organisms/Prius/Prius";
import { Facility } from "../../../../api/facility";
import { SpotPriceChart } from "../Price/SpotPriceChart";
import Daily from "../../Organisms/Daily/Daily";
import {
  EnergyPriceDay,
  useCurrentPriceSlot,
} from "../../../../api/energyprice";
import { EmsState } from "../../../../api/state";

const MainPage: FC<{
  facility: Facility;
  energyPriceDay: EnergyPriceDay;
  emsState: EmsState;
}> = ({ facility, energyPriceDay, emsState }) => {
  const [currentPriceSlot] = useCurrentPriceSlot(
    facility.timezone,
    energyPriceDay
  );

  return (
    <Container
      padding="14px 14px 27px 14px"
      flexDirection="column"
      gap={16}
      id="mobile"
    >
      <Link to="/daily" style={{ width: "100%", textDecoration: "none" }}>
        <Daily facility={facility}></Daily>
      </Link>
      <Link to="/prius" style={{ width: "100%", textDecoration: "none" }}>
        <Card backgroundColor="BluePrintBlue" noPadding minHeight="unset">
          <Prius
            emsState={emsState}
            batteryActive={true}
            solarPanelActive={emsState.pv > 0 ? true : false}
            houseActive={true}
            priusHeight="360px"
            priceSlot={currentPriceSlot}
            gridActive={true}
            width="100%"
            viewBox="-60 -30 400 500"
            hideInfoData={true}
            mobile={true}
          />
        </Card>
      </Link>
      <Card backgroundColor="White">
        <Container flexDirection="column" height="unset">
          <Link to="/price" style={{ width: "100%", textDecoration: "none" }}>
            <Container
              flexDirection="column"
              justifyContent="center"
              flex={1}
              gap={16}
            >
              <Typography color="black" marginBottom="None" marginTop="None">
                Electricity price
              </Typography>
              <Container
                flexDirection="column"
                justifyContent="center"
                alignItems="baseline"
                flex={1}
              >
                <Container
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="baseline"
                  flex={1}
                >
                  <Typography
                    color="black"
                    marginBottom="None"
                    marginTop="None"
                    fontSize="H2"
                  >
                    {currentPriceSlot.purPrice.toFixed(2)}
                  </Typography>
                  <Typography
                    color="black"
                    marginBottom="None"
                    marginTop="None"
                    fontSize="H5"
                    noTransform
                  >
                    SEK / kWh
                  </Typography>
                </Container>
                <Container flexDirection="column" alignItems="center" flex={1}>
                  <Typography
                    color="black"
                    marginBottom="None"
                    marginTop="None"
                    fontSize="H5"
                  >
                    Current price
                  </Typography>
                </Container>
              </Container>

              <Container
                flexDirection="row"
                justifyContent="space-between"
                flex={1}
                gap={16}
              >
                {energyPriceDay !== undefined && (
                  <SpotPriceChart
                    timezone={facility?.timezone}
                    enableAnnotation={true}
                    enableTooltip={false}
                    chartHeight="150px"
                    setSelected={null}
                    data={energyPriceDay}
                    priceLevel={
                      Object.keys(energyPriceDay.breakdown).length > 0 &&
                      energyPriceDay.breakdown.breakdown["pur_breakdown"]
                    }
                    id="mainChart"
                  ></SpotPriceChart>
                )}
              </Container>
            </Container>
          </Link>
        </Container>
      </Card>
    </Container>
  );
};

export default MainPage;
