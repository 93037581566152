import { FC, useEffect, useState } from "react";
import styled from "styled-components";

import { Card, Container } from "../../../Atoms";
import BackHeader from "../../../Molecules/BackHeader/BackHeader";
import { useAuth0 } from "@auth0/auth0-react";
import { Cost, fetchCostRange } from "../../../../../api/usage/cost";
import { fetchEmsUsageRange, Usage } from "../../../../../api/usage/ems";
import DateSelector, {
  DateSelection,
} from "../../../Molecules/DateScroll/DateSelector";
import { UsageQuery } from "../../../../../api/usage/query";
import { Facility } from "../../../../../api/facility";
import { CompareUsageChart, CostChart, UsageChart } from "../../../Atoms/Chart";
import {
  ValueGridCard,
  ValueGridRow,
  ValueGridSlot,
} from "../../../Molecules/ValueGrid/ValueGridCard";
const StatsPageRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  gap: 14px;
  height: 100%;
  background-color: #2da4c7;
  overflow: scroll;
`;
const SolarPanels: FC<{
  facility: Facility;
}> = ({ facility }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [dateSelection, setDateSelection] = useState<
    DateSelection | undefined
  >();
  const [usage, setUsage] = useState<Array<Usage>>(new Array());
  const [cost, setCost] = useState<Array<Cost>>(new Array());

  const loadUsage = async (query: UsageQuery) => {
    const usage = await fetchEmsUsageRange(
      facility.id,
      query,
      getAccessTokenSilently()
    );
    setUsage(usage);
  };

  const loadCost = async (query: UsageQuery) => {
    let cost = await fetchCostRange(
      facility.id,
      query,
      getAccessTokenSilently()
    );
    setCost(cost);
  };

  useEffect(() => {
    if (dateSelection === undefined) {
      return;
    }

    let query: UsageQuery = {
      duration: "",
      start: dateSelection.start,
      stop: dateSelection.stop,
    };

    switch (dateSelection.period) {
      case "day":
        query.duration = "1h";
        break;
      case "week":
      case "month":
        query.duration = "1d";
        break;
      case "year":
        query.duration = "1mo";
        break;
    }

    loadUsage(query);
    loadCost(query);
  }, [dateSelection]);

  const peaks = [...usage];
  peaks.sort((a, b) => b.pv - a.pv);

  // OLD
  return (
    <Container
      flexDirection="column"
      padding="14px 14px 27px 14px"
      gap={16}
      height="100vh"
      bgColor="#fafafa"
      scroll="scroll"
    >
      <BackHeader title="Solar Panels" goBack="/stats" />

      <DateSelector
        timezone={facility.timezone}
        stateSetter={setDateSelection}
        sharedState={true}
      />

      {dateSelection !== undefined && (
        <>
          <Card backgroundColor="White" flex="0">
            <UsageChart
              timezone={facility.timezone}
              period={dateSelection.period}
              usage={usage}
              point={"pv"}
              mode="production"
            />
          </Card>

          <Card backgroundColor="White" flex="0">
            <CostChart
              timezone={facility.timezone}
              period={dateSelection.period}
              cost={cost}
              point={"pv_profit"}
              mode="production"
            />
          </Card>

          <Card backgroundColor="White" flex="0">
            <CompareUsageChart
              facility={facility}
              period={dateSelection.period}
              point="pv"
              highlight={6 - dateSelection.index}
              mode="production"
            />
          </Card>
        </>
      )}

      <ValueGridCard title="Produced">
        <ValueGridRow>
          <ValueGridSlot
            value={usage
              .reduce((partialSum: number, a: Usage) => {
                return partialSum + a.pv;
              }, 0)
              ?.toFixed(2)}
            suffix="kWh"
            desc="Solar Panel"
          />
          <ValueGridSlot
            value={cost
              .reduce((partialSum: number, a: Cost) => {
                return partialSum + a.pv_profit;
              }, 0)
              ?.toFixed(2)}
            suffix="SEK"
            desc="Profit"
          />
        </ValueGridRow>
      </ValueGridCard>

      <ValueGridCard title="Self sufficient" showWip={true}>
        <ValueGridRow>
          <ValueGridSlot value="17" suffix="%" desc="Highest peak" />
          <ValueGridSlot value="80" suffix="kW" desc="Generated" />
        </ValueGridRow>
      </ValueGridCard>
    </Container>
  );
};

export default SolarPanels;
