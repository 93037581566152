import React, { FC } from "react";
import styled from "styled-components";

import { TypographyGaps, ContainerProps, TypographyFlex } from "./types";

const ContainerContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  gap: ${(props) => (props.gap ? TypographyGaps[props.gap] : 0)};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : "flex-start"};
  flex: ${(props) => (props.flex ? TypographyFlex[props.flex] : 0)};
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : "0")};
  box-sizing: border-box;
  height: ${(props) => (props.height ? props.height : "100%")};
  overflow: ${(props) => (props.scroll ? "scroll" : "unset")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "unset")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : 0)};
`;

const Container: FC<ContainerProps> = ({
  alignItems,
  children,
  flexDirection,
  gap,
  justifyContent,
  flex,
  bgColor,
  ...rest
}) => {
  return (
    <ContainerContainer
      alignItems={alignItems}
      flexDirection={flexDirection}
      gap={gap}
      justifyContent={justifyContent}
      flex={flex}
      bgColor={bgColor}
      {...rest}
    >
      {children}
    </ContainerContainer>
  );
};

export default Container;
