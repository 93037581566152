import { ReactNode, HTMLAttributes } from "react";

export const TypographyGaps = {
  0: 0,
  8: "8px",
  16: "16px",
  24: "24px",
  32: "32px",
  40: "40px",
};

export const TypographyFlex = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
};

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  flexDirection?: "row" | "column";
  gap?: keyof typeof TypographyGaps;
  justifyContent?:
    | "center"
    | "flex-end"
    | "flex-start"
    | "space-around"
    | "space-between"
    | "space-evenly";
  alignItems?: "flex-start" | "center" | "flex-end" | "baseline";
  flex?: keyof typeof TypographyFlex;
  padding?: string;
  bgColor?: string;
  scroll?: string;
  height?: string;
  borderRadius?: string;
}
