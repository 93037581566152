import React, { FC } from "react";
import styled from "styled-components";
import {
  TypographyProps,
  TypographySize,
  TypographyWeight,
  TypographyMargin,
} from "./types";

const TypographyContainer = styled.p<TypographyProps>`
  font-size: ${(props) =>
    props.fontSize ? TypographySize[props.fontSize] : `${18}px`};
  font-weight: ${(props) =>
    props.fontWeight ? TypographyWeight[props.fontWeight] : 500};
  color: ${(props) => (props.color ? props.color : "#000000")};
  margin-bottom: ${(props) =>
    props.marginBottom ? TypographyMargin[props.marginBottom] : "8px"};
  margin-left: ${(props) =>
    props.marginLeft ? TypographyMargin[props.marginLeft] : "8px"};
  margin-right: ${(props) =>
    props.marginRight ? TypographyMargin[props.marginRight] : "8px"};
  margin-top: ${(props) =>
    props.marginTop ? TypographyMargin[props.marginTop] : "8px"};
  white-space: ${(props) => (props.noWrap ? props.noWrap : "normal")};
  text-transform: ${(props) => (props.noTransform ? "unset" : "lowercase")};
  &::first-letter {
    text-transform: ${(props) => (props.noTransform ? "unset" : "uppercase")};
  }
`;

const Typography: FC<TypographyProps> = ({
  color,
  fontSize,
  fontWeight,
  children,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  noWrap,
  noTransform,
  ...rest
}) => {
  return (
    <TypographyContainer
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      noWrap={noWrap}
      noTransform={noTransform}
      {...rest}
    >
      {children}
    </TypographyContainer>
  );
};

export default Typography;
