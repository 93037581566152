import { FC } from "react";
import {
  ValueGridCard,
  ValueGridRow,
  ValueGridSlot,
} from "../ValueGrid/ValueGridCard";

export const ElectricityFromCard: FC<{
  pv?: number;
  grid?: number;
  battery?: number;
  pvCost?: number;
  gridCost?: number;
  batteryCost?: number;
}> = ({ pv, grid, battery, pvCost, gridCost, batteryCost }) => {
  return (
    <ValueGridCard title="Electricity from">
      <ValueGridRow>
        <ValueGridSlot
          value={pv ? pv.toFixed(2) : 0}
          suffix="kWh"
          desc="Solar Panel"
        />
        <ValueGridSlot
          value={pvCost ? pvCost.toFixed(2) : 0}
          suffix="SEK"
          desc="Profit"
        />
      </ValueGridRow>
      <ValueGridRow>
        <ValueGridSlot
          value={Math.abs(grid ?? 0).toFixed(2)}
          suffix="kWh"
          desc="Grid"
        />
        <ValueGridSlot
          value={Math.abs(gridCost ?? 0).toFixed(2)}
          suffix="SEK"
          desc={gridCost !== undefined && gridCost >= 0 ? "Cost" : "Profit"}
        />
      </ValueGridRow>

      <ValueGridRow>
        <ValueGridSlot
          value={Math.abs(battery ?? 0).toFixed(2)}
          suffix="kWh"
          desc="Battery"
        />
        <ValueGridSlot
          value={Math.abs(batteryCost ?? 0).toFixed(2)}
          suffix="SEK"
          desc={
            batteryCost !== undefined && batteryCost >= 0 ? "Cost" : "Profit"
          }
        />
      </ValueGridRow>
    </ValueGridCard>
  );
};
