import { Facility } from "./facility";
import { GATE_ADDRESS } from "./server";
import { DateTime } from "luxon";

export const batteryScheduler = async (
  facility: Facility,
  tokenGetter: () => Promise<string>
): Promise<any> => {
  const token = await tokenGetter();

  const address = GATE_ADDRESS + `/facility/${facility.id}/battery_schedule`;
  const response = await fetch(address, {
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
  const json = await response.json();

  const result = json
    .map((item: { startsAt: string; endsAt: string; energy: any }) => {
      let startsAt = DateTime.fromISO(item.startsAt);
      let endsAt = DateTime.fromISO(item.endsAt);

      if (startsAt.hour >= 23 && endsAt.hour < startsAt.hour) {
        endsAt = endsAt.plus({ hours: 24 });
      }

      return { startsAt, endsAt, energy: item.energy };
    })
    .filter((item: { startsAt: DateTime; endsAt: DateTime; energy: any }) => {
      return item.startsAt.hasSame(DateTime.local(), "day");
    });

  return {
    date: DateTime.fromISO(json.date, { zone: json.timezone }),
    schedule: result,
  };
};
