import { Facility } from "./facility";
import { GATE_ADDRESS } from "./server";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

interface SellBreakdown {
  [key: string]: string;
}
export interface EnergyPriceSlot {
  purPrice: number;
  sellPrice: number;
  startTime: DateTime;
  endTime: DateTime;
  pur_breakdown?: {
    energy: string;
    energy_surcharge: string;
    energy_tax: string;
    transfer_fee: string;
    vat_grid: string;
    vat_supplier: string;
    balance_fee: string;
    certificate: string;
    origin_guarantee: string;
  };
  sell_breakdown?: SellBreakdown;
}

export interface EnergyPriceDay {
  date: DateTime;
  slots: EnergyPriceSlot[];
  breakdown: any;
}

export const defaultState = (): EnergyPriceDay => {
  return {
    date: DateTime.now(),
    slots: [],
    breakdown: {},
  };
};

export const useCurrentPriceSlot = (
  timezone: string | undefined,
  prices: EnergyPriceDay
): [price: EnergyPriceSlot] => {
  const emptySlot = {
    purPrice: 0,
    sellPrice: 0,
    startTime: DateTime.now(),
    endTime: DateTime.now(),
    pur_breakdown: {
      energy: "",
      energy_surcharge: "",
      energy_tax: "",
      transfer_fee: "",
      vat_grid: "",
      vat_supplier: "",
      balance_fee: "",
      certificate: "",
      origin_guarantee: "",
    },
    sell_breakdown: {
      oringen60: "",
      energy: "",
      net_benefit: "",
      vat_grid: "",
      vat_supplier: "",
      sell_60öringen: "",
      sell_energy: "",
      sell_net_benefit: "",
      sell_vat_grid: "",
      sell_vat_supplier: "",
    },
  };
  const [currentPriceSlot, setCurrentPriceSlot] =
    useState<EnergyPriceSlot>(emptySlot);

  useEffect(() => {
    setCurrentPriceSlot(
      prices.slots.find(findSlotByTime(DateTime.now().setZone(timezone))) ??
        emptySlot
    );

    const updateCurrentPrice = setInterval(() => {
      const slot = prices.slots.find(
        findSlotByTime(DateTime.now().setZone(timezone))
      );
      if (slot === undefined) {
        return;
      }

      if (currentPriceSlot.startTime < slot.startTime) {
        setCurrentPriceSlot(slot);
      }
    }, 5 * 1000);
    return () => {
      clearInterval(updateCurrentPrice);
    };
  }, [timezone, prices]);

  return [currentPriceSlot];
};

interface ResponseBody {
  date: string;
  timezone: string;
  price_slots: {
    price: string;
    pur_price: string;
    pur_breakdown: {
      energy_surcharge: string;
      energy_tax: string;
      transfer_fee: string;
      vat_grid: string;
      vat_supplier: string;
      energy: string;
      balance_fee: string;
      certificate: string;
      origin_guarantee: string;
    };
    sell_price: string;
    sell_breakdown: SellBreakdown;
    start_time: string;
  }[];
}

export const fetchEnergyPrice = async (
  facility: Facility,
  date: DateTime,
  tokenGetter: Promise<string>
): Promise<EnergyPriceDay> => {
  const token = await tokenGetter;

  const parameters = new URLSearchParams({
    date: date.toFormat("yyyy-MM-dd"),
  });

  const address =
    GATE_ADDRESS + `/facility/${facility.id}/spotprice?${parameters}`;
  const response = await fetch(address, {
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
  const json = (await response.json()) as ResponseBody;

  const slots = json.price_slots.map((s): EnergyPriceSlot => {
    const startTime = DateTime.fromISO(s.start_time, { zone: json.timezone });
    const modifiedSellBreakdown: SellBreakdown = {};

    for (const key in s.sell_breakdown) {
      modifiedSellBreakdown["sell_" + key] = s.sell_breakdown[key];
    }
    return {
      purPrice: Number(s.pur_price),
      sellPrice: Number(s.sell_price),
      startTime: startTime,
      endTime: startTime.plus({ hour: 1 }),
      pur_breakdown: {
        energy: s.pur_breakdown.energy,
        energy_surcharge: s.pur_breakdown.energy_surcharge,
        vat_supplier: s.pur_breakdown.vat_supplier,
        transfer_fee: s.pur_breakdown.transfer_fee,
        energy_tax: s.pur_breakdown.energy_tax,
        vat_grid: s.pur_breakdown.vat_grid,
        balance_fee: s.pur_breakdown.balance_fee,
        certificate: s.pur_breakdown.certificate,
        origin_guarantee: s.pur_breakdown.origin_guarantee,
      },

      sell_breakdown: modifiedSellBreakdown,
    };
  });

  return {
    date: DateTime.fromISO(json.date, { zone: json.timezone }),
    slots: slots,
    breakdown: {},
  };
};

const findSlotByTime = (time: DateTime) => {
  return (s: EnergyPriceSlot) => {
    return s.startTime.get("hour") === time.get("hour");
  };
};
