import { DateTime } from "luxon";

export interface UsageQuery {
  duration: string;
  start: DateTime;
  stop: DateTime;
}

export const DailyQuery = (date: DateTime): UsageQuery => {
  const start = date.startOf("day");
  return {
    duration: "1d",
    start: start,
    stop: start.plus({ days: 1 }),
  };
};
