export interface HelperText {
  [key: string]: HelperTextItem;
}

export interface HelperTextItem {
  title: string;
  text: string;
  source: string;
  sourceTitle: string;
}

const surchargeMarketPriceInfo = {
  title: "Surcharge market price",
  text: "Electricity suppliers may need to purchase electricity on the open market to meet the demand for their customers. When demand for electricity is high or supply is limited, the cost of purchasing electricity from the market can increase. This increase in cost is reflected in the surcharge market price, which is added on top of the standard price for electricity. \n \nThe surcharge market price is a way for electricity suppliers to recover their costs when they have to purchase more expensive electricity from the market. It is typically a temporary measure and is only applied when the cost of generating electricity exceeds a certain threshold. So, in summary, a surcharge market price is an additional fee that may be charged by electricity suppliers when the cost of generating electricity is higher than usual due to market conditions.",
  source: "https://www.google.se",
  sourceTitle: "google",
};

const purBalanceFee = {
  title: "Balance Fee",
  text: "Balance fee",
  source: "https://www.google.se",
  sourceTitle: "google",
};

const purCertificate = {
  title: "Certificate",
  text: "Certificate",
  source: "https://www.google.se",
  sourceTitle: "google",
};

const purOriginGuarantee = {
  title: "Origin guarantee",
  text: "Origin guarantee",
  source: "https://www.google.se",
  sourceTitle: "google",
};

const marketPriceInfo = {
  title: "Market Price",
  text: "The market price of electricity is the price that buyers andsellers are willing to accept for electricity on a certain market.The price can vary depending on a number of factors such as supplyand demand, production and distribution costs, and regular factorssuch as weather, time of day and week.\n \nThe market price can alsobe affected by external factors such as political decisions andenergy agreements with other countries. It is important to notethat the market price can vary depending on where you are in theworld and what type of market it is.",
  source: "https://www.google.se",
  sourceTitle: "google",
};

const energyTaxInfo = {
  title: "Energy tax",
  text: "An energy tax is a tax that is applied to the consumption of energy, such as electricity, gas, or oil. The purpose of an energy tax is to encourage individuals and businesses to reduce their energy consumption, which in turn can help to reduce carbon emissions and combat climate change.\n\nThe tax is usually applied at the point of sale, so when a customer purchases energy, they will be required to pay an additional amount to cover the energy tax. The amount of the tax can vary depending on the type of energy and the amount consumed.",
  source: "https://www.google.se",
  sourceTitle: "google",
};

const transmissionFee = {
  title: "Transmission fee",
  text: "A transmission fee is a charge that is applied to the delivery of electricity from the power plant to the local distribution network. It covers the costs associated with maintaining and operating the transmission infrastructure, including power lines, substations, and other equipment used to transport electricity over long distances.\n\nElectricity is generated at power plants and then transported over long distances to reach homes and businesses. The transmission fee is a cost that is added to the price of electricity to cover the cost of maintaining and operating this infrastructure.The amount of the transmission fee can vary depending on factors such as the distance that the electricity needs to be transported, the capacity of the transmission lines, and the demand for electricity in a particular area.\n\nThe fee is typically set by the company that owns and operates the transmission infrastructure.The transmission fee is an important part of the cost of electricity, and it is necessary to ensure that the transmission infrastructure is properly maintained and operated. Without the transmission fee, the cost of electricity would likely be higher or the infrastructure may not be properly maintained, which could lead to power outages and other problems.",
  source: "https://www.google.se",
  sourceTitle: "google",
};

const vatSupplierInfo = {
  title: "VAT Supplier",
  text: "VAT stands for Value Added Tax, and it is a type of tax that is applied to the sale of electricity. It is typically added to the price of electricity that a customer pays to their electricity supplier.The amount of VAT that is charged on electricity can vary depending on the country and the specific regulations in place. In some countries, there may be a reduced rate of VAT for residential customers, while commercial or industrial customers may be subject to a higher rate of VAT.\n\nThe revenue generated from VAT on electricity is typically collected by the government and used to fund public services such as healthcare, education, and infrastructure projects.VAT on electricity is just one of the many costs that make up the total price that a customer pays for their electricity. Other costs may include generation, transmission, and distribution fees.\n\nThe electricity supplier is responsible for collecting the VAT on behalf of the government and passing it on to the relevant authorities.In summary, VAT on electricity is a type of tax that is added to the price of electricity paid by customers. The amount of VAT charged can vary depending on the country and the specific regulations in place, and the revenue generated is typically used to fund public services.",
  source: "https://www.google.se",
  sourceTitle: "google",
};

const vatGridInfo = {
  title: "VAT Grid",
  text: "VAT stands for Value Added Tax, and it is a type of tax that is applied to the sale of electricity. It is typically added to the price of electricity that a customer pays to their electricity supplier.The amount of VAT that is charged on electricity can vary depending on the country and the specific regulations in place. In some countries, there may be a reduced rate of VAT for residential customers, while commercial or industrial customers may be subject to a higher rate of VAT.\n\nThe revenue generated from VAT on electricity is typically collected by the government and used to fund public services such as healthcare, education, and infrastructure projects.VAT on electricity is just one of the many costs that make up the total price that a customer pays for their electricity. Other costs may include generation, transmission, and distribution fees.\n\nThe electricity supplier is responsible for collecting the VAT on behalf of the government and passing it on to the relevant authorities.In summary, VAT on electricity is a type of tax that is added to the price of electricity paid by customers. The amount of VAT charged can vary depending on the country and the specific regulations in place, and the revenue generated is typically used to fund public services.",
  source: "https://www.google.se",
  sourceTitle: "google",
};

const sell60oringen = {
  title: "60 Öringen",
  text: "The government gives people a tax reduction of 60 öre (which is a small amount of money) for every unit of electricity they use, called a kilowatt hour. However, the maximum amount of electricity that the tax reduction applies to is 30,000 kilowatt hours. This means that the highest amount of tax reduction a person can get in one year is 18,000 kronor (which is a decent amount of money). But in order to get this tax reduction, the person needs to have paid enough taxes to be able to use it.",
  source: "https://www.google.se",
  sourceTitle: "google",
};

const sellEnergy = {
  title: "Energy",
  text: "something sell",
  source: "https://www.google.se",
  sourceTitle: "google",
};

const sellNetBenefit = {
  title: "Net benefit",
  text: '"Net benefit" in electricity refers to the overall positive economic or societal impact that comes from decisions related to the production, distribution, and consumption of electricity. This includes considering factors like cost, environmental impact, and reliability when making decisions about things like building new power plants or setting electricity prices. The goal is to make sure that any decision ultimately benefits society more than it harms it.',
  source: "https://www.google.se",
  sourceTitle: "google",
};

const energySaleMarkup = {
  title: "Energy sale Markup",
  text: "something sell",
  source: "https://www.google.se",
  sourceTitle: "google",
};

const defaultFallBack = {
  title: "Missing",
  text: "something sell",
  source: "https://www.google.se",
  sourceTitle: "google",
};

export const helperText: HelperText = {
  surchargeMarketPrice: { ...surchargeMarketPriceInfo },
  purSurchargeMarketPrice: { ...surchargeMarketPriceInfo },

  marketPrice: {
    ...marketPriceInfo,
  },
  purEnergy: { ...marketPriceInfo },

  energyTax: {
    ...energyTaxInfo,
  },
  purEnergyTax: { ...energyTaxInfo },

  transmissionFee: {
    ...transmissionFee,
  },
  purTransferFee: { ...transmissionFee },

  vatSupplier: {
    ...vatSupplierInfo,
  },
  purVatSupplier: { ...vatSupplierInfo },

  vatGrid: {
    ...vatGridInfo,
  },
  purVatGrid: { ...vatGridInfo },
  sellEnergy: { ...sellEnergy },
  sell60oringen: { ...sell60oringen },
  sellNetBenefit: { ...sellNetBenefit },
  defaultFallBack: { ...defaultFallBack },
  purBalanceFee: { ...purBalanceFee },
  purCertificate: { ...purCertificate },
  purOriginGuarantee: { ...purOriginGuarantee },
  energySaleMarkup: { ...energySaleMarkup },
};
