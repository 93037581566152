// useErrorBoundary.ts
import { useCallback, useEffect, useReducer } from "react";

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

type ErrorBoundaryAction =
  | { type: "SET_ERROR"; error: Error; errorInfo: React.ErrorInfo }
  | { type: "RESET_ERROR" };

const errorBoundaryReducer = (
  state: ErrorBoundaryState,
  action: ErrorBoundaryAction
): ErrorBoundaryState => {
  switch (action.type) {
    case "SET_ERROR":
      return {
        hasError: true,
        error: action.error,
        errorInfo: action.errorInfo,
      };
    case "RESET_ERROR":
      return { hasError: false };
    default:
      return state;
  }
};

export const useErrorBoundary = (): [
  ErrorBoundaryState,
  (error: Error, errorInfo: React.ErrorInfo) => void
] => {
  const [state, dispatch] = useReducer(errorBoundaryReducer, {
    hasError: false,
  });

  const setError = useCallback((error: Error, errorInfo: React.ErrorInfo) => {
    dispatch({ type: "SET_ERROR", error, errorInfo });
  }, []);

  useEffect(() => {
    const handleError = (error: ErrorEvent) => {
      setError(error.error, { componentStack: "" });
    };

    window.addEventListener("error", handleError);
    return () => {
      window.removeEventListener("error", handleError);
    };
  }, [setError]);

  return [state, setError];
};
