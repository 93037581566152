import { useAuth0 } from "@auth0/auth0-react";
import { useState, useCallback } from "react";
import { GATE_ADDRESS } from "./server";

const AUDIENCE = "https://gate.thiink.io/hc";

type Fetcher = (
  path: string,
  method: string,
  body: Object | null
) => Object | null;

export const useAPI = (): [
  boolean,
  string | null,
  Object | undefined,
  Fetcher
] => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [response, setResponse] = useState<Object | undefined>(undefined);
  const { getAccessTokenSilently } = useAuth0();

  const fetcher = useCallback(
    async (
      path: string,
      method: string,
      body: Object | null
    ): Promise<Object | null> => {
      setLoading(true);

      const token = await getAccessTokenSilently({
        audience: AUDIENCE,
      });

      let options: RequestInit = {
        method: method,
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: null,
      };

      if (body !== null) {
        options.body = JSON.stringify(body);
      }

      let url = GATE_ADDRESS + path;
      const response = await fetch(url, options);
      const data = await response.json();

      if (response.ok === false) {
        setError("request failed with status code: " + response.status);
        setResponse(undefined);
        setLoading(false);
        return null;
      }

      setError(null);
      setResponse(data);
      setLoading(false);

      return data;
    },
    [getAccessTokenSilently]
  );

  return [loading, error, response, fetcher];
};
