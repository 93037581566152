/**
 * Return a date time format based on a duration. Duration define
 * the window size for aggregated Usage and Cost data and are returned
 * with each Usage and Cost record. E.g a record can have the duration 1m.
 */
export const DateTimeFormatFromDuration = (
  duration: string,
  defaultFormat: string
): string => {
  let format = defaultFormat;

  if (duration.endsWith("d")) {
    format = "yyyy-MM-dd";
  } else if (duration.endsWith("w")) {
    format = "yyyy w.W";
  } else if (duration.endsWith("mo")) {
    format = "LLL, yyyy";
  } else if (duration.endsWith("y")) {
    format = "yyyy";
  }

  return format;
};
